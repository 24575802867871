import Header from "../Header.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    //apply full 100% width to page
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh"
    },
    //stretch page to full width of screen below header
    child: {
        flex: "1 1 auto",
    }
}));

const BaseComponent = ({children, drawerAction, handleSendEmailDialogOpen}) => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Header drawerAction={drawerAction} 
                handleSendEmailDialogOpen={handleSendEmailDialogOpen}/>
            <div className={classes.child}>{children}</div>
        </div>
    )
}

export default BaseComponent;
