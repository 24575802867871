import { getAxios } from "./axiosUtil";

export const getOrgSupportEmailsApi = (organization_id) => {

    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/emails`);
}

export const getLoginRedirectUrlApi = (redirect_uri) => {   
    const param = {
        params: {
            redirect_uri
        }
    }

    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/login`, param)
}

export const getGmailOutlookRefeshTokenApi = (code, apiPathStr, organization_id, organization_email_id, redirect_uri)=>{
    const data = {
        code,
        organization_id,
        organization_email_id,
        redirect_uri
    }

    return getAxios().post(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/${apiPathStr}`, data)
}

export const getTriageListApi = (organization_id, page, text, type) => {
    const param = {
        params: {
            page,
            text,
            type
        }
    }

    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/triage`, param)
}

export const replyForwardEmailApi = (organization_email_id, email_uuid, from_email, to_email, cc_email, bcc_email, subject, body_text, attachments, redirect_uri, metadata, from_name, company_ids, is_repeat, apiPathStr) => {
    let data = {
        from_email,
        to_email,
        cc_email,
        bcc_email,
        subject,
        body_text,
        attachments,
        redirect_uri,
        metadata,
        from_name,
        is_repeat,
        company_ids
    }

return getAxios().post(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/email/${organization_email_id}/message/${email_uuid}/${apiPathStr}`, data);
}

export const getAttachmentUploadURLApi = (name, type, bucket, organinzation_id) => {
    let data = {name, type, bucket, organinzation_id}
    return getAxios().post(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/presigns3url`, data);
}

export const checkEmailBackgroundTaskCompleted = (email_item_id) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/bg_job_request/${email_item_id}/check`);
}

export const modifyClientsForTriageItemApi = (email_uuid, company_ids, triage_id) => {
    let data = {
        company_ids,
        triage_id
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/email/${email_uuid}/assign_company`, data);
}

export const modifyChecklistsForTriageItemApi = (email_uuid, organization_id, checklist_ids, triage_id, checklist_member_ids, followups, isSentEmail, removed_checklists) => {
    let data = {
        checklist_ids,
        triage_id,
        checklist_member_ids,
        followups,
        isSentEmail,
        removed_checklists
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/email/${email_uuid}/assign_checklist`, data);
}

export const clearTriageApi = (triage_ids) => {
    let data = {
        triage_ids
    }
    return getAxios().put(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/triage/clear`,data);
}

export const getClearTriageListApi = (organization_id, page, is_cleared_at, text, type) => {
    const param = {
        params: {
            page,
            is_cleared_at,
            text,
            type
        }
    }


    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/clearTriage`, param);
}

export const createChecklistInGenieApi = (organization_id, email_uuid, triage_id, checklists, isSentEmail) => {
    let data = {
        triage_id,
        checklists,
        isSentEmail
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/email/${email_uuid}/update`, data);
}

export const getEmailDraftListApi = (organization_id) => {

    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/email_drafts`);
}

export const sendEmailApi = (organization_email_id, from_email, to_email, cc_email, bcc_email, subject, body_text, attachments, metadata, redirect_uri, from_name, company_ids,is_repeat) => {
    let data = {
        from_email,
        to_email,
        cc_email,
        bcc_email,
        subject,
        body_text,
        attachments,
        metadata,
        redirect_uri,
        from_name,
        company_ids,
        is_repeat
    }

const header = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Content-Type': 'multipart/form-data'
    }
};

    return getAxios().post(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/email/${organization_email_id}/send`, data);
}

export const readTriageItemApi = (email_uuids) => {
    let data = {
        email_uuids
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/email/read`, data);
}

export const unReadTriageItemApi = (organization_id, email_uuids) => {
    let data = {
        email_uuids
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/email/unread`, data);
}

export const addCommentToEmailApi = (email_uuid, comment, mentioned_users, triage_id) => {
    let data = {
        comment,
        mentioned_users,
        triage_id
    }

    return getAxios().post(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/email/${email_uuid}/add_comment`, data);
}

export const getSentEmailsListApi = (organization_id, page, support_emails, text) => {
    let param = {
        params: {
            page,
            support_emails,
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/sent_emails`, param);
}

export const getEmailDetailsApi = (email_uuid, ) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/email/${email_uuid}/email_details/decrypt`);
}

export const getForEmailAllClientsApi = (organization_id, from_email) => {
    let param = {
        params: {
            from_email
        }
    }
    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/organization/${organization_id}/clients`,param);
}

export const getNotesListApiCall = (checklist_id) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_APP_BASE_URL}/checklist/${checklist_id}/notes`);
}