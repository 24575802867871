import React, {useEffect, useState, createRef} from 'react';
import { Drawer, AppBar, Toolbar, IconButton, Typography, Grid, Paper, Badge, Avatar, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useIsMount } from './useIsMount';
import { connect } from 'react-redux';
import { TriageTypes, consoleToLog, getStatusColor } from '../util/AppUtil';
import { getNotesListApiCall } from '../services/genieService';
import { MentionsInput, Mention } from "react-mentions";
import SendIcon from '@material-ui/icons/Send';
import {addChecklistNoteApi, noteReadApi} from '../services/mainAppService';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {addCommentToEmailApi} from '../services/genieService';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: '32%', 
        height: 'calc(100vh - 51px)',
        top:'51px',
        [theme.breakpoints.down(1450)] : {
            width: '33%', 
        },
        [theme.breakpoints.down(1320)] : {
            width: '35%', 
        },
        flexGrow:1,
        //background: '#efe6dd',
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        background:'#f7f7f7'
    },
    detailBadge: {
        backgroundColor: "#cccccc",
        fontSize: "12px",
        borderRadius: "3.5px",
        padding: "0px 6px",
        fontWeight: 500,
        color: "black",
        marginBottom: "3px",
        marginLeft: "5px",
    },
    messageContainer: {
        padding: '6px 16px 16px 16px'

    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    notesListContainer: {
        position:"relative",
        height: 'calc(100vh - 180px)',
        overflow: 'auto',
        position:"relative",
        background: '#f2f1f1',
        paddingTop:'8px',
    },
    textFieldStyle: {
        position:'relative',
        height:'70px',
        left:0,
        right:0,
        bottom:0,
        borderTop:'1px solid #ccc',
        background:"#fff",
        overflow:'hidden'
    },
    sendButtonStyle: {
        color: "#008a7c",
    },
    templateNameStyle: {
        color:'#0071FF'
    },
    actionIconStyle: {
        width:'21px',
        filter: 'invert(100%) sepia(90%) saturate(0%) hue-rotate(296deg) brightness(94%) contrast(101%)',
        position:'relative',
        top:'1px'
    }
}));

const NotesDrawer = (props) => {
    const {fromDetailsOpen} = props;
    const classes = useStyles(fromDetailsOpen);
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [noteList, setNoteList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mentions, setMentions] = useState();
    const [text, setText] = useState("");
    const [notesLoading, setNotesLoading] = useState(false);
    const myDivToFocus = createRef();

    let usersData = props.members.map((member) => ({
        id: member.user.id,
        display: `${member.user.firstname} ${member.user.lastname}`,
    }));
    const loggedInUser = props.triageSelectedOrganization.user;

    const checklistArr = props.selectedTriageItemObj?.data.checklists;
    const triageItem = props.selectedTriageItemObj;
    const noteItemRead = checklistArr && checklistArr.length > 0 && checklistArr[0].note_read_users?.includes(loggedInUser);

    const checklist_id = props.selectedTriageItemObj?.target_id;

    const generateRandomId = () => {
        return Math.random().toString(36).substr(2, 9);
    }

    const restructureNotes = (notes) => {

        let currentUserId = null;
        const groupedNotes = [];
        for (let i = 0; i < notes.length; i++) {
            const note = notes[i];
            const nextNote = notes[i + 1];
            const userId = triageItem.type === TriageTypes.CHECKLIST_NOTE ? note?.note_user?.id : note?.user?.id;
            const nextUserId = triageItem.type === TriageTypes.CHECKLIST_NOTE ? nextNote?.note_user?.id : nextNote?.user?.id;

            if (currentUserId === null || userId !== currentUserId) {
                currentUserId = userId;
                const newGroup = { group_id: generateRandomId(), items: [] };
                groupedNotes.push(newGroup);
            }

            groupedNotes[groupedNotes.length - 1].items.push(note);

                if (nextNote && nextUserId !== currentUserId) {
                    currentUserId = null; // Change the group ID for the next iteration
                }
        }
        return groupedNotes;
    }
    
    const restructuredNotesArr = restructureNotes(noteList);
   // consoleToLog('restructured notes array', restructuredNotesArr);

    useEffect(() => {
        if(!isMount) {
            if(triageItem && triageItem.type === TriageTypes.CHECKLIST_NOTE) {
                getNotesListApi(true);
            }
        }

    }, [triageItem]);

    const filterDuplicateObjects = (array) => {
        const seen = new Set();
        return array.filter(obj => {
            const stringifiedObj = JSON.stringify(obj);
            if (seen.has(stringifiedObj)) {
                return false;
            }
            seen.add(stringifiedObj);
            return true;
        });
    }

    useEffect(() => {
        if(!isMount) {
            if(props.openDrawer && triageItem.type === TriageTypes.CHECKLIST_NOTE) {
                const noteSocketMessage = props.triageListFromParent && props.triageListFromParent.length > 0 ? 
                props.triageListFromParent.filter((triageItem) => triageItem.type === TriageTypes.CHECKLIST_NOTE) : [];

                const socketArr = noteSocketMessage[0]?.data.checklists;
                const noteRead = socketArr && socketArr.length > 0 && socketArr[0].note_read_users?.includes(loggedInUser);

                let notesArr = noteSocketMessage[0]?.data.notes;
                
                let noteArrList = [...noteList, notesArr[0]];
                const filteredArray = filterDuplicateObjects(noteArrList);

                notesLoading && setNotesLoading(false);
                setNoteList(filteredArray);
                if(!noteRead) {
                    callNoteReadApi(checklist_id);
                }
            }
        }

    }, [props.triageListFromParent]);

    useEffect(() => {
        if(!isMount) {
            if(myDivToFocus.current) {
                myDivToFocus.current.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }
    }, [myDivToFocus]);

    useEffect(() => {
        if(!isMount) {
            if(triageItem && triageItem.type === TriageTypes.EMAIL) {
                const newItem = triageItem;
                const commentsListArr = [...newItem.data.comments];
                setNoteList(commentsListArr.reverse());
            }
        }
    }, [props.selectedTriageItemObj]);

    useEffect(() => {
        if(!isMount) {
            if(triageItem && triageItem.type === TriageTypes.EMAIL) {
                const noteSocketMessage = props.triageListFromParent && props.triageListFromParent.length > 0 ? 
                props.triageListFromParent.filter((triageItem) => triageItem.id === props.selectedTriageItemObj.id) : [];

                let newItem = noteSocketMessage[0];
                let commentsListArr = [...newItem.data.comments];

                setNoteList(commentsListArr.reverse());
            }
        }
    }, [props.triageListFromParent]);



    const getNotesListApi = (load) =>{
        if(checklist_id){
            if(load) setLoading(true);
            getNotesListApiCall(checklist_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getNotesListApiCall', res);
                setLoading(false);
                setNoteList(res.notes.reverse());
                // console.log('group by map', res.notes.groupByToMap(product => {
                //     return product.note_user.id;
                // }))
                if(!noteItemRead) {
                    callNoteReadApi(checklist_id);
                }

            })
            .catch((e) => {
                consoleToLog("getNotesListApiCall error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
        }
    } 

    const callNoteReadApi = (checklist_id) => {
        const organization_id = props.triageSelectedOrganization.organization.id;

        noteReadApi(organization_id, checklist_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response noteReadApi', res);

                    // let newObj = {
                    //     ...obj,
                    //     data: {
                    //         ...obj.data,
                    //         checklists: obj.data.checklists.map((checklistItem) => {
                    //                 checklistItem.note_read_users = [loggedInUser, ...checklistItem.note_read_users];
                    //                 return checklistItem;
                    //         })
                    //     }
                    // }
                    // props.triageListNoteRead(newObj);
            })
            .catch((e) => {
                consoleToLog('Error noteReadApi', e);
            })

    }


    const onSendButtonClick = () =>{
        const checklist_id = props.selectedTriageItemObj?.target_id;;
        const organization_id = props.triageSelectedOrganization.organization.id;
        const mention_users = mentions.map(mentionObj => mentionObj.id);
        const email_uuid = triageItem?.data?.email_uuid;
        const triage_id = triageItem?.id
        
        if(triageItem.type === TriageTypes.CHECKLIST_NOTE) {
            callAddNewNoteApi(checklist_id, mention_users, organization_id);
        } else {
            callAddNewCommentApi(email_uuid, mention_users, triage_id)
        }
    }

    const callAddNewNoteApi = (checklist_id, mention_users, organization_id) => {

        setNotesLoading(true);
        addChecklistNoteApi(checklist_id, mention_users, text, organization_id)
        .then((response) => {
            const res = response.data;
            consoleToLog ("Response addChecklistNoteApi: ", res);
                var newNote = res.comment
                //var newNotesArray = [...notes,newNote];
                //setNotes(newNotesArray);
                setNotesLoading(false);
                // enqueueSnackbar(res.message, {
                //     variant: "success",
                // });
                setText('');
        })
        .catch((e) => {
            consoleToLog("addChecklistNoteApi error", e);
        });

    }

    const callAddNewCommentApi = (email_uuid, mention_users, triage_id) => {

        setNotesLoading(true);
        addCommentToEmailApi(email_uuid, text, mention_users, triage_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addCommentToEmailApi: ', res);
                setText('');
                setMentions([]);

                const newItem = res.triage;
                const commentsListArr = [...newItem.data.comments];
                setNoteList(commentsListArr.reverse());
                props.updateTriageArr(res.triage);
                setNotesLoading(false);
            })
            .catch((e) => {
                consoleToLog('Error addCommentToEmailApi: ', e.response);
                setNotesLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    }

    const returnTaskStatusClassName = (status) => {
        if(status === "awaiting_reply") return 'awaiting-reply__background';
        if(status === "pending") return 'pending__background';
        if(status === "in_process") return 'in-process__background';
        if(status === "pending_approval") return 'pending-approval__background';
        if(status === "completed") return 'completed__status__background';
    }

    const closeDrawer = () => {
        props.handleDrawerClose();
        setMentions([]);
        setText('');
        setNoteList([]);
        props.setSelectedTriageItemObj(undefined);
    }

    const handleKeyDown = (e) => {
        if(e.ctrlKey && e.keyCode === 13) { 
            onSendButtonClick();
        }
    }

    const getReadableNotesText = (note) => {
        var readableNoteText = note.text;
        let  numberPattern = /\d+/g;
        let num = readableNoteText.match(numberPattern);
        num?.join();
        
        readableNoteText = readableNoteText.replace(/\n\r?/g, "<br />");//add <br>
        readableNoteText = readableNoteText.split('@[').join(`<b>`);
        readableNoteText = readableNoteText.split(']').join("</b>");
        readableNoteText = readableNoteText.split(/\(.+?\)/).join('');
        return readableNoteText;
        
    }

    return (
        <Drawer variant="temporary"
            anchor="right"
            open={props.openDrawer}
            onClose={closeDrawer}
            classes={{
                paper: classes.drawerPaper
                }}
            >
            {props.openDrawer &&
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 8px', }}>
                    {triageItem.type === TriageTypes.CHECKLIST_NOTE ?
                        <Grid item container alignItems='center'>
                            <Grid item md={1} style={{flexBasis:'6%', maxWidth:'6%'}}>
                                <i className='flaticon-clipboards-2' style={{fontSize:'17px'}}></i>
                            </Grid>
                            <Grid item md={10}>
                                <Typography variant="body1" 
                                    style={{fontWeight:'600', marginRight:'auto'}}>
                                    {checklistArr[0]?.title}
                                </Typography>
                                <Typography variant="body2" className={classes.templateNameStyle}>
                                    {checklistArr[0]?.template?.name}
                                    <span style={{marginLeft:'4px', fontSize:'13px', color:'#888'}}>
                                        {`(${checklistArr[0]?.subtitle})`}
                                    </span>
                                </Typography>
                            </Grid> 
                        </Grid>
                        :
                        <Grid item container alignItems='center'>
                            <Grid item md={1} style={{flexBasis:'6%', maxWidth:'6%'}}>
                                <img src="/images/inbox-icon1.png" className={classes.actionIconStyle}/>
                            </Grid>
                            <Grid item md={11}>
                                <Typography variant="body1">
                                    {triageItem?.data?.subject}
                                </Typography>
                            </Grid> 
                        </Grid>
                    }
                    <IconButton style={{color:'#202020', marginRight:'-8px'}} 
                        onClick={closeDrawer}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>}
            <div style={{maxWidth:'100%'}}>
                {!loading &&
                <div className={classes.notesListContainer}>
                    <Grid item md={12} >
                        {restructuredNotesArr && restructuredNotesArr.length > 0 && restructuredNotesArr.map((note, index)=>{
                            // const nameArr = note.note_user.name.split(" ");
                            // let nameStr1 = nameArr[0];
                            // let nameStr2 = nameArr[1] ? nameArr[1] : '';
                            // const finalStr = nameStr1?.charAt(0).toUpperCase() + nameStr2?.charAt(0).toUpperCase();
                            return <Grid item md={12} key={note.group_id} className={classes.messageContainer}>
                                            <Grid item md={12} ref={myDivToFocus}>
                                                {note.items && note.items.length > 0 &&
                                                note.items.slice(0,1).map((item) => {
                                                    const createdAt = triageItem.type === TriageTypes.CHECKLIST_NOTE ? item?.created_at : item?.createdAt;
                                                    const messageReceivedToday = props.calculateMessageTimeReceived(createdAt);
                                                    const userId = triageItem.type === TriageTypes.CHECKLIST_NOTE ? item?.note_user?.id : item?.user?.id;
                                                    const fullName = triageItem.type === TriageTypes.EMAIL && item?.user?.firstname + ' ' + item?.user?.lastname
                                                    const userName = triageItem.type === TriageTypes.CHECKLIST_NOTE ? item?.note_user?.name : fullName;
                                                    return <div className={loggedInUser === userId ? 'loggedInUserMessage' : 'message'} key={item.id}>
                                                                {loggedInUser !== userId &&
                                                                <Typography variant='body2' style={{fontWeight:'600'}}>
                                                                    {userName}
                                                                </Typography>}
                                                                <Grid item md={12} style={{marginTop:'2px'}}>
                                                                    {
                                                                        triageItem.type === TriageTypes.CHECKLIST_NOTE && item.note_type === TriageTypes.CHECKLIST_TASK &&
                                                                        <div className={returnTaskStatusClassName(item?.checklist_task?.status)}
                                                                            style={{
                                                                                borderLeft:`5px solid ${getStatusColor(item?.checklist_task?.status)}`, 
                                                                                margin:'4px 0px 8px 0px',
                                                                                fontSize: '13px',
                                                                                padding: '8px',
                                                                                borderRadius:'5px'
                                                                            
                                                                        }}>
                                                                            <span style={{marginLeft:'4px'}}>
                                                                                {item.checklist_task.title}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    <Typography variant='body2' dangerouslySetInnerHTML={{ __html : getReadableNotesText(item)}}>
                                                                    </Typography>
                                                                    <span className='time'>
                                                                        {messageReceivedToday ? moment(createdAt).format('MMM D, h:mm a') : moment(createdAt).format('h:mm a')}    
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                })
                                                }
                                        </Grid>
                                        {
                                            note.items && note.items.length > 0 && 
                                            note.items.slice(1).map((item) => {
                                                const createdAt = triageItem.type === TriageTypes.CHECKLIST_NOTE ? item?.created_at : item?.createdAt;
                                                const messageReceivedToday = props.calculateMessageTimeReceived(createdAt);
                                                const userId = triageItem.type === TriageTypes.CHECKLIST_NOTE ? item?.note_user?.id : item?.user?.id;
                                                return <div key={item.id} className='messageOne' 
                                                            style={{background: loggedInUser === userId ? '#e4f8d0' : '#fff',
                                                                    marginLeft: loggedInUser === userId && 'auto',
                                                                    marginRight: loggedInUser !== userId && 'auto'}}>
                                                            {
                                                                triageItem.type === TriageTypes.CHECKLIST_NOTE && item.note_type === TriageTypes.CHECKLIST_TASK &&
                                                                <div className={returnTaskStatusClassName(item?.checklist_task?.status)}
                                                                    style={{
                                                                        borderLeft: `5px solid ${getStatusColor(item?.checklist_task?.status)}`, 
                                                                        margin:'4px 0px 8px 0px',
                                                                        fontSize: '13px',
                                                                        padding: '8px',
                                                                        borderRadius:'5px',
                                                                    
                                                                }}>
                                                                    <span style={{marginLeft:'4px'}}>
                                                                        {item.checklist_task.title}
                                                                    </span>
                                                                </div>
                                                            }
                                                            <Typography variant='body2' dangerouslySetInnerHTML={{ __html : getReadableNotesText(item)}}>
                                                            </Typography>
                                                            <span className="time">
                                                                {messageReceivedToday ? moment(createdAt).format('MMM D, h:mm a') : moment(createdAt).format('h:mm a')}    
                                                            </span>
                                                        </div>
                                            })
                                        }
                                    </Grid>
                        })}
                    </Grid>
                </div>}
                <Grid item container md={12} className={classes.textFieldStyle}>
                    <Grid item md={11}>
                        <div className="mentionWrapper1">
                            <MentionsInput
                                markup="@[__display__](__id__)"
                                className="mentions1"
                                placeholder="Use @ to notify a team member and ctrl + enter↵ to send message"
                                value={text}
                                onChange={(e, newValue, newPlainTextValue, mentions) => {
                                    setText(e.target.value);
                                    setMentions(mentions);
                                }}
                                onKeyDown={handleKeyDown}
                            >
                                <Mention className="mentions1__mention" data={usersData}/>
                            </MentionsInput>
                        </div>
                    </Grid>
                    <Grid item md={1}>
                        <Button 
                            onClick={onSendButtonClick}
                            disabled={text === ''}
                            style={{margin:'10px 8px auto 0px', verticalAlign:'middle', minWidth:'20px'}}>
                            {notesLoading ?
                                <CircularProgress size={25} style={{color: "#008a7c"}} />
                                :
                                <SendIcon className={classes.sendButtonStyle}/>}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    members: state.triageSelectedOrganizationInfo.members,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(NotesDrawer);