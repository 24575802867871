import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/triageAuth';
import drawerReducer from '../reducers/drawer';
import triageOrganizationsReducer from '../reducers/triageOrganizations';
import triageSelectedOrganizationReducer from '../reducers/triageSelectedOrganization';
import loadingReducer from '../reducers/loading';
import triageOverlayReducer from '../reducers/triageOverlayReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Store creation:
const storeObj =  (persistedState) => {
    const store = createStore(
        combineReducers({
            triageAuth: authReducer,
            drawerState: drawerReducer,
            triageOrganizations: triageOrganizationsReducer,
            triageSelectedOrganizationInfo: triageSelectedOrganizationReducer,
            loading: loadingReducer,
            triageOverlay: triageOverlayReducer,
        }),
        persistedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default storeObj