import React, {useState, useEffect, Fragment} from 'react';
import  Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { consoleToLog } from '../util/AppUtil';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { makeStyles } from '@material-ui/core/styles';
import { orgCompaniesListApi } from '../services/mainAppService';
import {autoCompleteClientApi} from '../services/clientService';
import {Grid, Avatar, Chip, Tooltip} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px 1px 1px 4px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
        fontSize: "13px",
        }
    },
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }
}))

const AutoCompleteCompany = (props) => {
    const [value, setValue] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [inputText, setInputText] = useState('')
    const [open, setOpen] = useState(false);
    const isMount = useIsMount();
    const classes = useStyles();

    useEffect(() => {
        if(!isMount) {
            consoleToLog("Autocomplete companies- on company change: in useeffect", selectedCompany);
            props.onSelectedCompanies(selectedCompany);
        }
    }, [selectedCompany])

    const fetchCompanyList = (text) => {
        const organization_id = props.triageSelectedOrganization.organization.id;

        if(!text) return;

        autoCompleteClientApi(organization_id, text)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response orgCompaniesListApi: ', res);

                if(res.companies && res.companies.length > 0) {
                    setCompanies(res.companies);
                } else {
                    const noCF = { id: -1, name: 'No companies found' }
                    setCompanies([noCF])
                }
        })
        .catch((e) => {
            consoleToLog('Error orgCompaniesListApi: ', e.response);
        })
    }

    const handleInputChange = (e, newValue) => {
        fetchCompanyList(newValue);
            
        setInputText(newValue);
            if (!newValue) {
                setOpen(false);
            }
    }


    const onDeleteCompany = (option) => {
        
        setSelectedCompany(selectedCompany.filter(entry => entry !== option));
    }


    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:"100%"}} 
                className={classes.autoStyle}
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
        );
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.name}>
                    <Tooltip title={option.name} arrow>
                        <Chip
                            classes={{
                                root: classes.chip
                            }}
                            avatar={<Avatar classes={{
                                root: classes.avatar
                            }}>{option.name.charAt(0)}</Avatar>}
                            label={option.name}
                            onDelete={() => onDeleteCompany(option)}
                            variant="outlined"
                            style={{margin: "2px 2px", overflow:'hidden'}}
                            deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                        />
                    </Tooltip>
                </Fragment>))
    }

    return (
        <Autocomplete
            multiple={true}
            style={{width: "100%"}}
            id="size-small-outlined-multi"
            size="small"
            open={open}
            onOpen={() =>  {
                if(inputText) {
                    setOpen(true)
                }
            }}
            onClose={() => setOpen(false)}
            options={companies}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            disableClearable
            renderOption={(option) => renderOptions(option)}
            value={selectedCompany}
            onChange={(e, newValue) => {
                setSelectedCompany(newValue)
            }}
            onInputChange={(e, newValue) => handleInputChange(e, newValue)}
            renderTags={(options) => renderTags(options)}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Search Company" />
            )}
        />
            );
}

const mapStateToProps = (state) => ({
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
})

export default connect(mapStateToProps)(AutoCompleteCompany);