import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles'; 
import moment from "moment";
import { consoleToLog } from "../util/AppUtil";

const useStyles = makeStyles((theme) => ({
    btn: {
        width: "242.06px", 
        fontWeight: 400, 
        boxShadow: "none !important", 
        //borderLeft:  "1px solid #ccc", 
        //borderRight:  "1px solid #ccc", 
        color: "#000",  
        borderRadius: "0", 
        justifyContent: "left", 
        backgroundColor: "#FFF",
        padding: "6px 12px !important"
    }
}));

const Calender = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    
    const [weekendPassed, setWeekendPassed] = useState(false);
    const [weekendDate, setWeekendDate] = useState("");
    const [nextMonday, setNextMonday] = useState("");
    const [todayDay, setTodayDay] = useState();
    const [tomorrowDay, setTomorrowDay] = useState();

    const classes = useStyles();

    const onChange = (dates) => {
        //const [start, end] = dates;
        setStartDate(dates.start);
        setEndDate(dates.end);
        props.clickedDate(dates);
        //consoleToLog(dates)
    };

    useEffect(()=> {
        const today= moment().endOf('day');
        setTodayDay(today.format('ddd'));
        //consoleToLog("Today: ", today.format('ddd'));

        const tomorrow  = moment().add(1, 'days');
        setTomorrowDay(tomorrow.format('ddd'))
        //consoleToLog("Tomorrow: ", tomorrow.format('ddd')); 

        weekend();
        nextWeek();
    }, []);

    const weekend = () => {
        const dayINeed = 6; // for saturday
        const today = moment().isoWeekday();
        //consoleToLog("today:  ", today);

        var day;
        // if we haven't yet passed the day of the week that I need:
        if (today < dayINeed) { 
        // then just give me this week's instance of that day
        day = moment().isoWeekday(dayINeed);
        setWeekendPassed(false);
        //consoleToLog("weekend not passed  ", day);
        setWeekendDate(day.format('YYYY-MM-DD'));
        } else {
        // otherwise, give me *next week's* instance of that same day
            day = moment().add(1, 'weeks').isoWeekday(nextMonday);
            //consoleToLog("weekend passed", day);
            setWeekendPassed(true);
            setWeekendDate(day.format('YYYY-MM-DD'));
        }

        //consoleToLog("Weekend ", day.format('YYYY-MM-DD'));
        //setWeekendDate(day.format('YYYY-MM-DD'));
    }

    const nextWeek = () => {
        const dayINeed = 1; // for monday
        const today = moment().isoWeekday();
        //consoleToLog("dayINeed:  ", today);

        var day;
        day = moment().add(1, 'weeks').isoWeekday(dayINeed);
        setNextMonday(day.format('YYYY-MM-DD'));
        //consoleToLog("Monday ", day.format('ddd DD MMM'));
    }

  return (
    <div>
        <div>
        <Button className={classes.btn}
            style={{fontSize: "13px"}}
            variant="contained"
            onClick={()=>{
                const todayStr=moment().endOf('day').format('YYYY-MM-DD');
                props.clickedDate(todayStr);
            }}
        >   
            <img alt="edit" src="/images/today.svg" style={{marginRight:"6px"}}/>  
            Today
            <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{todayDay}</span>
        </Button>
        </div>
        <div>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                const tomorrow  = moment().add(1, 'days').format('YYYY-MM-DD');
                props.clickedDate(tomorrow);
            }}
        >
            <img alt="edit" src="/images/tomorrow.svg" style={{marginRight:"6px"}}/>
            Tomorrow
            <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{tomorrowDay}</span>
        </Button>
        </div>
        <div>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                props.clickedDate(weekendDate);
            }}
        >
            <img alt="edit" src="/images/today.svg" style={{marginRight:"6px"}}/>
            {weekendPassed ? "Next Weekend" : "This Weekend"}
            <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>Sat</span>
        </Button>
        </div>
        <div>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                props.clickedDate(nextMonday);
            }}
        >
            <img alt="edit" src="/images/nextweek.svg" style={{marginRight:"6px"}}/>
            Next Week
            <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{nextMonday}</span>
        </Button>
        </div>
        <div>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                props.clickedDate("");
            }}
        >
            <img alt="edit" src="/images/nodate.svg" style={{marginRight:"6px"}}/>
            No Date
        </Button>
        </div>
        <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            //selectsRange
            inline
        />
    </div>
  );
}

export default Calender;