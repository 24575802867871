import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import DashboardPage from "../components/DashboardPage";
import LoginPage from "../components/LoginPage";
import VerifySupportEmailPage from "../components/VerifySupportEmailPage";

const AppRouter = (props) => (
    <BrowserRouter>
        <div>
            <Routes>
                <Route path="/" element={<LoginPage/>} />
                <Route path="/dashboard" element={<DashboardPage/>} />
                <Route path="/verify_gmail" element={<VerifySupportEmailPage />} />
                <Route path="/verify_outlook" element={<VerifySupportEmailPage />} />
            </Routes>
        </div>
    </BrowserRouter>
);

export default AppRouter;