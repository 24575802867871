export const OverlayView  = {
    SHOW_OVERLAY_LOADING  : 'overlay_loading',
    NO_VIEW : 'no_view',
    ERROR_VIEW : 'error_view'
};

export const setOverlayView = (exportView) => ({
    type: 'SET_OVERLAY_VIEW',
    exportView
})

export const setOvelayMessage = (overlayMessage) => ({
    type :'SET_OVERLAY_MESSAGE',
    overlayMessage
})

export const setEmailItemId = (emailItemId) =>({
    type:"SET_EMAIL_ITEM_ID",
    emailItemId
})

export const setIsEmailSend = (isEmailSend) =>({
    type:"SET_IS_EMAIL_SEND",
    isEmailSend
})