import { OverlayView } from "../actions/triageOverlay";

//manage dashboard page loading
const defaultState = {
    showOverlayView: OverlayView.NO_VIEW,
    overlayMessage:"",
    emailItemId: "",
    isSendEmail:false
}

const overlay= (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_OVERLAY_VIEW':
            return {
                ...state,
                showOverlayView: action.exportView
            };
        case 'SET_OVERLAY_MESSAGE':
            return{
                ...state,
                overlayMessage : action.overlayMessage 
            } 
        case 'SET_EMAIL_ITEM_ID':
            return{
                ...state,
                emailItemId : action.emailItemId 
            }
        case 'SET_IS_EMAIL_SEND':
            return{
                ...state,
                isSendEmail : action.isEmailSend 
            }
            
        default:
            return state;
    }
};

export default overlay;