import React,{ useState, useEffect } from 'react';
import {Grid, Tooltip, TextField, Typography, Button, Divider, ClickAwayListener, InputAdornment} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useIsMount } from "./useIsMount";
import {orgCompaniesListApi} from '../services/mainAppService';
import { connect } from 'react-redux';
import { consoleToLog } from '../util/AppUtil';
import ClearIcon from '@material-ui/icons/Clear';
import { showIcons } from '../util/AppUtil';
import {autoCompleteClientApi} from '../services/clientService';
import {getForEmailAllClientsApi} from '../services/genieService';


const useStyles = makeStyles((theme) => ({
    addButtonStyle: {
        position: "relative",
        width: "23px",
        height: "23px",
        minWidth: "0",
        marginTop: "-1px",
        fontSize: "13px",
        color: "#0071ff",
        borderRadius: "50%",
        border: "1px solid #0071ff",
        paddingTop: "3px",
    },
    companyNameStyle: {
        fontSize: '14px',
        fontWeight: 500,
        padding: '4px 0px'
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover' : {
            background:'#F9F9F9'
        }
    },
    listContainer : {
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    MuiFormControlroot: {
        '& .MuiInputBase-input' :{
            padding: '8px',
            color:'grey',
            background:'#f7f7f7'
        },
        '& .MuiOutlinedInput-root' :{
            position: 'relative',
            borderRadius: '6px',
        },   
        '& .MuiOutlinedInput-adornedStart' : {
            background:'#fff',
            paddingLeft: '7px',
        }
    },
}))

const SetClientForTriageItem = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [companyList, setCompanyList] = useState([]);
    const [selectedCompanyList, setSelectedCompanyList] = useState(props.selectedTriageObj?.data.companies);
    const [searchText, setSearchText] = useState('');

    const organization_id = props.triageSelectedOrganization.organization.id;
    const from_email = props.selectedTriageObj?.data.from_email;

    useEffect(() => {
        if (!isMount) {
            setSelectedCompanyList(props.selectedTriageObj?.data.companies);
        }
    }, [props.selectedTriageObj?.data.companies]);

    useEffect(() => {
        if(!isMount) {
            if(props.checklistTextBoxClicked) {
                onClickAway();
                props.setChecklistTextboxClicked(false);
            }
        }

    }, [props.checklistTextBoxClicked])

    const fetchCompanyList = (text) => {

        if(!text) {
            getAllClientsApi();
            return;
        }

        autoCompleteClientApi(organization_id, text)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response autoCompleteClientApi: ', res);

            let filterSelectedCompany = res.companies.filter(company => !props.selectedTriageObj?.data.companies.some(selectedCompany => company.id === selectedCompany.id));
            setCompanyList(res.companies);
        })
        .catch((e) => {
            consoleToLog('Error autoCompleteClientApi: ', e.response);
        })

    }

    const getAllClientsApi = () => {
        getForEmailAllClientsApi(organization_id, from_email)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getForEmailAllClientsApi', res);
                setCompanyList(res.clients);
            })
            .catch((e) => {
                consoleToLog('Error getForEmailAllClientsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const isSelectedCompany = (checkCompany) => {
        const tempArr = selectedCompanyList.filter(
            (company) => company.id === checkCompany.id
        );
        return tempArr.length > 0 ? true : false;
    };
    
    const onCompanyItemClick = (checkCompany, removeClient) => {
        if (isSelectedCompany(checkCompany)) {
            //remove
            const array = selectedCompanyList.filter(
            (company) => company.id !== checkCompany.id);

            const removedClientArr = selectedCompanyList.filter(
                (company) => company.id === checkCompany.id);
            //setSelectedCompanyList(array);
            onApplyChanges(array, removeClient, removedClientArr);
        } else {
            //add
            const array = selectedCompanyList.concat(checkCompany);
            //setSelectedCompanyList(array);
            onApplyChanges(array, false, []);
        }
    }

    const onApplyChanges = (list, removeClient, removedClientArr) => {
        props.addClientToTriageItem(list, removeClient, props.fromTriageDetails, removedClientArr);
        setSearchText('');
        setCompanyList([]);
    };

    // const showForTriageList = () => {
    //     return (
    //         <>
    //             <Grid item style={{ position: "relative", marginTop:'16px'}}>
    //                 <Typography variant='subtitle1' style={{marginBottom:'-8px'}}>
    //                     Search Client
    //                 </Typography>
    //                 <TextField 
    //                     variant='outlined'
    //                     margin='dense'
    //                     id="label_input"
    //                     fullWidth
    //                     value={searchText}
    //                     placeholder="Search Clients"
    //                     style={{ marginBottom: "8px" }}
    //                     onClick={(e) => e.stopPropagation()}
    //                     onChange={(e) => {
    //                         setSearchText(e.target.value)
    //                         fetchCompanyList(e.target.value)
    //                     }}
    //                 />
    //                     <ClickAwayListener onClickAway={() => {
    //                                 setSearchText('');
    //                                 setCompanyList([]);
    //                         }}>
    //                         <Grid item 
    //                             style={{minHeight:'10px !important'}}
    //                             className={`orgcomp__menu ${(searchText !== '')  ? "active" : "inactive"}`}
    //                         >
    //                             { companyList.length > 0 ?
    //                                 companyList.map((company) => {
    //                                     let company_name = company.name
    //                                     return <ul key={company.id} className={classes.listContainer}>
    //                                                     <li className={classes.list} 
    //                                                     onClick={(e) => onCompanyItemClick(company, false)}
    //                                                     >
    //                                                     <span>
    //                                                         {company_name}
    //                                                     </span>
                                                        
    //                                                 </li>
    //                                                 <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
    //                                             </ul>
    //                                 }) : <ul className={classes.listContainer} style={{textAlign:'right'}}>
    //                                         <li className={classes.list}>
    //                                             <span style={{fontSize:'14px', color:'#555'}}>
    //                                                 No Clients Found
    //                                             </span>
    //                                         </li>
    //                                     </ul> 
    //                             }
    //                         </Grid>
    //                     </ClickAwayListener>
    //             </Grid>
                
    //             <Grid item sm={12} style={{marginTop:'16px'}}>
    //                 {selectedCompanyList && selectedCompanyList.length > 0 &&
    //                     <Typography variant="button"  style={{fontWeight:600}} gutterBottom>
    //                         Clients
    //                     </Typography>
    //                 }

    //                 {
    //                     /* display initial 2 members if any*/ 
    //                     selectedCompanyList && selectedCompanyList.length > 0 &&
    //                     selectedCompanyList && selectedCompanyList.length > 0 && 
    //                     selectedCompanyList.map((company) => {
    //                         return <Grid item container key={company.id} direction='column'
    //                                     style={{ cursor: "pointer"}}>
    //                                         <Typography variant='body1' 
    //                                             className={`text_ellipsis company_checklist_style1`}>
    //                                             {company.name}    

    //                                             <ClearIcon fontSize='small' onClick={(e) => onCompanyItemClick(company, true)}/>
    //                                         </Typography>
    //                                 </Grid>
    //                     })
    //                 } 

    //                 {/* {companyList && companyList.length === 0 &&
    //                 <Typography variant="button" gutterBottom style={{textAlign:'center', padding:'4px 0px'}}>
    //                     No Clients Found
    //                 </Typography>
    //                 } */}
    //             </Grid>
    //         </>
    //     )
    // }

    const onClickAway = () => {
        setSearchText('');
        setCompanyList([]);
    }

    const showAssignClientUI = () => {
        return (
            <>
                <Grid item style={{ position: "relative", marginTop:'0px'}}>
                    <TextField fullWidth 
                        //value={checklistSearch}
                        variant='outlined'
                        margin='dense'
                        id="label_input"
                        value={searchText}
                        placeholder="Search Clients"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                            fetchCompanyList(e.target.value)
                        }}
                        classes={{
                            root: classes.MuiFormControlroot
                        }} 
                        InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <img src={showIcons('search')}/>
                            </InputAdornment>
                            ),
                            style: {paddingTop:'0.5px', paddingBottom:'0.5px', background:'#f7f7f7'}
                        }}
                        onFocus={getAllClientsApi}
                        />
                        <ClickAwayListener onClickAway={onClickAway}>
                            <Grid item 
                                style={{minHeight:'10px !important'}} 
                                className={`orgcomp__menu ${(companyList.length > 0)  ? "active" : "inactive"}`}
                            >
                                { companyList.length > 0 ?
                                    companyList.map((company) => {
                                        let company_name = company.name;
                                        const selected_company = selectedCompanyList?.some(companyObj => companyObj.id === company.id);
                                        return <ul key={company.id} className={classes.listContainer}>
                                                        <li className={classes.list} 
                                                            style={{background: selected_company && '#e7f5fe', color: selected_company ? '#0071bf' : '#333'}}
                                                            onClick={(e) => onCompanyItemClick(company, true)}
                                                        >
                                                        <span>
                                                            {company_name}
                                                        </span>
                                                        
                                                    </li>
                                                    <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
                                                </ul>
                                    }) : <ul className={classes.listContainer} style={{textAlign:'right'}}>
                                            <li className={classes.list}>
                                                <span style={{fontSize:'14px', color:'#555'}}>
                                                    No Clients Found
                                                </span>
                                            </li>
                                        </ul> 
                                }
                            </Grid>
                        </ClickAwayListener>
                </Grid>
                
                <Grid item sm={12} style={{marginTop:'16px'}}>
                    {
                        selectedCompanyList && selectedCompanyList.length > 0 &&
                        selectedCompanyList && selectedCompanyList.length > 0 && 
                        selectedCompanyList.map((company) => {
                            return <Grid item container key={company.id} direction='column'
                                        style={{ cursor: "pointer"}}>
                                            <Grid item container alignItems='center' className='company_checklist_style2'>
                                                <Tooltip title={company.name} arrow>
                                                    <Typography variant='body1' 
                                                        style={{width:props.fromTriageDetails ? '250px' : '350px'}}
                                                        className={`text_ellipsis1`}>
                                                        {company.name}    
                                                    </Typography>
                                                </Tooltip>
                                                <ClearIcon fontSize='small' 
                                                    className='icon_hover'
                                                    onClick={(e) => onCompanyItemClick(company, true)}/>
                                            </Grid>
                                    </Grid>

                        })
                    } 
                </Grid>
            </>
        )
    }


    return (
        <Grid item sm={12}>
            {
                showAssignClientUI()
            }
            
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.triageAuth,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(SetClientForTriageItem);