import './App.css';
import AppRouter from './routers/AppRouter';
import './css/flaticon.css';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import OverlayComponent from './components/OverlayComponent';
import './styles/styles.scss';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        TransitionComponent={Slide}>
        <AppRouter />
        <OverlayComponent />
      </SnackbarProvider>
    </div>
  );
}

export default App;
