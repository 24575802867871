import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, CircularProgress, Button, Checkbox, Divider, Tooltip, IconButton, ClickAwayListener, Popover, Link} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {consoleToLog, showTriageType, displayEmailSubject, showIcons} from '../util/AppUtil';
import TriageItemDetailsModal from './modal/TriageItemDetailsModal';
import {connect} from 'react-redux';
import {getTriageListApi} from '../services/genieService';
import SetClientForTriageItem from './SetClientForTriageItem';
import {modifyClientsForTriageItemApi, modifyChecklistsForTriageItemApi, clearTriageApi, getClearTriageListApi,
    readTriageItemApi, unReadTriageItemApi, getSentEmailsListApi} from '../services/genieService';
import { useIsMount } from './useIsMount';
import moment from 'moment';
import AssignCompanyChecklistDrawer from './AssignCompanyChecklistDrawer';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CreateChecklistDrawer from './CreateChecklistDrawer';
import { disableEmailFollowupApi } from '../services/mainAppService';
import { useSnackbar } from 'notistack';
import { SelectedDrawerState, } from '../actions/drawer';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import { trackGAEvent, TriageTypes } from '../util/AppUtil';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotesDrawer from './NotesDrawer';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {approvalStatus, ApprovalTypes, ApprovalStatus} from '../util/AppUtil';
import {approveRejectCancelApi, undoApproveRejectApi} from '../services/mainAppService';
import {downloadApprovalAttachmentApi} from '../services/mainAppService';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor:'white',
        marginTop:"8px",
        borderRadius:'4px',
        marginBottom:'32px'
    },
    triageFromEmailStyle: {
        color:  '#222 !important',
        maxWidth:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        fontSize:'16px',
        [theme.breakpoints.down(1500)] : {
            fontSize:'14px',
        }
    },
    countStyle: {
        color: '#ccc !important',
        fontSize: '14px',
        marginLeft: '6px'
    },
    emailBodyStyle: {
        color:'grey',
        overflow:'hidden !important',
        whiteSpace:'nowrap !important',
        textOverflow:'ellipsis !important',
        color: '#666666'
    },
    companyStyle: {
        color:'grey',
        display:'flex',
        alignItems:'center'
    },
    addIconStyle: {
        fontSize:'18px',
        marginRight:'4px'
    },
    triageTagStyle: {
        color:'#6a6877',
        padding:'2px 8px',
        borderRadius:'10px',
        backgroundColor: '#e6e6e6'
    },
    customMarginTop: {
        marginTop:'8px'
    },
    customMarginTop1: {
        marginTop:'10px'
    },
    customMarginLeft: {
        marginLeft:'8px'
    },
    triageTypeStyle: {
        borderRadius:'20px',
        fontSize:'12px',
        padding:"0px 8px 1px 8px",
        color:'#fff',
        width:'fit-content'
    },
    triageItemHover: {
        position:'relative',
        height:'100%',
        paddingTop:'8px',
        paddingBottom:'16px',
        '&:first-child': {
            paddngTop:'0px'
        },
        '& .emailTypeStyle': {
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-end',
            paddingRight:'16px',
            textAlign:'center',
            [theme.breakpoints.down(1500)] : {
                paddingRight:'8px',
            }
        },
        '& .emailTypeStyle1': {
            display:'flex', 
            flexDirection:'column', 
            alignItems:'center',
            paddingRight:'16px',
            [theme.breakpoints.down(1500)] : {
                paddingRight:'8px',
            }
        },
        '&:hover .emailTypeStyle': {
            display:'none'
        },
        '&:hover .emailTypeStyle1': {
            display:'none'
        },
        '& .moreActionStyle': {
            display:'none'
        },
        '& .moreActionStyle1': {
            display:'none'
        },
        '& .moreActionStyle2': {
            display:'none'
        },
        '& .moreActionStyle3': {
            display:'none'
        },
        '&:hover .moreActionStyle': {
            display:'grid',
            gridTemplateColumns: '0.5fr 0.5fr 0.5fr 0fr',
            height:'100%',
            marginLeft:'-380px',
            marginRight:'16px',
            [theme.breakpoints.down(1500)] : {
                marginRight:'12px',
            }
            
        },
        '&:hover .moreActionStyle1': {
            display:'grid',
            gridTemplateColumns: '1fr',
            height:'100%',
            marginRight:'16px',
            [theme.breakpoints.down(1500)] : {
                marginRight:'12px',
            }
            
        },
        '&:hover .moreActionStyle2': {
            display:'grid',
            gridTemplateColumns: '1fr 1fr 0fr 0fr',
            height:'100%',
            marginLeft:'-190px',
            marginRight:'16px',
            [theme.breakpoints.down(1500)] : {
                marginRight:'12px',
            }
            
        },
        '&:hover .moreActionStyle3': {
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-end',
            height:'100%',
            marginLeft:'-180px',
            marginRight:'16px',
            [theme.breakpoints.down(1500)] : {
                marginRight:'12px',
            }
            
        },
        '& .stop_followup' : {
            //position:'absolute',
            //right:'310px',
            background:'white',
            border:'1px solid #dc4f42',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            alignSelf:'center',
            color:'#dc4f42'
        },
        '& .assign_checklist' : {
            //position:'absolute',
            background:'white',
            border:'1px solid #555555',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            alignSelf:'center',
            color:'#555555'
        },
        '& .assign_client' : {
            //position:'absolute',
            //right:'170px',
            background:'white',
            border:'1px solid #555555',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            alignSelf:'center',
            color:'#555555'
        },
        '& .clear_triage' : {
            //position:'absolute',
            //right:'30px',
            background:'white',
            border:'1px solid #dc4f42',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            alignSelf:'center',
            color:'#dc4f42'
        },
        '& .clear_triage1' : {
           // position:'absolute',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
        },
        '& .more_actions' : {
            //position:'absolute',
            minWidth:'28px !important',
            padding:'1px 0px !important',
            background:'white',
            border:'1px solid #555555',
            borderRadius:'5px',
            fontSize:'13px',
            alignSelf:'center',
            color:'#555555'
        },
        '& .approve_item' : {
            //position:'absolute',
            background:'white',
            border:'1px solid #555555',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            alignSelf:'center',
            color:'#555555'
        },
        '& .approve_item1' : {
            //position:'absolute',
            width:"130px",
            background:'white',
            border:'1px solid #555555',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            alignSelf:'center',
            color:'#555555',
            position:'relative',
            top:'4px'
        },
        '&:hover': {
            //boxShadow: 'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)'
            boxShadow: '0 1px 8px 1px rgb(100 121 143 / 12%)',
        }

    },
    triageTimeStyle: {
        marginTop:'4px',
        fontWeight:500,
        [theme.breakpoints.down(1500)] : {
            fontSize:'12px'
        }
    },
    noteStyle: {
        color:'grey'
    },
    customLineHeight: {
        lineHeight:'23px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'10px'
    },
    subjectStyle: {
        width:'auto',
        maxWidth:'280px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1500)] : {
            fontSize:'14px',
            maxWidth:'230px',
        }
    },
    addButtonStyle: {
        position: "relative",
        width: "23px",
        height: "23px",
        border: "1px solid #0071ff",
        color: "#0071ff",
        fontSize:'13px',
        borderRadius:'50%'
    },
    addButtonIconStyle: {
        width: '1em',
        height: '1em',
        fontSize: '1rem',
    },
    moreActionsIcons: {
        marginRight: '4px', 
        color: '#555'
    },
    list : {
        cursor:'pointer',
        '&:hover' : {
            background:'#d3d3d3'
        }
    },
    headings: {
        fontWeight: '600',
        fontSize:'14px'
    },
    customColumn1: {//for checkbox
        flexBasis:"4%", 
        maxWidth:"4%"
    },
    customColumn2: {//for checkbox
        flexBasis:"59%", 
        maxWidth:"59%"
    },
    customColumn3: {//for checkbox
        flexBasis:"13%", 
        maxWidth:"13%",
        wordWrap: 'break-word',
        marginTop:'2px'
    },
    customColumn5: {//for checkbox
        flexBasis:"11%", 
        maxWidth:"11%"
    },
    moreItemStyle: {
        fontSize:'13px',
        textDecoration:'underline',
        marginLeft:'4px'
    },
    attachmentNoteStyle: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: '4px !important',
        backgroundColor: '#F8F8F8 !important',
        padding:'4px 9px',
        marginTop:'5px',
        fontSize:'14px',
        fontWeight:'400',
        width: 'auto',
        maxWidth:'240px',
    },
    moreAttachmentNoteStyle: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: '4px !important',
        backgroundColor: '#F8F8F8 !important',
        padding:'4px 9px',
        marginTop:'5px',
        fontSize:'14px',
        fontWeight:'400',
    },
    actionIcons: {
        width:'15px',
        marginRight:'6px',
        color:'blue'
    },
    actionIcons1: {
        width:'12px',
        marginRight:'6px',
        color:'blue'
    },
    followupTextStyle: {
        width:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    emailFollowupTag: {
        height:'20px',
        borderRadius:'14px',
        width:'70px',
        color:'#747775',
        textAlign:'center',
        fontSize:'12px',
        marginTop:'-3px'
    },
    emailAutomatedTag: {
        height:'20px',
        borderRadius:'14px',
        width:'70px',
        color:'#fff',
        textAlign:'center',
        fontSize:'12px',
        marginTop:'-3px'
    },
    triageItemTextStyles: {
        fontSize: '13px'
    },
    emailsArrStyle: {
        width: 'auto',
        maxWidth: '180px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            maxWidth: '160px',
        }
    },
    emailpaddingLeft: {
        paddingLeft: '16px',
    },
    emailCustomColumn1: {//for checkbox
        flexBasis:"59%", 
        maxWidth:"59%"
    },
    emailCustomColumn2: {//for checkbox
        flexBasis:"15%", 
        maxWidth:"15%",
        wordWrap: 'break-word',
        marginTop:'2px'
    },
    emailCustomColumn3: {//for checkbox
        flexBasis:"11%", 
        maxWidth:"11%"
    },
    sentEmailMarginLeft1: {
        marginLeft: '-30px',
        [theme.breakpoints.down(1450)] : {
            marginLeft: '-23px'
        }
    },
    sentEmailMarginLeft2: {
        marginLeft: '-48px',
        [theme.breakpoints.down(1450)] : {
            marginLeft: '-43px'
        }
    },
    noTriageImage: {
        height: "14rem",
        width: "26rem",
    },
    noTriageText: {
        fontSize: "20px",
        fontWeight: "600",
    },
    noListContainer: {
        padding:'100px 0px 150px 0px',
        [theme.breakpoints.down(1450)] : {
            padding:'80px 0px 120px 0px',
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    triageNoteStyle: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: '4px !important',
        backgroundColor: '#F8F8F8 !important',
        padding:'4px 9px',
        marginTop:'5px',
        fontSize:'14px',
        fontWeight:'400',
        width: 'auto',
        maxWidth:'130px',
    },
    noteCountStyle: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: '4px !important',
        backgroundColor: '#F8F8F8 !important',
        padding:'5.5px 9px',
        marginTop:'5px',
        fontSize:'14px',
        fontWeight:'400',
    },
    taskStyle: {
        fontSize:'15px',
        color:"#0071FF",
        marginBottom:'8px'
    },
    userApproverStyle :{
        display:'flex',
        alignItems: 'center'
    },
    userApproverNameStyle: {
        fontSize:"15px",
    },
    forwardArrowStyle: {
        fontSize:'24px',
        margin:"0px 8px",
        position:"relative",
    },
    textEllipsis: {
        maxWidth:'260px',
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            maxWidth:'220px',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth:'190px',
        }
    }
}))

const TriageList = (props) => {

    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [triageList, setTriageList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTriageItem, setSelectedTriageItem] = useState(undefined);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [openClientTab, setOpenClientTab] = useState(false);
    const [openChecklistTab, setOpenChecklistTab] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedTriageObj, setSelectedTriageObj] = useState(undefined);
    const [fromDetailsOpen, setFromDetailsOpen] = useState(false);
    const [openChecklistDrawer, setOpenChecklistDrawer] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [triageId, setTriageId] = useState(undefined);
    const [isShiftDown, setIsShiftDown] = useState(false);
    const [dontReload, setDontReload] = useState(false);
    const [sentEmailList, setSentEmailList] = useState([]);
    const [emailUuid, setEmailUuid] = useState(undefined);

    //for selecting triage checkbox
    const [selectedTriage, setSelectedTriage] = useState([]);
    const [lastSelectedItem, setLastSelectedItem] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [isActive1, setIsActive1] = useState(false);
    let listEl = null;

    const showTriage = props.drawerState === SelectedDrawerState.SHOW_TRIAGE;
    const showClearTriage = props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE;
    const showSentEmails = props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS;
    const {user} = props.triageSelectedOrganization;
    const [selectedTriageItemObj, setSelectedTriageItemObj] = useState(undefined);
    const [openNotesDrawer, setOpenNotesDrawer] = useState(false);

    useEffect(() => {
        if(props.isEmailSendError && props.rawS3EmailPath !== '') {
            setOpenDialog(true);
        }
    }, [])

    useEffect(() => {
        if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
            fetchTriageList();
        }

        if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
            fetchClearedTriageList();
        }

        if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
            fetchSentEmailList();
        }
    }, [])

    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                consoleToLog('isMore loading api called')
                if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
                    fetchTriageList();
                }

                if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
                    fetchClearedTriageList();
                }

                if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
                    fetchSentEmailList();
                }
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if(!isMount) {
            // send SelectedChecklistCount
            props.getSelectedTriageCount(selectedTriage.length);
            if(selectedTriage.length === 0) {
                consoleToLog("USeEffect: Inside if condition selectedChecklist",selectedTriage.length);
                props.setCheckAll(false);
            } else {
                props.setCheckAll(true);
            }
        }
    }, [selectedTriage]);

    useEffect(() => {
        if(!isMount) {
            if(props.checkAll && selectedTriage.length === 0) {
                setSelectedTriage(triageList.map(triageItem => triageItem))
            } else {
                
                if(!props.checkAll) {
                    setSelectedTriage([]);
                }
            }
        }
    }, [props.checkAll]);

    useEffect(() => {
        if(!isMount) {
            if(props.clearTriageItems && props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
                clearTriage();
            }
        }
    }, [props.clearTriageItems]);

    useEffect(() => {
        if(!isMount) {
            document.addEventListener("keyup", handleKeyUp, false);
            document.addEventListener("keydown", handleKeyDown, false);
            listEl?.addEventListener("selectstart", handleSelectStart, false);
            // return () => {
            //     listEl.current.removeEventListener("selectstart", handleSelectStart, false);
            // };

            return () => {
                document.removeEventListener("keyup", handleKeyUp);
                document.removeEventListener("keydown", handleKeyDown);
                listEl?.removeEventListener("selectstart", handleSelectStart);
            }
        }
    }, [selectedTriage, lastSelectedItem]);

    useEffect(() => {
        if(!isMount) {
            if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
                setPage(0);
                setDontReload(true);
            }
                
            if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
                setPage(0);
            }

            if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
                setPage(0);
            }
        }
    }, [props.drawerState]);

    useEffect(() => {
        if(!isMount) {
            if(page === 0) {
                if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE && !isMoreLoading) {
                    fetchTriageList();
                    setDontReload(false);
                }
                if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
                    fetchClearedTriageList();
                }
                if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
                    fetchSentEmailList();
                }
            } 
        }
    }, [page, props.drawerState, props.searchText, props.selectedTypeValue]);

    useEffect(() => {
        if(!isMount) {
            if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
                if(props.messageObj) {
                    setTriageList(props.triageListFromParent);
                    props.fetchTriageListFromChild(props.triageListFromParent);
                    props.setMessageObj(undefined);
                }
            }
        }
    }, [props.triageListFromParent]);

    useEffect(() => {
        if(!isMount) {
            if(selectedTriageItem && !showSentEmails) {
                updateSelectedTriageItem()
            }
        }
    }, [triageList]);

    useEffect(() => {
        if(!isMount) {
            if(props.markAsRead) {
                callEmailReadTriageItemsApi();
            }
        }
    }, [props.markAsRead]);

    useEffect(() => {
        if(!isMount) {
            if(props.markAsUnRead) {
                callEmailUnReadTriageItemsApi();
            }
        }
    }, [props.markAsUnRead]);

    useEffect(() => {
        if(!isMount) {
            if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
                setPage(0);
                setNextPage(false);
            }
                
            if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
                setPage(0);
                setNextPage(false);
            }

            if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
                setPage(0);
                setNextPage(false);
            }
        }
    }, [props.searchText, props.selectedTypeValue]);

    useEffect(() => {
        if(!isMount) {
            props.setSelectedTriageList(selectedTriage);
        }
    }, [selectedTriage]);

    const handleDetailsDialogOpen = (triageObj) => {
        if(triageObj.data) {
            setOpenDialog(true);
            setSelectedTriageItem(triageObj);
        } else {
            enqueueSnackbar("Email was not sent successfully. Please try sending this email again.", {variant:'error'});
            return;
        }
    }

    const handleDetailsDialogClose = () => {
        setOpenDialog(false);
        setSelectedTriageItem(undefined)
    }

    const handleDrawerOpen = (e, triage) => {
        e.stopPropagation();
        setOpenDrawer(true);
        setSelectedTriageObj(triage);
    }

    const handleNotesDrawerOpen = (e, triageItem) =>{
        e.stopPropagation();
        setOpenNotesDrawer(true);
        setSelectedTriageItemObj(triageItem);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setSelectedTriageObj(undefined);
        openClientTab && setOpenClientTab(false);
        openChecklistTab && setOpenChecklistTab(false);
        fromDetailsOpen && setFromDetailsOpen(false);
    }

    const handleSelectStart = (e) => {
        // if we're clicking the labels it'll select the text if holding shift
        if (isShiftDown) {
            e.preventDefault();
        }
    }

    const handleKeyUp = (e) => {
        if (e.key === "Shift" && isShiftDown) {
            setIsShiftDown(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Shift" && !isShiftDown) {
            setIsShiftDown(true);
        }
    }

    const onSelectedTriage = (triageItem) => {
        const tempArr =  selectedTriage.filter((item) => item.id === triageItem.id);
        return tempArr.length > 0 ? true : false;
    }

    const handleClick = (e, triageItem) => {
        consoleToLog("****handleClick array in onSelectedChecklist** ", selectedTriage.length);
        if(selectedTriage.length > 50 || selectedTriage.length === 50){
            if(onSelectedTriage(triageItem)) {
                const array = selectedTriage.filter((item) => item.id !== triageItem.id);
                setSelectedTriage(array);
            } else {    
                enqueueSnackbar('Cannot select more than 50 items !', {
                    variant: 'error'
                });
            }
        } else {
            const {value} = e.target;
            const nextValue = getNextValue(triageItem);

            setSelectedTriage(nextValue);
            setLastSelectedItem(triageItem.id);
        }
    }

    const getNextValue = (value) => {
        const hasBeenSelected = !selectedTriage.includes(value.id);
        //console.log('hasBeenSelected', hasBeenSelected);


        if (isShiftDown) {
            const newSelectedItems = getNewSelectedItems(value);
            // de-dupe the array using a Set
            const selections = [...new Set([...selectedTriage, ...newSelectedItems])];
            //console.log('selections', selections);

            if (!hasBeenSelected) {
                //console.log('new selected', selections.filter(item => !selectedTriage.includes(item.id)))
            return selections.filter(item => !selectedTriage.includes(item.id));
            }

            return selections;
        }

        // if it's already in there, remove it, otherwise append it
        return selectedTriage.includes(value)
            ? selectedTriage.filter(item => item.id !== value.id)
            : [...selectedTriage, value];
        }

        const getNewSelectedItems = (value) => {
            let currentSelectedIndex = triageList.findIndex(triageItem => triageItem.id === value.id);
            const lastSelectedIndex = triageList.findIndex(
                triageItem => triageItem.id === lastSelectedItem
            );
            //currentSelectedIndex = currentSelectedIndex > 49 ? 49 : currentSelectedIndex;


            //console.log('currentSelectedIndex:', currentSelectedIndex);
            //console.log('lastSelectedIndex:', lastSelectedIndex);

            return triageList
                .slice(
                Math.min(lastSelectedIndex, currentSelectedIndex),
                Math.max(lastSelectedIndex, currentSelectedIndex) + 1
                )
                .map(triageItem => triageItem);
        }

    const fetchTriageList = () => {
        const organization_id = props.triageSelectedOrganization.organization.id;
        const text = props.searchText;
        const type = props.selectedTypeValue

        if(!isMoreLoading)  setLoading(true);
        if(dontReload) {
            setLoading(false);
        }
        getTriageListApi(organization_id, page, text, type)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getTriageListApi: ', res);
                setLoading(false);
                if(dontReload) setDontReload(false);

                if(isMoreLoading) {
                    const newItems = triageList.concat(res.triages);
                    setTriageList(newItems);
                    props.fetchTriageListFromChild(newItems)
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                     // to parent
                    props.getTriageCount(newItems.length);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setTriageList(res.triages);
                    props.fetchTriageListFromChild(res.triages)
                     // to parent
                    props.getTriageCount(res.triages.length);
                }

            })
            .catch((e) => {
                consoleToLog('Error getTriageListApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.reponse.data.message) {
                    return;
                }
            })
    }

    const fetchClearedTriageList = () => {
        const organization_id = props.triageSelectedOrganization.organization.id;
        const is_cleared_at = true;
        const text = props.searchText;
        const type = props.selectedTypeValue;

        if(!isMoreLoading)  setLoading(true);
        getClearTriageListApi(organization_id, page, is_cleared_at, text, type)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClearTriageListApi: ', res);
                setLoading(false);

                if(isMoreLoading) {
                    const newItems = triageList.concat(res.triages);
                    setTriageList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setTriageList(res.triages);
                }

            })
            .catch((e) => {
                consoleToLog('Error getClearTriageListApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.reponse.data.message) {
                    return;
                }
            })
        
    }

    const updateTriageArr = (triageObj) => {
        let updatedArray = triageList.map((triage) => {
            if(triage.id === triageObj.id) {
                triage.data = triageObj.data;
            }
            return triage;
        });
        setTriageList(updatedArray);
    }

    const updateSentEmailsArr = (emailObj) => {
        let updatedArray = sentEmailList.map((email) => {
            if(email.email_uuid === emailObj.email_uuid) {
                email = emailObj
            }
            return email;
        });
        setSentEmailList(updatedArray);
        let obj = {
            id: undefined,
            data: emailObj
        }
        selectedTriageItem ? setSelectedTriageItem(obj) : setSelectedTriageObj(obj);

    }

    const addClientToTriageItem = (selectedCompany, removeClient, fromTriageItemDetails, removedClientArr) => {
        const triageObj = selectedTriageItem ? selectedTriageItem : selectedTriageObj;
        const email_uuid = triageObj.data?.email_uuid;
        const triage_id = triageObj.id;
        const company_ids = selectedCompany?.map((company) => company.id);

        modifyClientsForTriageItemApi(email_uuid, company_ids, triage_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response modifyClientsForTriageItemApi ', res);

                if(showTriage) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Client from Inbox', `${props.user.firstname} ${props.user.lastname} `);
                if(showClearTriage) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Client from Cleared Items', `${props.user.firstname} ${props.user.lastname} `);
                if(showSentEmails) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Client from Sent Emails', `${props.user.firstname} ${props.user.lastname} `);
                if(fromTriageItemDetails) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Client from Email Details', `${props.user.firstname} ${props.user.lastname} `);

                if(showSentEmails) {
                    updateSentEmailsArr(res.emailObj);
                } else {
                    updateTriageArr(res.triage);
                }

                if(removeClient && !showSentEmails) {
                    callChecklistApiInTriage(res.triage, triageObj, removedClientArr, fromTriageItemDetails);
                }
                if(removeClient && showSentEmails) {
                    callChecklistApiInSentEmail(res.emailObj, triageObj, removedClientArr, fromTriageItemDetails);
                }
            })
            .catch((e) => {
                consoleToLog('Error modifyClientsForTriageItemApi: ', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const callChecklistApiInTriage = (triageRes, triageObj, removedClientArr, fromTriageItemDetails) => {
        const checklist_ids = triageRes.data?.checklists.filter(checklistItem => {
            return triageRes.data?.companies.find(company => {
            return company.id === checklistItem.client_id
            });
        });
        const removedChecklistArray = triageRes.data?.checklists.filter(checklistItem => {
            return removedClientArr.find(company => {
            return company.id === checklistItem.client_id
            });
        });
        if(checklist_ids && checklist_ids.length > 0) {
            let triageObjFollowupArr = triageObj.data.metadata.followups;
            const followupArr = triageObjFollowupArr && triageObjFollowupArr.length > 0 && 
                triageObjFollowupArr.filter((followup) => {
                    return checklist_ids.some(checklistItem => {
                    return followup.checklist_ids?.includes(checklistItem.checklists_id ? checklistItem.checklists_id : checklistItem.checklist_id);
                    })
                });
                addChecklistToTriageItem(checklist_ids, followupArr, fromTriageItemDetails, removedChecklistArray);
        }  else {
                addChecklistToTriageItem(checklist_ids, [], fromTriageItemDetails, removedChecklistArray);
        }   
    }

    const callChecklistApiInSentEmail = (triageRes, triageObj, removedClientArr, fromTriageItemDetails) => {
        const checklist_ids = triageRes.checklists.filter(checklistItem => {
            return triageRes.companies.find(company => {
            return company.id === checklistItem.client_id
            });
        });
        const removedChecklistArray = triageRes.checklists.filter(checklistItem => {
            return removedClientArr.find(company => {
            return company.id === checklistItem.client_id
            });
        });
        if(checklist_ids && checklist_ids.length > 0) {
            let triageObjFollowupArr = triageObj.data.metadata.followups;
            const followupArr = triageObjFollowupArr && triageObjFollowupArr.length > 0 && 
                triageObjFollowupArr.filter((followup) => {
                    return checklist_ids.some(checklistItem => {
                    return followup.checklist_ids?.includes(checklistItem.checklists_id ? checklistItem.checklists_id : checklistItem.checklist_id);
                    })
                });
            addChecklistToTriageItem(checklist_ids, followupArr, fromTriageItemDetails, removedChecklistArray);
        }  else {
            addChecklistToTriageItem(checklist_ids, [], fromTriageItemDetails, removedChecklistArray);
        }   
    }

    const addChecklistToTriageItem = (selectedChecklist, followupArr, fromTriageItemDetails, removedChecklistArray) => {
        const triageObj = selectedTriageItem ? selectedTriageItem : selectedTriageObj;
        const email_uuid = triageObj.data?.email_uuid;
        const triage_id = triageObj.id;
        const checklist_ids = selectedChecklist?.map((checklistItem) => checklistItem.checklists_id ? checklistItem.checklists_id : checklistItem.checklist_id);
        const checklistUsers = selectedChecklist?.map((checklistItem) => checklistItem.checklist_member_ids);
        const checklist_member_ids = checklistUsers.flat().filter(ids => ids);
        const isSentEmail = (showTriage || showClearTriage) ? false : true;
        const organization_id = props.triageSelectedOrganization.organization.id;
        const removed_checklists = removedChecklistArray && removedChecklistArray.length > 0 ? removedChecklistArray.map((checklistItem) => checklistItem.checklists_id ? checklistItem.checklists_id : checklistItem.checklist_id) : undefined;
        //const followups = selectedChecklist?.map((checklistItem) => checklistItem.followups ? checklistItem.followups : []);
        //const followupsArr = followups && followups.length > 0 ? followups.flat() : [];
        consoleToLog('removed checklist array', removedChecklistArray);

        modifyChecklistsForTriageItemApi(email_uuid, organization_id, checklist_ids, triage_id, checklist_member_ids, followupArr, isSentEmail, removed_checklists) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response modifyChecklistsForTriageItemApi ', res);

                if(showTriage) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Checklist from Inbox', `${props.user.firstname} ${props.user.lastname} `);
                if(showClearTriage) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Checklist from Cleared Items', `${props.user.firstname} ${props.user.lastname} `);
                if(showSentEmails) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Checklist from Sent Emails', `${props.user.firstname} ${props.user.lastname} `);
                if(fromTriageItemDetails) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Assigned Checklist from Email Details', `${props.user.firstname} ${props.user.lastname} `);


                if(showSentEmails) {
                    updateSentEmailsArr(res.emailObj);
                } else {
                    updateTriageArr(res.triage);
                }
            })
            .catch((e) => {
                consoleToLog('Error modifyChecklistsForTriageItemApi: ', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const updateTriageArrayList = () => {
        let obj = {...selectedTriageItem,
                followup: undefined
        };
        setSelectedTriageItem(obj);
        fetchTriageList()
    }

    const handleChecklistDrawerOpen = (e) => {
        setOpenChecklistDrawer(true);
        isActive && setIsActive(false);
    }

    const handleChecklistDrawerClose = () => {
        setOpenChecklistDrawer(false);
        fromDetailsOpen && setFromDetailsOpen(false);
    }

    const handleNoteDrawerClose = () => {
        setOpenNotesDrawer(false);
    }

    const onMoreActionIconClick = (e, triageItem) => {
        e.stopPropagation();
        setIsActive(!isActive);
        setTriageId(triageItem.id);
        setSelectedTriageObj(triageItem);
    }

    const clearTriage = (triage) => {
        const triageIdArr = selectedTriage && selectedTriage.length > 0 ? selectedTriage.map((triageItem) => triageItem.id) : [];
        const triage_ids = triage ? [triage.id] : triageIdArr;

        clearTriageApi(triage_ids)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response clearTriageApi: ', res);
                enqueueSnackbar(res.message, {variant:'success'});

                openDialog && handleDetailsDialogClose();

                if(selectedTriage && selectedTriage.length === 0) {
                    const updatedTriageArr = triageList.filter((triageItem) => triageItem.id !== triage.id);
                    setTriageList(updatedTriageArr);
                    showTriage && props.fetchTriageListFromChild(updatedTriageArr);
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Cleared Items', `${props.user.firstname} ${props.user.lastname} `);
                } else {
                    const filteredTriageArr = triageList.filter((triageItem) =>  !triageIdArr.includes(triageItem.id));
                    setTriageList(filteredTriageArr);
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Bulk Cleared Items', `${props.user.firstname} ${props.user.lastname} `);
                    showTriage && props.fetchTriageListFromChild(filteredTriageArr);
                    setSelectedTriage([]);
                    props.setClearTriageItems(false);
                    props.setCheckAll(false);
                }
            })
            .catch((e) => {
                consoleToLog('Error clearTriageApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            })
    }

    const showTriageTile = () => {
        return (
            <Grid item container alignItems='center' className={'stickyTopBar1'}
                style={{marginTop:'8px'}}>
                <Grid item md={1} lg={1} className={classes.customColumn1}>
                    
                </Grid> 

                <Grid item md={showSentEmails ? 7 : 6} lg={showSentEmails ? 7 : 6} className={showSentEmails ? classes.emailCustomColumn1 : classes.customColumn2}>
                    <Typography  
                        style={{marginLeft: !showSentEmails && '3px'}}
                        className={`${classes.headings} ${showSentEmails && classes.sentEmailMarginLeft1}`}>
                        {showSentEmails ? 'Emails' : 'Inbox'}     
                    </Typography>
                </Grid>

                <Grid item md={2} lg={2} className={showSentEmails ? classes.emailCustomColumn2 : classes.customColumn3}>
                    <Typography 
                    style={{marginLeft: !showSentEmails && '0px'}}
                    className={`${classes.headings} ${showSentEmails && classes.sentEmailMarginLeft2}`}>
                        Clients     
                    </Typography>
                </Grid>   

                <Grid item md={2} lg={2} className={showSentEmails ? classes.emailCustomColumn2 : classes.customColumn3}>
                    <Typography 
                    style={{marginLeft: !showSentEmails && '0px'}}
                    className={`${classes.headings} ${showSentEmails && classes.sentEmailMarginLeft2}`}>
                        Checklists     
                    </Typography>   
                </Grid> 
                
                <Grid item md={1} lg={1} className={showSentEmails ? classes.emailCustomColumn3 : classes.customColumn5}>
                </Grid>
            </Grid>
        )
    }

    const showMoreCompaniesList = (triageCompanies) => {
        let companiesList = triageCompanies.slice(2)
        return  `+ ${companiesList.length} more`
    }

    const showMoreCheckList = (triageChecklist) => {
        let checklists = triageChecklist.slice(2)
        return  `+ ${checklists.length} more`
    }

    const getEmailColor = (triageObj, triageItemEmailRead) => {
        const to_email = triageObj.data?.to_email && triageObj.data?.to_email.length > 0 ? triageObj.data?.to_email : [];
        const cc_email = triageObj.data?.cc && triageObj.data?.cc.length > 0 ? triageObj.data?.cc : [];
        const allEmailsArr = [...to_email, ...cc_email];
        const supportEmail = props.triageOrgEmailList.filter((orgEmail) => allEmailsArr.includes(orgEmail.email));
        return supportEmail && supportEmail.length > 0 ? supportEmail[0].color : triageItemEmailRead ? '#F3F3F3' : 'white'
    }

    const getEmailColor1 = (emailObj) => {
        const to_email = emailObj.to_email && emailObj.to_email.length > 0 ? emailObj?.to_email : [];
        const cc_email = emailObj?.cc && emailObj?.cc.length > 0 ? emailObj?.cc : [];
        const allEmailsArr = [...to_email, ...cc_email];
        const supportEmail = props.triageOrgEmailList.filter((orgEmail) => allEmailsArr.includes(orgEmail.email));
        return supportEmail && supportEmail.length > 0 ? supportEmail[0].color : 'white'
    }

    const showMoreAttachments = (moreAttachments) => {
        return  `+${moreAttachments.length}`
    }

    const onClickAway1 = () => {
        isActive && setIsActive(false);
        isActive1 && setIsActive1(false);
        showSentEmails ? setEmailUuid(undefined) : setTriageId(undefined);  
    }

    const onStopEmailFollowupClick = (obj) => {
        setIsActive1(!isActive1);
        showSentEmails ? setEmailUuid(obj.email_uuid) : setTriageId(obj.id);    
    }

    const showEmailFollowupsPopover = (obj, followupList) => {
        return (
            (showSentEmails ? obj.email_uuid === emailUuid : obj.id === triageId) && 
            <ClickAwayListener onClickAway={onClickAway1}>
                <Grid item className={`menu ${isActive1 ? "active" : "inactive"}`} style={{width:'230px', textAlign:'left'}}>
                    <ul >
                        {followupList && followupList.length > 0 && followupList.map((followup) => {
                            
                            return  followup &&
                                    <li className={classes.list} key={followup.id}
                                            onClick={() => onEmailFollowupClick(followup, false)}
                                            style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                                            <Tooltip title={followup ? followup.subject : ''} arrow>
                                            <a className={classes.followupTextStyle}>{followup.subject}</a>
                                            </Tooltip>
                                    </li>
                        })
                            
                        }

                        
                    </ul>
                </Grid>
            </ClickAwayListener>
        )
    }

    const updateSelectedTriageItem = () => {
        const getSelectedTriageItem = triageList.filter((triageItem) => triageItem.id === selectedTriageItem.id);
        setSelectedTriageItem(getSelectedTriageItem[0]) 
    }

    const onEmailFollowupClick = (followup, fromSendEmailDetails) => {
        const organization_id = props.triageSelectedOrganization.organization.id;
        const followup_id = followup.id;

        disableEmailFollowupApi(organization_id, followup_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response disableEmailFollowupApi', res);

                if(showTriage) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Stop Followup from Inbox', `${props.user.firstname} ${props.user.lastname} `);
                if(fromSendEmailDetails) trackGAEvent(props.triageSelectedOrganization.organization.name, 'Stop Followup from Send Email Details', `${props.user.firstname} ${props.user.lastname} `);

                if(showClearTriage) {
                    const updatedArray = triageList.map((triageItem) => {
                            if(triageItem.id === triageId) {
                                triageItem.data.metadata.followups = triageItem.data.metadata.followups.filter((followup) => followup.id !== followup_id);
                            }
                            return triageItem;
                    });
                    setTriageList(updatedArray);
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Stop Followup from Cleared Items', `${props.user.firstname} ${props.user.lastname} `);
                }

                if(showSentEmails) {
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Stop Followup from Sent Emails', `${props.user.firstname} ${props.user.lastname} `);

                    const updatedArray = sentEmailList.map((emailObj) => {
                        if(emailObj.email_uuid === emailUuid) {
                            emailObj.metadata.followups = emailObj.metadata.followups.filter((followup) => followup.id !== followup_id);
                        }
                        return emailObj;
                    });
                    setSentEmailList(updatedArray);
                    if(selectedTriageItem) {
                        selectedTriageItem.data.metadata.followups = selectedTriageItem.data.metadata.followups.filter((followup) => followup.id !== followup_id)
                        setSelectedTriageItem(selectedTriageItem);
                    }
                }
                //props.updateTriageArrayList();
            })
            .catch((e) => {
                consoleToLog('Error disableEmailFollowupApi', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            })
    }

    const triageListEmailReadAndUnread = (allowRead, email_uuids) => {
        const udpateReadTriageArray = triageList.map((triageItem) => {
            email_uuids.map((uuid) => {
                if(triageItem.data.email_uuid === uuid) {
                    triageItem.data.read_users = allowRead ? [user] : [];
                }
            })
            return triageItem;
        })

        setTriageList(udpateReadTriageArray);
    }


    const callEmailReadTriageItemsApi = (selectedTriageEmailUuid) => {
        const emailUUIDArr = selectedTriage && selectedTriage.length > 0 ? selectedTriage.map((triageItem) => triageItem.data.email_uuid) : [];
        const email_uuids = selectedTriageEmailUuid ? [selectedTriageEmailUuid] : emailUUIDArr;
        props.setMarkAsRead(false);
        props.setCheckAll(false);

        readTriageItemApi(email_uuids)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response readTriageItemApi: ", res);

                if(emailUUIDArr && emailUUIDArr.length > 0) {
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Read Inbox Items', `${props.user.firstname} ${props.user.lastname} `);
                } else {
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Bulk Read Inbox Items', `${props.user.firstname} ${props.user.lastname} `);
                }
                triageListEmailReadAndUnread(true, email_uuids);
            })
            .catch((e) => {
                consoleToLog("Error readTriageItemApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const callEmailUnReadTriageItemsApi = (selectedTriageEmailUuid) => {
        const emailUUIDArr = selectedTriage && selectedTriage.length > 0 ? selectedTriage.map((triageItem) => triageItem.data.email_uuid) : [];
        const email_uuids = selectedTriageEmailUuid ? [selectedTriageEmailUuid] : emailUUIDArr;
        const organization_id = props.triageSelectedOrganization.organization.id;
        props.setMarkAsUnRead(false);
        props.setCheckAll(false);


        unReadTriageItemApi(organization_id, email_uuids)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response readTriageItemApi: ", res);

                if(emailUUIDArr && emailUUIDArr.length > 0) {
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Read Inbox Items', `${props.user.firstname} ${props.user.lastname} `);
                } else {
                    trackGAEvent(props.triageSelectedOrganization.organization.name, 'Bulk Read Inbox Items', `${props.user.firstname} ${props.user.lastname} `);
                }

                triageListEmailReadAndUnread(false, email_uuids);
            })
            .catch((e) => {
                consoleToLog("Error readTriageItemApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const getReadableNotesText = (note, tooltip) => {
        var readableNoteText = note.text;
        let  numberPattern = /\d+/g;
        let num = readableNoteText.match(numberPattern);
        num?.join();
        
        readableNoteText = readableNoteText.split('@[').join(tooltip ? `<span style=margin-right:4px>@` : '');
        readableNoteText = readableNoteText.split(']').join(tooltip ? `</span>` : '');
        readableNoteText = readableNoteText.split(/\(.+?\)/).join('');
        return readableNoteText;
        
    }

    const showMoreCommentsList = (moreNotes) => {
        return  `+${moreNotes - 1} more comments`
    }

    const showMoreNotesList = (notesCount) => {
        return  `+${notesCount - 3} more notes`
    }

    const fetchSentEmailList = () => {
        const organization_id = props.triageSelectedOrganization.organization.id;
        const support_emails = props.triageOrgEmailList?.map((emailObj) => emailObj.email).join(', ');
        const text = props.searchText;
        
        if(!isMoreLoading)  setLoading(true);
        getSentEmailsListApi(organization_id, page, support_emails, text)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getSentEmailsListApi ', res);
                setLoading(false);
                
                if(isMoreLoading) {
                    const newItems = sentEmailList.concat(res.emails);
                    setSentEmailList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setSentEmailList(res.emails);
                }

            })
            .catch((e) => {
                consoleToLog('Error getSentEmailsListApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    }

    const onSentEmailItemClick = (e, details, emailItem) => {
        let obj = {
                id: undefined,
                data: emailItem
        }

        details ? handleDetailsDialogOpen(obj) : handleDrawerOpen(e, obj)
    }

    const calculateMessageTimeReceived = (time) => {
        let lastExecutionTime = moment(time).utc().format(); //your last execution time
        let nextDay = moment(lastExecutionTime).add(12,'hours').utc().format(); // calculate 24 hours next to last access
        let currentTime = moment().utc().format(); //current time
        return moment(currentTime).isSameOrAfter(nextDay);
    }

    const showEmailOpenedPopover = (emailObj) => {
        return (
            <Popover
            id='email-recipient-popover'
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal:'center'
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            className={classes.popover}
            classes={{
                paper: classes.paper,
            }}
            >
                <Typography variant='body2'>
                    Email Opened: {emailObj.open_count ? emailObj.open_count : 0} times
                </Typography>

                <Typography variant='body2'>
                    Last Time Email Opened: {emailObj.last_opened_timestamp ? moment(emailObj.last_opened_timestamp).format('Do MMMM YYYY h:mm a') : ''}
                </Typography>
            </Popover>
        )
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const showName = (triageItem) => {
        var name='';
        if(triageItem.type === TriageTypes.EMAIL){
            name=triageItem.data?.from_name ? triageItem.data?.from_name : triageItem.data?.from_email;
        }
        if(triageItem.type === TriageTypes.CHECKLIST_NOTE){
            if(triageItem.data && triageItem.data.notes && triageItem.data.notes.length>0){
                name=triageItem.data.notes[0].note_user.name;
            } 
        }
        return name;
    }
    
    const showText = (triageItem) => {
        if(triageItem.type === TriageTypes.EMAIL){
            return triageItem.data?.subject;
        }
        else if(triageItem.type === TriageTypes.CHECKLIST_NOTE){
            var text='';
            if(triageItem.data.notes && triageItem.data.notes.length>0){
                text = getReadableNotesText(triageItem.data.notes[0], true);
            } 
            return text;
        }
    }

    const onTriageItemClick = (e, triageItem) => {
        if(triageItem.type === TriageTypes.EMAIL) {
            handleDetailsDialogOpen(triageItem)
        } else {
            handleNotesDrawerOpen(e, triageItem);
        }
    }

    const triageListNoteRead = (obj) => {
        const udpateReadTriageArray = triageList.map((triageItem) => {
            if(triageItem.target_id === obj.target_id) {
                triageItem = obj
            }
            return triageItem
        })
        setTriageList(udpateReadTriageArray);
    }

    const returnVal = (actionText) => {
        if(actionText === 'approved') return 'approve';
        if(actionText === 'rejected') return 'reject';
        if(actionText === 'cancelled') return 'cancel';
    }

    const approveRejectCancelMessage = (approvalObj, actionText) => {

        if(approvalObj.target_type === ApprovalTypes.CHECKLIST){
            return `Are you sure you want to ${returnVal(actionText)} this checklist?`
        }
        
        if(approvalObj.target_type === ApprovalTypes.TASK){
            return `Are you sure you want to ${returnVal(actionText)} this task?`
        }
    }

    const undoApproveRejectMessage = (approvalObj, actionText) => {

        if(approvalObj.target_type === ApprovalTypes.CHECKLIST){
            return `Are you sure you want to undo ${actionText} this checklist?`
        }
        
        if(approvalObj.target_type === ApprovalTypes.TASK){
            return `Are you sure you want to undo ${actionText} this task?`
        }
    }

    const updateTriageListForApproval = (triage_id, approvalObj) => {
        const updatedArray = triageList.map((triageItem) => {
            if(triageItem.id === triage_id) {
                triageItem.data.approval = approvalObj
            }
            return triageItem
        });
        setTriageList(updatedArray);
    }

    const callApproveRejectCancelApi = (e, approvalObj, actionText, triage_id) => {
        e.stopPropagation();

        const organization_id = props.triageSelectedOrganization.organization.id;
        const apiStr = approvalObj.target_type === ApprovalTypes.CHECKLIST ? `checklist/${approvalObj.target_id}/${approvalObj.id}/${actionText}`
        : `task/${approvalObj.target_id}/${approvalObj.id}/${actionText}`;
        const val = approvalObj.target_type === ApprovalTypes.CHECKLIST ? 'Checklist' : 'Checklist Task'

        if(window.confirm(approveRejectCancelMessage(approvalObj, actionText))) {
            approveRejectCancelApi(organization_id, apiStr)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response approveRejectCancelApi: ', res);
                    enqueueSnackbar(`${val} ${actionText} successfully`, {variant: 'success'});

                    updateTriageListForApproval(triage_id, res.approval);

                })
                .catch((e) => {
                    consoleToLog("Error approveRejectCancelApi: ", e);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
                });
        }
    }

    const onUndoApproveRejectClick = (e, approvalObj, actionText, triage_id) => {
        e.stopPropagation();
        const organization_id = props.triageSelectedOrganization.organization.id;
        console.log('approvalId', approvalObj.id);

        if(window.confirm(undoApproveRejectMessage(approvalObj, actionText))) {

            undoApproveRejectApi(organization_id, approvalObj.id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response undoApproveRejectApi: ', res);

                    updateTriageListForApproval(triage_id, res.approval);
                })
                .catch((e) => {
                    consoleToLog('Error undoApproveRejectApi: ', e.response);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
                })
        }
    }

    const showButtonContainerClasses = (triageObj, approval_obj, approverOrMember) => {
        if(triageObj.type === TriageTypes.EMAIL) {
            return 'moreActionStyle';
        } 
        else if(triageObj.type === TriageTypes.CHECKLIST_NOTE) {
            return 'moreActionStyle1'
        }
        else if(triageObj.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.PENDING && approverOrMember) {
            return 'moreActionStyle';
        }
        else if(triageObj.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.PENDING && !approverOrMember) {
            return 'moreActionStyle2';
        }
        else if(triageObj.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.CANCELLED) {
            return 'moreActionStyle1';
        }
        else if(triageObj.type === TriageTypes.APPROVAL && (approval_obj.approval_status === ApprovalStatus.APPROVED || approval_obj.approval_status === ApprovalStatus.REJECTED) && approverOrMember) {
            return 'moreActionStyle2';
        }
        else if(triageObj.type === TriageTypes.APPROVAL && (approval_obj.approval_status === ApprovalStatus.APPROVED || approval_obj.approval_status === ApprovalStatus.REJECTED) && !approverOrMember) {
            return 'moreActionStyle1';
        } else {
            return 'moreActionStyle1';
        }
    }

    const showBackGroundClasses = (triageItem, triageItemEmailRead, triageItemNotesRead) => {
        if(triageItem.type === TriageTypes.APPROVAL && triageItem.data?.approval?.approval_status === ApprovalStatus.PENDING) {
            return '#fff';
        } 
        if(triageItem.type === TriageTypes.APPROVAL && triageItem.data?.approval?.approval_status !== ApprovalStatus.PENDING) {
            return '#F3F3F3';
        }
        if(triageItemEmailRead || triageItemNotesRead) {
            return '#F3F3F3'
        } 
        if(!triageItemEmailRead || !triageItemNotesRead) {
            return '#fff'
        } 
    }

    const triageTypeColor = (type) => {
        switch (type) {
            case TriageTypes.EMAIL:
                return '#f1c31c';
            case TriageTypes.CHECKLIST_NOTE:
                return '#51dbd1';
            case TriageTypes.APPROVAL:
                return '#007bfb';
            default:
                return '';
        }
    }

    const onAttachmentClick = (e, item) => {
        e.stopPropagation();
        //console.log('item', item);
        const organization_id = props.triageSelectedOrganization.organization.id;
        const path = item.approval_attachments[0]?.path;


        downloadApprovalAttachmentApi(organization_id, path)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response downloadAttachmentApi: ", res);
            const downloadableURL = res.document_signed_url;
            document.getElementById("approval_attachment_download").href = downloadableURL;
            document.getElementById("approval_attachment_download").click();
            trackGAEvent(props.selectedOrganization.organization.name, 'Approval Download Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        })
        .catch((e) => {
        consoleToLog(" downloadAttachmentApi error", e.response);
        });
    }

    const showTriageList = () => {
        return (
            <Grid item md={12} lg={12}>
                {
                    triageList && triageList.length > 0 ?
                    triageList.map((triageItem) => {
                        let triageCompaniesList = triageItem.data?.companies;
                        let triageChecklists = triageItem.data?.checklists;
                        let triageAttachments = triageItem.data?.attachments && triageItem.data?.attachments.length > 0 ? 
                        triageItem.data?.attachments.filter((attachment) => attachment.disposition !== 'inline') : [];
                        let triageMetadata = triageItem.data?.metadata;
                        let moreAttachments = triageAttachments?.slice(1);
                        let metadataObject = triageMetadata ? Object.keys(triageMetadata).length > 0 : false;
                        let triageItemEmailRead = triageItem.data?.read_users && triageItem.data?.read_users.length > 0 && triageItem.data?.read_users.includes(user);
                        let triageComments = triageItem.data?.comments;
                        let triageNotes = triageItem.data?.notes;
                        let moreNotes = triageComments?.slice(1);
                        let messageReceivedToday = calculateMessageTimeReceived(triageItem.updated_at);//moment.unix(date).isAfter(Moment().subtract(24, 'hours'));
                        let triageNotesCount = triageItem.data?.notes_count;
                        let triageItemNotesRead = triageItem.data?.checklists && triageItem.data?.checklists.length > 0 && triageItem.data?.checklists[0].note_read_users?.includes(user);
                        let statusObj = approvalStatus(triageItem.data?.approval?.approval_status);
                        const approval_obj = triageItem.data?.approval;
                        const assignerUserName = approval_obj?.assigner_user?.firstname + ' ' + approval_obj?.assigner_user?.lastname;
                        const approverUserName = approval_obj?.approver_user?.firstname + ' ' + approval_obj?.approver_user?.lastname;
                        const approverOrMember = approval_obj?.approver_user?.id === user;
                        const cancelledUser = approval_obj?.cancellor_user?.firstname + ' ' + approval_obj?.cancellor_user?.lastname;

                        return Object.keys(triageItem.data).length !== 0 &&
                        <Grid item container direction='column' key={triageItem.id}>
                            <Grid item container alignItems='center'
                                onMouseLeave={onClickAway1}
                                style={{flexWrap:'wrap', 
                                        cursor:'pointer', 
                                        borderLeft: triageItem.type === TriageTypes.EMAIL ? `4px solid ${getEmailColor(triageItem, triageItemEmailRead)}`
                                        :triageItemNotesRead && `4px solid #f3f3f3`,
                                        background: showBackGroundClasses(triageItem, triageItemEmailRead, triageItemNotesRead)}}
                                        className={classes.triageItemHover}>
                                    <Grid item md={1}
                                        className={classes.customColumn1}
                                        ref={node => (listEl = node)}>
                                            <Checkbox
                                                style={{marginTop:"-9px"}}
                                                checked= {onSelectedTriage(triageItem) || false}
                                                id={`item-${triageItem.id}`}
                                                value={triageItem.id}
                                                onChange={(e) =>  handleClick(e, triageItem)}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                    </Grid>
                                    <Grid item md={6} className={classes.customColumn2}>
                                        {triageItem.type !== TriageTypes.APPROVAL ?
                                        <Grid item md={12} style={{flexBasis:'95%', maxWidth:'95%'}}
                                            onClick={(e) => onTriageItemClick(e, triageItem)}>
                                            <Grid item container alignItems='center' spacing={1}>
                                                <Grid item>
                                                    <Tooltip title={showName(triageItem)} arrow>
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.triageFromEmailStyle}
                                                            style={{cursor:"pointer",
                                                                    fontWeight: triageItemEmailRead || triageItemNotesRead ? '400' : '600'}}>
                                                                    {showName(triageItem)}

                                                            {/* <span className={classes.countStyle}>
                                                                {triage.count}
                                                            </span> */}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body1' style={{fontWeight:500}}>
                                                            -
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title={showText(triageItem)} arrow>
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.subjectStyle}
                                                            style={{fontWeight: triageItemEmailRead || triageItemNotesRead ? '400' : '600', marginTop:'1px'}}
                                                            dangerouslySetInnerHTML={{ __html : showText(triageItem)}}
                                                            >
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>

                                                {(metadataObject && triageMetadata?.followups && triageMetadata?.followups.length > 0) &&
                                                    <Grid item>
                                                        <Typography
                                                            style={{background: '#ededed'}}
                                                            className={classes.emailFollowupTag}>
                                                            Followup
                                                        </Typography>
                                                    </Grid>
                                                }
                                            </Grid>

                                            <Grid item md={12} style={{width:'600px'}}>
                                                <Typography 
                                                    variant="body2"
                                                    dangerouslySetInnerHTML={{ __html: triageItem.data?.body_text?.replace(/<[^>]*>?/gm, '') }}
                                                    className={`${classes.emailBodyStyle} ${classes.customLineHeight}`}>
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10}>
                                                <Grid item container alignItems='center' spacing={1}>
                                                {triageAttachments && triageAttachments.length > 0 &&
                                                    <Grid item>
                                                        <Grid item container alignItems='center'>
                                                            {triageAttachments && triageAttachments.length > 0 &&
                                                                triageAttachments.slice(0, 1).map((attachment) => {
                                                                    return <Typography key={attachment.name} 
                                                                                className={classes.attachmentNoteStyle} style={{display:'flex', alignItems:'center', marginRight:'8px'}}>
                                                                                <img src={showIcons('attachment')} style={{marginRight:'6px'}} />
                                                                                <Tooltip title={attachment.name} arrow>
                                                                                    <span className='text_ellipsis'>
                                                                                        {attachment.name}
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </Typography>
                                                                })
                                                                
                                                            }
                                                            {triageAttachments && triageAttachments.length > 1 &&
                                                                <Tooltip arrow 
                                                                    title={moreAttachments.map((attachment) => attachment.name).join(', ')}>
                                                                    <span className={classes.moreAttachmentNoteStyle}> 
                                                                        {showMoreAttachments(moreAttachments)}
                                                                    </span>
                                                                </Tooltip>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {triageComments && triageComments.length > 0 &&
                                                    <Grid item>
                                                        <Grid item container alignItems='center'>
                                                            {triageComments && triageComments.length > 0 &&
                                                                triageComments.slice(0, 1).map((comment) => {
                                                                    return <Typography key={comment.id} 
                                                                                onClick={(e) => handleNotesDrawerOpen(e, triageItem)}
                                                                                className={classes.attachmentNoteStyle} style={{display:'flex', alignItems:'center', marginRight:'8px'}}>
                                                                                    <img src={showIcons('comment')} style={{marginRight:'6px'}} />
                                                                                    <Tooltip arrow 
                                                                                        title={getReadableNotesText(comment, false)}>
                                                                                    <p className='text_ellipsis' style={{margin:'0px'}}
                                                                                        dangerouslySetInnerHTML={{ __html: getReadableNotesText(comment, true) }}>
                                                                                    </p>
                                                                                    </Tooltip>
                                                                            </Typography>
                                                                })
                                                                
                                                            }
                                                            {triageComments && triageComments.length > 1 &&
                                                                    <span className={classes.moreAttachmentNoteStyle}
                                                                        onClick={(e) => handleNotesDrawerOpen(e, triageItem)}> 
                                                                        {showMoreCommentsList(triageItem.data.comment_count)}
                                                                    </span>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {triageNotes && triageNotes.length > 0 &&
                                                    <Grid item>
                                                        <Grid item container alignItems='center'>
                                                            {triageNotes.map((note) => {
                                                                    return <Typography key={note.id} 
                                                                                className={classes.triageNoteStyle} 
                                                                                style={{display:'flex', alignItems:'center', marginRight:'8px'}}
                                                                                >
                                                                                    <img src={showIcons('comment')} style={{marginRight:'6px'}} />
                                                                                    <Tooltip arrow 
                                                                                        title={getReadableNotesText(note, false)}>
                                                                                    <p className='text_ellipsis' style={{margin:'0px'}}
                                                                                        dangerouslySetInnerHTML={{ __html: getReadableNotesText(note, true) }}
                                                                                        >
                                                                                    </p>
                                                                                    </Tooltip>
                                                                            </Typography>
                                                                })
                                                                
                                                            }
                                                            {triageNotesCount > 3 &&
                                                            <span className={classes.noteCountStyle}> 
                                                                {showMoreNotesList(triageNotesCount)}
                                                            </span>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                </Grid>
                                            </Grid> 
                                        </Grid>
                                        :
                                        <Grid item md={12} style={{flexBasis:'95%', maxWidth:'95%'}}>
                                            <Grid item container direction='column'>
                                                {
                                                    triageItem.data.task_title &&
                                                    <Grid item md={12}>
                                                        <Typography className={classes.taskStyle}>
                                                        { triageItem.data?.task_title}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item md={12}>
                                                    <Grid item container alignItems='center'>
                                                        <Grid item>
                                                            <Typography className={classes.userApproverStyle}>
                                                                <span className={classes.userApproverNameStyle}>
                                                                    {assignerUserName}
                                                                </span>
                                                                <ArrowForwardIcon className={classes.forwardArrowStyle}/>
                                                                <span className={classes.userApproverNameStyle}>
                                                                    {approverUserName}
                                                                </span>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item>
                                                            <Typography 
                                                                style={{
                                                                    fontSize:'12px',
                                                                    borderRadius:'20px',
                                                                    padding:'0px 8px 1px 8px', 
                                                                    background:statusObj.color, 
                                                                    width:"fit-content",
                                                                    color:'#fff',
                                                                    marginLeft:'8px',
                                                                    }}>
                                                                {statusObj.name}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item>
                                                            
                                                        </Grid>
                                                        {
                                                            (approval_obj.approval_status === ApprovalStatus.PENDING) &&
                                                            <Grid item className={classes.customMarginLeft}>
                                                                <Typography variant='body2'>
                                                                    {`${moment.utc(approval_obj.sent_timestamp).fromNow()}`}
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            (approval_obj.approval_status === ApprovalStatus.APPROVED || approval_obj.approval_status === ApprovalStatus.REJECTED) &&
                                                            <Grid item className={classes.customMarginLeft}>
                                                                <Typography variant='body2'>
                                                                    {`on ${moment.utc(approval_obj.approve_reject_timestamp).format('DD MMM YYYY')}`}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        {approval_obj.approval_status === ApprovalStatus.CANCELLED &&
                                                        <Grid item className={classes.customMarginLeft}>
                                                            <Tooltip title={`by ${cancelledUser} on ${moment.utc(approval_obj.cancelled_timestamp).format('DD MMM YYYY')}`}
                                                                arrow>
                                                                <Typography variant='body2' className={classes.textEllipsis}>
                                                                        {`by ${cancelledUser} on ${moment.utc(approval_obj.cancelled_timestamp).format('DD MMM YYYY')}`}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                    
                                                </Grid>

                                                <Grid item md={12}>
                                                    <Grid item container alignItems='center' style={{marginTop:'8px'}}>
                                                        {approval_obj?.text &&
                                                        <Grid item>
                                                            <span style={{display:'flex', alignItems:'center'}}>
                                                                <img src='/images/chat_bubble.svg' style={{marginRight:'4px'}}/>
                                                                <Tooltip title={approval_obj?.text} arrow>
                                                                    <Typography variant='body1' className={classes.textEllipsis}>
                                                                        {approval_obj?.text}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </span>
                                                        </Grid>}
                                                        {approval_obj?.approval_attachments?.length > 0 && 
                                                        <Grid item>
                                                            <Typography
                                                                onClick={(e) => onAttachmentClick(e, approval_obj)}
                                                                className={classes.attachmentNoteStyle} style={{display:'flex', alignItems:'center', marginLeft: approval_obj?.text && '8px'}}>
                                                                <img src={showIcons('attachment')} style={{marginRight:'6px'}} />
                                                                <Tooltip title={approval_obj?.approval_attachments[0].path.replace(/^.*[\\\/]/, "")} arrow>
                                                                    <span className='text_ellipsis'>
                                                                    {approval_obj?.approval_attachments[0].path.replace(/^.*[\\\/]/, "")}
                                                                    </span>
                                                                </Tooltip>
                                                            </Typography>
                                                            <Link
                                                                style={{ display: "none" }}
                                                                id='approval_attachment_download'
                                                                target="_blank"
                                                                onClick={(e) => e.stopPropagation()}
                                                                download
                                                                ></Link>
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        }
                                    </Grid>

                                    <Grid item md={2} className={classes.customColumn3}>
                                        <Typography  
                                            style={{maxWidth:'90%', position:'relative', top:'3px'}}
                                            className={classes.triageItemTextStyles}>
                                            {triageCompaniesList && triageCompaniesList.length > 0 ? 
                                            triageCompaniesList.slice(0,2).map((company) => company?.name).join(', ') : ''}
                                            {triageCompaniesList && triageCompaniesList.length > 2 &&
                                            <span className={classes.moreItemStyle}> 
                                                {showMoreCompaniesList(triageItem.data.companies)}
                                            </span>}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} className={classes.customColumn3}>
                                        <Typography  
                                            style={{maxWidth:'90%', position:'relative', top:'3px'}}
                                            className={classes.triageItemTextStyles}>
                                            {triageChecklists && triageChecklists.length > 0 ? 
                                            triageChecklists.slice(0,2).map((checklistItem) => `${checklistItem?.template?.name} (${checklistItem?.subtitle})`).join(', ') : ''}
                                            {triageChecklists && triageChecklists.length > 2 &&
                                            <span className={classes.moreItemStyle}> 
                                                {showMoreCheckList(triageItem.data.checklists)}
                                            </span>}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={1} lg={1} className={classes.customColumn5} style={{position:'relative', alignSelf:'center'}}>
                                        <Grid item className='emailTypeStyle'>
                                            <div>
                                                <Typography variant='subtitle1'>
                                                    {messageReceivedToday ? moment(triageItem.updated_at).format('MMM D') : moment(triageItem.updated_at).format('h:mm a')}
                                                </Typography>
                                                <Typography
                                                    style={{background: triageTypeColor(triageItem.type)}}
                                                    className={classes.triageTypeStyle}>
                                                    {showTriageType(triageItem.type)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item className={showClearTriage && triageItem.type === TriageTypes.APPROVAL ? 'moreActionStyle3'  : showButtonContainerClasses(triageItem, approval_obj, approverOrMember)}>
                                            {   
                                                triageItem.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.APPROVED && approverOrMember &&
                                                <Button item className={showClearTriage ? "approve_item1" : "approve_item"}
                                                    onClick={(e) => onUndoApproveRejectClick(e,  approval_obj, 'approve', triageItem.id)}
                                                > 
                                                        <img src={showIcons('approve')} className={classes.actionIcons1} />   
                                                    <span> Undo Approve </span>
                                                    
                                                </Button>
                                            }

                                            {   
                                                triageItem.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.REJECTED && approverOrMember &&
                                                <Button item className={showClearTriage ? "approve_item1" : "approve_item"}
                                                    onClick={(e) => onUndoApproveRejectClick(e,  approval_obj, 'reject', triageItem.id)}
                                                > 
                                                        <img src={showIcons('reject')} className={classes.actionIcons1} />   
                                                    <span> Undo Reject </span>
                                                    
                                                </Button>
                                            }
                                            
                                            
                                            {   
                                                triageItem.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.PENDING && !approverOrMember &&
                                                <Button item className={showClearTriage ? "approve_item1" : "approve_item"}
                                                    onClick={(e) => callApproveRejectCancelApi(e,  approval_obj, 'cancelled', triageItem.id)}
                                                > 
                                                        <img src={showIcons('assign-checklist')} className={classes.actionIcons1} />   
                                                    <span> Cancel </span>
                                                    
                                                </Button>
                                            }

                                            {   
                                                triageItem.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.PENDING && approverOrMember &&
                                                <Button item className={showClearTriage ? "approve_item1" : "approve_item"}
                                                    onClick={(e) => callApproveRejectCancelApi(e,  approval_obj, 'rejected', triageItem.id)}
                                                > 
                                                        <img src={showIcons('reject')} className={classes.actionIcons1} />   
                                                    <span> Reject </span>
                                                    
                                                </Button>
                                            }

                                            {
                                                triageItem.type === TriageTypes.APPROVAL && approval_obj.approval_status === ApprovalStatus.PENDING && approverOrMember &&
                                                <Button item className={showClearTriage ? "approve_item1" : "approve_item"}
                                                    onClick={(e) => callApproveRejectCancelApi(e,  approval_obj, 'approved', triageItem.id)}
                                                > 
                                                        <img src={showIcons('approve')} className={classes.actionIcons1} />   
                                                    <span> Approve </span>
                                                    
                                                </Button>
                                            }
                                            
                                            
                                            
                                            {triageItem.type === TriageTypes.EMAIL && triageMetadata?.followups && triageMetadata?.followups.length > 0 &&
                                                <Button item className="stop_followup"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onStopEmailFollowupClick(triageItem);
                                                    }}
                                                > 
                                                        <HighlightOffOutlinedIcon 
                                                        className={classes.moreActionsIcons}
                                                        style={{fontSize:'15px', verticalAlign:'baseline', color:'#dc4f42'}}   
                                                        />
                                                    <span> Stop Followup </span>
                                                    {showEmailFollowupsPopover(triageItem, triageMetadata?.followups)}
                                                </Button>
                                            }
                                            {triageItem.type === TriageTypes.EMAIL &&
                                            <Button item className="assign_checklist"
                                                onClick={(e) => handleDrawerOpen(e,  triageItem)}
                                            > 
                                                    <img src={showIcons('assign-checklist')} className={classes.actionIcons} />   
                                                <span> Assign Checklist </span>
                                                
                                            </Button>}
                                            {
                                                (!showTriage || !metadataObject || metadataObject && !triageMetadata.followups || triageMetadata?.followups && triageMetadata?.followups.length === 0) && triageItem.type === TriageTypes.EMAIL &&
                                                <Button item className="assign_client"
                                                    onClick={(e) => handleDrawerOpen(e,  triageItem)}
                                                > 
                                                        <img src={showIcons('assign-client')} className={classes.actionIcons} />
                                                    <span> Assign Client </span>
                                                    
                                                </Button>
                                            }

                                            {showTriage ?
                                            <Button item className="clear_triage"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    clearTriage(triageItem);
                                                }}
                                            > 
                                                    <img src={showIcons('triage')} 
                                                        className={`${classes.actionIcons} clear_triage_stop_followup`} />
                                                <span> Clear Item </span>
                                                
                                            </Button>
                                            :
                                            (!showTriage && !metadataObject || metadataObject && !triageMetadata.followups || triageMetadata?.followups && triageMetadata?.followups.length === 0) && triageItem.type === TriageTypes.EMAIL &&
                                            <Button item className="assign_client"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleChecklistDrawerOpen(e)
                                                }}
                                            > 
                                                    <img src={showIcons('create-checklist')} className={`${classes.actionIcons}`}/>
                                                    <span> Create Checklist </span>
                                                
                                            </Button>
                                            }
                                            
                                            
                                            { (showTriage || metadataObject && triageMetadata?.followups && triageMetadata?.followups.length > 0) && triageItem.type === TriageTypes.EMAIL &&
                                            <Button item className="more_actions"
                                            //onClick={() => getEmailFollowups(checklistItem)}
                                            >
                                                <Tooltip title="More Actions" arrow >
                                                        <i className="flaticon-more action__icon" 
                                                            onClick={(e) => onMoreActionIconClick(e, triageItem)}
                                                            style={{position:"relative", top:'1.5px', left:'1px'}}>
                                                        </i>
                                                </Tooltip>
                                            </Button>}
                                            {(triageId === triageItem.id) && isActive &&
                                                <ClickAwayListener onClickAway={() => {
                                                    setIsActive(false);
                                                    setTriageId(undefined);
                                                }}>
                                                    <Grid item 
                                                        style={{
                                                            position: 'absolute',
                                                            top: triageAttachments && triageAttachments.length > 0 || triageComments && triageComments.length > 0 ? '60px' : '40px'}}
                                                        className={`menu ${isActive ? "active" : "inactive"}`}>
                                                        <ul>
                                                            <li className={classes.list} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleChecklistDrawerOpen(e)
                                                                
                                                                }}>
                                                                <a>
                                                                    <img src={showIcons('create-checklist')}
                                                                        style={{position:'relative', top:'2px'}}
                                                                        className={classes.actionIcons}/>
                                                                    Create Checklist
                                                                </a>
                                                            </li>

                                                            {(metadataObject && triageMetadata?.followups && triageMetadata?.followups.length > 0) &&
                                                            <li className={classes.list} onClick={(e) => handleDrawerOpen(e, triageItem, true, false)}>
                                                                <a>
                                                                    <img src={showIcons('assign-client')} className={classes.actionIcons} />
                                                                    Assign Client
                                                                </a>
                                                            </li>}
                                                        </ul>
                                                    </Grid>
                                                </ClickAwayListener>
                                            }
                                        </Grid>
                                    </Grid>

                            </Grid>
                            
                            <Divider light style={{borderBottom: '1px solid rgba(0, 0, 0, 0.09)'}}/>
                        </Grid>
                    }) 
                    :
                    !loading &&
                    <NoTriageList  src="/images/no_triage_item.svg" 
                        message='No Triage Found'/>
                }
            </Grid>
        )
    }


    const showSentEmailList = () => {
        return (
            <Grid item md={12} lg={12}>
                {
                    sentEmailList && sentEmailList.length > 0 ?
                    sentEmailList.map((emailItem) => {
                        let emailCCs = emailItem.cc ? emailItem.cc : [];
                        let toEmails = emailItem.to_email ? emailItem.to_email : [];
                        let emailArr = [...toEmails, ...emailCCs];
                        let emailCompaniesList = emailItem.companies;
                        let emailChecklists = emailItem.checklists;
                        let emailAttachments = emailItem.attachments;
                        let moreAttachments = emailItem.attachments && emailItem.attachments.length ? emailItem.attachments?.slice(1) : [];
                        let emailMetadata = emailItem.metadata;
                        let metadataObject = emailItem ? Object.keys(emailItem.metadata ? emailItem.metadata : {}).length > 0 : false;
                        let emailItemRead = emailItem.read_users && emailItem.read_users.length > 0 && emailItem.read_users.includes(user);
                        let emailComments = emailItem.comments;
                        let moreNotes = emailComments?.slice(1);
                        let sentUserName = emailItem.user?.firstname + ' ' + emailItem.user?.lastname;
                        let messageReceivedToday = calculateMessageTimeReceived(emailItem.updated_at);
                        return  <Grid item container direction='column' key={emailItem.email_uuid}>
                                    <Grid item container alignItems='center'
                                        onMouseLeave={onClickAway1}
                                        style={{flexWrap:'wrap', 
                                                cursor:'pointer', 
                                                borderLeft: `4px solid ${getEmailColor1(emailItem)}`,
                                                background: '#fff'}}
                                                className={classes.triageItemHover}>
                                            <Grid item md={6} className={classes.emailCustomColumn1}>
                                                <Grid item md={12} style={{flexBasis:'95%', maxWidth:'95%'}}
                                                    onClick={(e) => onSentEmailItemClick(e, true, emailItem)}>
                                                    <Grid item container alignItems='center' spacing={1}>
                                                        <Grid item>
                                                            <Tooltip title={emailArr && emailArr.length > 0 && emailArr.map((email) => email).join(', ')} arrow>
                                                                <Typography variant='body2'
                                                                    className={`${classes.emailsArrStyle} ${classes.emailpaddingLeft}`}
                                                                    style={{cursor:"pointer"}}>
                                                                            To: {emailArr && emailArr.length > 0 && emailArr.map((email) => email).join(', ')}

                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='body1' style={{fontWeight:400}}>
                                                                    -
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title={emailItem.subject} arrow>
                                                                <Typography
                                                                    variant="body2"
                                                                    className={classes.subjectStyle}
                                                                    style={{marginTop:'1px', fontWeight:500}}
                                                                    >
                                                                        {/* {displayEmailSubject(emailItem.subject)} */}
                                                                        {emailItem.subject}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>

                                                        {/* <Grid item>
                                                            <Typography
                                                                style={{background: '#f1c31c'}}
                                                                className={classes.triageTypeStyle}>
                                                                Email
                                                            </Typography>
                                                        </Grid> */}

                                                        {(metadataObject && emailMetadata?.followups && emailMetadata?.followups.length > 0) &&
                                                            <Grid item>
                                                                <Typography
                                                                    style={{background: '#ededed'}}
                                                                    className={classes.emailFollowupTag}>
                                                                    Followup
                                                                </Typography>
                                                            </Grid>
                                                        } 

                                                        {emailItem.automated &&
                                                            <Grid item>
                                                                <Typography
                                                                    style={{background: '#1E90FF'}}
                                                                    className={classes.emailAutomatedTag}>
                                                                    Automated
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {emailItem.automated && emailItem.open_count && emailItem.open_count > 0 &&
                                                            <Grid item 
                                                                onMouseEnter={handlePopoverOpen}
                                                                onMouseLeave={handlePopoverClose}> 
                                                                <DoneAllIcon style={{marginLeft:'4px', color:'MediumSeaGreen'}}/>

                                                                {emailItem.automated && emailItem.open_count && emailItem.open_count > 0 && showEmailOpenedPopover(emailItem)}
                                                            </Grid>
                                                        } 
                                                    </Grid>

                                                    <Grid item md={12} style={{width:'600px'}}>
                                                        <Typography 
                                                            variant="body2"
                                                            dangerouslySetInnerHTML={{ __html: emailItem.body_text?.replace(/<[^>]*>?/gm, '') }}
                                                            className={`${classes.emailBodyStyle} ${classes.customLineHeight} ${classes.emailpaddingLeft}`}>
                                                        </Typography>
                                                    </Grid>

                                                    {
                                                        emailItem.user &&
                                                        <Grid item md={12}>
                                                            <Typography variant="body2"
                                                                className={`${classes.emailBodyStyle} ${classes.customLineHeight} ${classes.emailpaddingLeft}`}>
                                                                <span style={{color:'#202020'}}>Sent By:</span> {sentUserName}
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    <Grid item md={10}>
                                                        <Grid item container alignItems='center' spacing={1}>
                                                        {emailAttachments && emailAttachments.length > 0 &&
                                                            <Grid item>
                                                                <Grid item container alignItems='center' className={classes.emailpaddingLeft}>
                                                                    {emailAttachments && emailAttachments.length > 0 &&
                                                                        emailAttachments.slice(0, 1).map((attachment) => {
                                                                            return <Typography key={attachment.name} 
                                                                                        className={classes.attachmentNoteStyle} style={{display:'flex', alignItems:'center'}}>
                                                                                        <img src={showIcons('attachment')} style={{marginRight:'6px'}} />
                                                                                        <Tooltip title={attachment.name} arrow>
                                                                                            <span className='text_ellipsis'>
                                                                                                {attachment.name}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    </Typography>
                                                                        })
                                                                        
                                                                    }
                                                                    {emailAttachments && emailAttachments.length > 1 &&
                                                                        <Tooltip arrow 
                                                                            title={moreAttachments.map((attachment) => attachment.name).join(', ')}>
                                                                            <span className={classes.moreAttachmentNoteStyle} style={{marginLeft:'8px'}}> 
                                                                                {showMoreAttachments(moreAttachments)}
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        {emailComments && emailComments.length > 0 &&
                                                            <Grid item>
                                                                <Grid item container alignItems='center'>
                                                                    {emailComments && emailComments.length > 0 &&
                                                                        emailComments.slice(0, 1).map((comment) => {
                                                                            return <Typography key={comment.id} 
                                                                                        className={classes.attachmentNoteStyle} style={{marginRight:'8px'}}>
                                                                                            <Tooltip arrow 
                                                                                                title={getReadableNotesText(comment, false)}>
                                                                                            <p className='text_ellipsis' style={{margin:'0px'}}
                                                                                                dangerouslySetInnerHTML={{ __html: getReadableNotesText(comment, true) }}>
                                                                                            </p>
                                                                                            </Tooltip>
                                                                                    </Typography>
                                                                        })
                                                                        
                                                                    }
                                                                    {emailComments && emailComments.length > 1 &&
                                                                            <span className={classes.moreAttachmentNoteStyle}> 
                                                                                {showMoreNotesList(emailItem.comment_count)}
                                                                            </span>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        </Grid>
                                                    </Grid> 
                                                </Grid>
                                            </Grid>

                                            <Grid item md={2} className={classes.emailCustomColumn2}>
                                                <Typography  
                                                    style={{maxWidth:'90%', position:'relative', top:'3px'}}
                                                    className={classes.triageItemTextStyles}>
                                                    {emailCompaniesList && emailCompaniesList.length > 0 ? 
                                                    emailCompaniesList.slice(0,2).map((company) => company?.name).join(', ') : ''}
                                                    {emailCompaniesList && emailCompaniesList.length > 2 &&
                                                    <span className={classes.moreItemStyle}> 
                                                        {showMoreCompaniesList(emailItem.companies)}
                                                    </span>}
                                                </Typography>
                                            </Grid>

                                            <Grid item md={2} className={classes.emailCustomColumn2}>
                                                <Typography  
                                                    style={{maxWidth:'90%', position:'relative', top:'3px'}}
                                                    className={classes.triageItemTextStyles}>
                                                    {emailChecklists && emailChecklists.length > 0 ? 
                                                    emailChecklists.slice(0,2).map((checklistItem) => `${checklistItem?.template?.name} (${checklistItem?.subtitle})`).join(', ') : ''}
                                                    {emailChecklists && emailChecklists.length > 2 &&
                                                    <span className={classes.moreItemStyle}> 
                                                        {showMoreCheckList(emailItem.checklists)}
                                                    </span>}
                                                </Typography>
                                            </Grid>

                                            <Grid item md={1} lg={1} className={classes.emailCustomColumn3}
                                                style={{position:'relative'}}>
                                                <Grid item className={'emailTypeStyle1'}>
                                                    <Typography variant='subtitle1' 
                                                        style={{position:'relative', top:'3px'}}
                                                        className={classes.triageTimeStyle}>
                                                        {messageReceivedToday ? moment(emailItem.created_at).format('MMM D') : moment(emailItem.created_at).format('h:mm a')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item className='moreActionStyle' 
                                                    style={{
                                                        marginLeft:"auto",
                                                        justifyContent:"right",
                                                        position:'relative',
                                                        top:'4px'
                                                        }}
                                                    >
                                                    {emailMetadata?.followups && emailMetadata?.followups.length > 0 &&
                                                        <Button item className="stop_followup"
                                                            style={{width:'160px'}}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onStopEmailFollowupClick(emailItem);
                                                            }}
                                                        > 
                                                                <HighlightOffOutlinedIcon 
                                                                className={classes.moreActionsIcons}
                                                                style={{fontSize:'15px', verticalAlign:'baseline', color:'#dc4f42'}}   
                                                                />
                                                            <span> Stop Followup </span>
                                                            {showEmailFollowupsPopover(emailItem, emailMetadata?.followups)}
                                                        </Button>
                                                    }
                                                    <Button item className="assign_checklist"
                                                        style={{width:'160px'}}
                                                        onClick={(e) => onSentEmailItemClick(e, false, emailItem)}
                                                    > 
                                                            <img src={showIcons('assign-checklist')} className={classes.actionIcons} />   
                                                        <span> Assign Checklist </span>
                                                        
                                                    </Button>
                                                    <Button item className="assign_client"
                                                        style={{width:'160px'}}
                                                        onClick={(e) => onSentEmailItemClick(e, false, emailItem)}
                                                    > 
                                                            <img src={showIcons('assign-client')} className={classes.actionIcons} />
                                                        <span> Assign Client </span>
                                                        
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    
                            <Divider light style={{borderBottom: '1px solid rgba(0, 0, 0, 0.09)'}}/>
                        </Grid>
                    }) 
                    :
                    !loading &&
                    <NoTriageList  src="/images/no_triage_item.svg" 
                        message='No Emails Found'/>
                }
            </Grid>
        )
    }

    const NoTriageList = (props) => {
        const classes = useStyles();
        return (
            <div className={classes.noListContainer}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item sm={6}>
                            <img alt="No Triage Found" src={props.src}
                                className={classes.noTriageImage}/>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.noTriageText}>
                            {props.message}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }

    
    const loadMoreLogic = () => {
        return (
            <Grid item container justifyContent='center'>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            {(triageList && triageList.length > 0 || sentEmailList && sentEmailList.length > 0) && showTriageTile()}
            <div className={classes.mainbg}>
                { loading ?
                    <CircularProgress size={25} className={classes.loading} />
                    :
                        <Grid item md={12}>
                            {showSentEmails ?
                            showSentEmailList()
                            :
                            showTriageList()}
                            {(triageList && triageList.length > 0 || sentEmailList && sentEmailList.length > 0)  && loadMoreLogic()}
                        </Grid>
                }
            </div>

            {openDialog &&
            <TriageItemDetailsModal openDialog = {openDialog}
                handleDetailsDialogClose = {handleDetailsDialogClose}
                handleDetailsDialogOpen = {handleDetailsDialogOpen}
                selectedTriageItem={selectedTriageItem}
                handleDrawerOpen={handleDrawerOpen}
                setFromDetailsOpen={setFromDetailsOpen}
                updateTriageArrayList={updateTriageArrayList}
                handleChecklistDrawerOpen={handleChecklistDrawerOpen}
                addClientToTriageItem={addClientToTriageItem}
                addChecklistToTriageItem={addChecklistToTriageItem}
                onEmailFollowupClick={onEmailFollowupClick}
                callEmailReadTriageItemsApi={callEmailReadTriageItemsApi}
                callEmailUnReadTriageItemsApi={callEmailUnReadTriageItemsApi}
                updateTriageArr={updateTriageArr}
                clearTriage={clearTriage}
                showSentEmails={showSentEmails}
                />}

            <AssignCompanyChecklistDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                openClientTab={openClientTab}
                openChecklistTab={openChecklistTab}
                addClientToTriageItem={addClientToTriageItem}
                selectedTriageObj={selectedTriageObj}
                addChecklistToTriageItem={addChecklistToTriageItem}
                fromDetailsOpen={fromDetailsOpen}
            />

            {<NotesDrawer openDrawer={openNotesDrawer}
                handleDrawerClose={handleNoteDrawerClose}
                selectedTriageItemObj={selectedTriageItemObj}
                triageListFromParent={props.triageListFromParent}
                calculateMessageTimeReceived={calculateMessageTimeReceived}
                updateTriageArr={updateTriageArr}
                setSelectedTriageItemObj={setSelectedTriageItemObj}
                triageList={triageList}
                triageListNoteRead={triageListNoteRead}
            />}

            <CreateChecklistDrawer 
                openChecklistDrawer={openChecklistDrawer}
                handleChecklistDrawerClose={handleChecklistDrawerClose}
                fromDetailsOpen={fromDetailsOpen}
                triageObj={selectedTriageObj ? selectedTriageObj : selectedTriageItem}
                triageList={triageList}
                setTriageList={setTriageList}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    isEmailSendError: state.triageSelectedOrganizationInfo.isEmailSendError,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
    members: state.triageSelectedOrganizationInfo.members,
    drawerState: state.drawerState.setDrawerState,
    triageOrgEmailList: state.triageSelectedOrganizationInfo.orgEmailList,
    rawS3EmailPath: state.triageSelectedOrganizationInfo.rawS3EmailPath,
    user: state.triageSelectedOrganizationInfo.user
})

export default connect(mapStateToProps)(TriageList);