import axios from 'axios';
import { consoleToLog } from '../util/AppUtil';
import { Navigate } from 'react-router-dom';

const UNAUTHORIZED = 401;

export const getAxios = () => {
    //consoleToLog("interceptors: ", axios.interceptors);

    axios.interceptors.request.use(
        (config) => {
            config.withCredentials = true;
            return config;
        },
        (error) => {
            return Promise.reject(error)
        }
    )
    
    if (axios.interceptors.response.handlers.length === 0) {
        axios.interceptors.response.use(
            response => response,
            error => {
                if (!error.response || !error.response.status) {
                    //consoleToLog("interceptors: ", error);
                    if (!isConnectionExists()) {
                        alert('Something went wrong!, Please try again.');
                        return Promise.reject(error);
                    } else {
                        alert('Something went wrong!, Please try again.');
                        return Promise.reject(error);
                    }
                }

                const { status } = error.response;
                if (status === UNAUTHORIZED) {
                    error.response.data.message = "Session Expired, please login again!"
                    localStorage.clear();
                    window.location.reload();
                    return Promise.reject(error);
                }
                return Promise.reject(error);
            }
        );
    }
    return axios;
};


function isConnectionExists() {
    var xhr = new XMLHttpRequest();
    var file = "https://www.kirupa.com/blank.png";
    var randomNum = Math.round(Math.random() * 10000);

    xhr.open('HEAD', file + "?rand=" + randomNum, true);
    xhr.send();

    xhr.addEventListener("readystatechange", processRequest, false);

    function processRequest(e) {
        if (xhr.readyState == 4) {
            if (xhr.status >= 200 && xhr.status < 304) {
                return true;
            } else {
                return false;
            }
        }
    }
}



/* export const getAxios = () => {
        if(axios.interceptors.response.handlers.length === 0){
        const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            const {status} = error.response;
            consoleToLog("***status: ", error.response);

            // Reject promise if usual error
            if (status !== UNAUTHORIZED) {
                return Promise.reject(error);
            }

            const persistedState = loadState();
            const store = configureStore(persistedState);
            consoleToLog("****old store: ", store.getState().auth);

            axios.interceptors.response.eject(interceptor);
            history.push('/login');

            store.dispatch(logout());
            store.dispatch(logoutFilters());
            store.dispatch(logoutOrgs());
            store.dispatch(logoutSelectedOrg());
            localStorage.clear();
            consoleToLog("****new store: ", store.getState());
            return Promise.reject(error);
        }
        );
    }

    return axios;
}; */