import React, {useEffect, useState, createRef, Fragment, useRef, useMemo} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, Grid, Button, Typography, InputBase, MenuItem, Tooltip, Select,
    InputAdornment, Avatar, Chip, Paper, CircularProgress, Checkbox, FormControlLabel} from '@material-ui/core';
import {connect} from 'react-redux';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import { consoleToLog, sendEmailString, displayEmailSubject, OverlayMessage, sendApiPath, trackGAEvent, capitalizeFirstLetterOfEachWord, EditorConfig } from '../../util/AppUtil';
import { Autocomplete } from '@material-ui/lab';
import { useIsMount } from '../useIsMount';
import { emailValidator } from '../../util/validator';
import { ClickAwayListener } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import { Editor } from "@tinymce/tinymce-react";
import ConnectEmailModal from '../modal/ConnectEmailModal';
import { setIsEmailSendError, setCurrentEmailState, setRawEmailS3Path } from '../../actions/triageSelectedOrganization';
import { replyForwardEmailApi } from '../../services/genieService';
import { getClientEmailListApi } from '../../services/mainAppService';
import { useSnackbar } from 'notistack';
import {uploadAttachmentApi} from '../../services/uploadService';
import {setOverlayView, setOvelayMessage, setEmailItemId, setIsEmailSend, OverlayView} from '../../actions/triageOverlay';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {createEmailFollowupApi} from '../../services/mainAppService';
import {fullTextSearchApi} from '../../services/clientService';
import {inputNumberValidate} from '../../util/AppUtil';
import EmailAttachmentErrorModal from '../EmailAttachmentErrorModal';
import ReactDOMServer from "react-dom/server";
import JoditEditor from 'jodit-react';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius:'4px !important',
        borderRadius:'4px !important'
    },
    paper: {
        width:'900px',
        maxHeight:'600px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding:'0px 0px 16px 0px',
        borderRadius:'4px !important',
        overflowY:'auto',
        overflowX:'hidden'
    },
    appBar: {
        position: 'relative',
        background:'#f2f6fc'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    customColumn: {
        flexBasis:'5.5%',
        maxWidth:'5.5%'
    },
    attachLink : {
        color:'#444',
        fontWeight:'600',
        cursor:'pointer',
        textDecoration:"none !important",
        fontSize:'15px',
        display:'flex',
        alignItems:'flex-start',
        minWidth:'25px',
        padding:'4px 8px',
        marginLeft:'12px',
        marginRight:'12px',
    },
    attachmentListStyle: {
        display:'flex',
        alignItems:'center',
        padding:'8px',
        backgroundColor:'#f9f9f9',
        border:'1px solid #ececec',
        borderRadius:'5px',
        marginRight:'12px',
        marginBottom:'12px'
    },
    attachmentFilename: {
        marginTop:'-2px',
        marginRight:'6px',
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderBottom:'1px solid #ccc',
        borderRadius:'0px'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none !important',
    
    },
    selectIcon: {
        left: 80,
        color:'#555555'
    },
    selectIcon1: {
        position: 'relative',
        color:'#555555'
    },
    textStyles: {
        color: '#747775',
        fontSize: '14px'
    },
    selectIcon2: {
        right: 0,
    },
    selectIcon3: {
        position: 'relative',
    },
    MuiTextFieldroot : {
        '& .MuiInput-underline' : {
            border:'0 !important'
        }
    },
    customBorder: {
        borderBottom:'1px solid rgba(0,0,0,0.1)'
    },
    wrapperClass : { 
        display: 'flex', 
        flexDirection: 'column' 
    },
    toolbarClass: { 
        order: 2,
        border:'0',
        paddingLeft:'0px',
        paddingLeft: '0px',
        boxShadow: '0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 20%)'
    },
    sendButtonStyle: {
        background:'#0b57d0',
        borderRadius:'50px',
        fontSize:'14px',
        fontWeight:500,
        color:'#fff !important',
        width:'135px',
        '&:hover' : {
            color:'#fff !important',
            backgroundColor: '#1b61d1 !important'
        }
    },
    avatar : {
        width: '23px !important',
        height: '23px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '16px !important',
        backgroundColor: '#fff !important',
        border: '1px solid #ccc !important',
        color:'#5f6368',
        '&:hover' : {
            backgroundColor: '#f7f7f7 !important',
            fontWeight:500
        }
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    tooltipText: {
        color: '#5f6368',
        fontSize:'14px'
    },
    tooltipText1: {
        color: 'grey',
        fontSize:'13px',
        marginTop:'-2px',
        textAlign:'left'
    },
    avatar1: {
        width: '35px !important',
        height: '35px !important',
        fontSize: '16px !important',
        background: '#f7f7f7 !important',
        color: '#5f6368 !important',
        border: '1px solid #5f6368 !important'
    },
    popover: {
        pointerEvents: 'none',
        marginTop:'8px',
        marginLeft:'100px',
    },
    lineText: {
        color: '#747775',
        fontSize: '14px',
        '&:hover' : {
            textDecoration:'underline'
        }
    },
    subtitleStyle: {
        marginLeft:'8px', 
        color:'grey', 
        fontSize:'13px'
    },
    templateName: {
        color:'#0071FF'
    },
    errorTextStyles: {
        fontSize: '17px',
        fontWeight: '600',
        textAlign: 'center'
    },
    emailTextStyle: {
        fontSize:'16px',
        fontWeight:600,
        display:'flex',
        alignItems:'center'
    },
    emailListContainer: {
        padding:'0px 24px',
        '& .more_actionButton' : {
            visibility:'visible',
            width:'120px',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            background:'white',
            border:'1px solid #ccc',
        },

    },
    connectIcons: {
        width:'22px',
        marginRight: '8px'
    },
    checkIcon: {
        color:"green",
        fontSize:'20px',
        marginLeft:'8px'
    },
    customPadding:{
        margin:'0px 16px'
    },
    root: {
        width: 200,
        "& .MuiInputLabel-root": {
            color: "#041E49"
        },
    },
    connectLinkStyle: {
        fontSize:'13px',
        cursor:'pointer',
        marginLeft:'8px',
        color:'#0b57d0',
        '&:hover': {
            textDecoration:'underline'
        }
    },
    daysLabel : {
        background:'#C4C4C4', 
        padding:'14px', 
        borderRadius:'4px',
    },
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    topBarStyle: {
        marginBottom:'6px', 
        padding:'4px 18px 4px 16px', 
        background:'#f2f6fc', 
        borderTopLeftRadius:'4px', 
        borderTopRightRadius:'4px',
    },
    headingStyle: {
        color:'#041E49', 
        fontWeight:'500', 
    },
    emailCloseButton: {
        minWidth:'25px',
        padding:'6px 8px',
    },
    clientListStyle: {
        fontSize:'13px',
        maxWidth:'450px',
        whiteSpace:"nowrap",
        overflow: 'hidden',
        textOverflow:'ellipsis',
        marginLeft:'6px'
    }
}))

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[4],
        fontSize: 11,
        marginLeft:'100px !important',
        padding: theme.spacing(2),
        width: 'max-content', 
        maxWidth: '550px'
    },
    }))(Tooltip);

const ReplyForwardEmailModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const editorRef = useRef(null);

    const [selectedEmailId, setSelectedEmailId] = useState(props.triageOrgEmailList && props.triageOrgEmailList.length === 1 ? props.triageOrgEmailList[0] : '-1');
    const [emailSubject, setEmailSubject] = useState(displayEmailSubject(props.emailObj?.subject));
    const [attachment, setAttachment] = useState([]);
    const [emails, setEmails] = useState([]);
    const [emailCcs, setEmailCcs] = useState([]);
    const [emailBccs, setEmailBccs] = useState([]);
    const [textFieldClicked, setTextFieldClicked] = useState(true);
    const [emailCcClicked, setEmailCcClicked] = useState(false);
    const [emailBccClicked, setEmailBccClicked] = useState(false);
    const [clientEmailList, setClientEmailList] = useState([]);
    const [inputText, setInputText] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openDropdown1, setOpenDropdown1] = useState(false);
    const [openDropdown2, setOpenDropdown2] = useState(false);
    const [allEmailsArr, setAllEmailsArr] = useState([]);
    const [selectedEmailString, setSelectedEmailString] = useState(sendEmailString.TO);
    const [emailError, setEmailError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorAttachments, setErrorAttachments] = useState([]);
    const [selectedEmailDraft, setSelectedEmailDraft] = useState(props.currentEmailState?.selectedEmailDraft ? props.currentEmailState?.selectedEmailDraft : '-1');
    
    
    const [openModal, setOpenModal] = useState(false);
    const [interval, setInterval] = useState(false);
    const [intervalDays, setIntervalDays] = useState(2);
    const [maxCount, setMaxCount] = useState(3);

    const isMount = useIsMount();

	const config = useMemo(
		() => ({
            ...EditorConfig,
            toolbarAdaptive: false,
        }),
        [],
    );

    const displayEditorBodyData = () => {

        return `<br/><br/>
                <div>On ${moment(props.stringDate).format('ddd, DD MMM YYYY')} at ${moment(props.stringDate).format('HH:mm')}, ${props.emailObj?.from.name} &lt;${props.emailObj?.from.email}&gt; wrote: </div>
                <blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
                    ${props.initialEmailObj?.html}
                </blockquote>`
    }


    const displayForwardMessage = () => {

        return `<br/><br/>
                <span style="font-size:12px">---------------------- Forwarded message -----------------------<br/>
                From: ${props.emailObj?.from.name} &lt;${props.emailObj?.from.email}&gt<br/>
                Date: ${moment(props.stringDate).format('ddd, DD MMM YYYY')} at ${moment(props.stringDate).format('HH:mm')}<br/>
                Subject: ${props.emailObj?.subject}<br/>
                
                ${props.ccEmails && props.ccEmails.length > 0 ? 
                    `Cc: ${props.ccEmails.map((email) => `&lt;${email.email}`).join(', ')}&gt;</span>` : ``}
                <br/><br/>
                    ${props.initialEmailObj?.html}`
    }
    const [editorValue, setEditorValue] = useState(props.forwardClick ? displayForwardMessage() : displayEditorBodyData());


    useEffect(() => {
        setEditorValue(props.forwardClick ? displayForwardMessage() : displayEditorBodyData());         
    }, [])

    const storeAttachmentArray = () => {
        let attachmnentArr = [];
        for(let i = 0; i < attachment.length; i++) {
            let obj = {
                lastModified: attachment[i].lastModified,
                lastModifiedDate: attachment[i].lastModifiedDate,
                name: attachment[i].name,
                size: attachment[i].size,
                type: attachment[i].type,
                webkitRelativePath: attachment[i].webkitRelativePath
            }
            attachmnentArr.push(obj);
        }
        return attachmnentArr
    }
    
    let data = { selectedEmailId, 
                emails, 
                emailCcs, 
                emailBccs, 
                emailSubject, 
                body_text: editorValue, 
                selectedEmailString, 
                emailCcClicked, 
                emailBccClicked, 
                attachment: storeAttachmentArray(),
                interval,
                intervalDays,
                maxCount,
                replyClick: props.replyClick,
                replyAllClick: props.replyAllClick,
                forwardClick: props.forwardClick,
                selectedEmailDraft
    }


    const handleChange = (event) => {
        setSelectedEmailId(event.target.value);
        const obj = event.target.value;
        if(!obj?.connected) {
            onConnectClick(obj);
        }
    };

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if(props.isEmailSendError) {
            props.setIsEmailSendError(false);
        }
    }, [])

    useEffect(() => {
        if(props.currentEmailState) {
            setSelectedEmailId(props.currentEmailState?.selectedEmailId ? props.currentEmailState?.selectedEmailId : '-1');
            setEmails(props.currentEmailState?.emails ? props.currentEmailState?.emails : []);
            setEmailCcs(props.currentEmailState?.emailCcs ? props.currentEmailState?.emailCcs : []);
            setEmailBccs(props.currentEmailState?.emailBccs ? props.currentEmailState?.emailBccs : []);
            setSelectedEmailString(props.currentEmailState?.selectedEmailString ? props.currentEmailState?.selectedEmailString : sendEmailString.TO);
            setEmailCcClicked(props.currentEmailState?.emailCcClicked ? props.currentEmailState?.emailCcClicked : false);
            setEmailBccClicked(props.currentEmailState?.emailBccClicked ? props.currentEmailState?.emailBccClicked : false); 
            setAttachment(props.currentEmailState?.attachment ? props.currentEmailState?.attachment : []);
            setEditorValue(props.currentEmailState?.body_text);
            setEmailSubject(props.currentEmailState?.emailSubject);
            setInterval(props.currentEmailState?.interval ? props.currentEmailState?.interval : false);
            setIntervalDays(props.currentEmailState?.intervalDays ? props.currentEmailState?.intervalDays : 2);
            setMaxCount(props.currentEmailState?.maxCount ? props.currentEmailState?.maxCount : 3);

            props.setIsEmailSendError(false);
        }
    }, []);

    useEffect(() => {
        setTextFieldClicked(false);
        if(props.replyClick) {
            let emailArray = props.emailObj?.from  ? [props.emailObj?.from.email] : [];
            setEmails(emailArray);
            setSelectedEmailString(sendEmailString.TO);
        }
        if(props.replyAllClick) {
            let emailArray = props.emailObj?.from  ? [props.emailObj?.from.email] : [];
            if(props.toEmails && props.toEmails.length > 0) {
                const emailArr1 = props.toEmails && props.toEmails.length > 0 ? props.toEmails.map((email) => email) : [];
                const finalArr = [...emailArray, ...emailArr1];
                setEmails(finalArr);
                setSelectedEmailString(sendEmailString.TO);
            }

            if(props.ccEmails && props.ccEmails.length > 0) {
                setEmailCcs(props.ccEmails && props.ccEmails.length > 0 ? props.ccEmails.map((email) => email) : [])
                setEmailCcClicked(true);
                setSelectedEmailString(sendEmailString.CC);
            }
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(selectedEmailId !== '-1' && !selectedEmailId?.connected) {
                //onConnectClick();
            }   
        }
    }, [selectedEmailId]);


    useEffect(() => {
        if(!isMount) {
            if(props.triageOrgEmailList && props.triageOrgEmailList.length > 0) {
                if(props.triageOrgEmailList && props.triageOrgEmailList.length === 1) {
                    setSelectedEmailId(props.triageOrgEmailList[0]);
                } else {
                    const filterEmailList = props.triageOrgEmailList.filter((orgEmail) => orgEmail.id === props.currentEmailState?.selectedEmailId?.id);
                    if(!filterEmailList[0]?.connected) onConnectClick(filterEmailList[0]);
                    setSelectedEmailId(filterEmailList[0]);
                }
            }
        }
    }, [props.triageOrgEmailList]);

    useEffect(() => {
        if(props.emailAttachments && props.emailAttachments.length > 0 && props.forwardClick) {
            setAttachment(props.emailAttachments);
        }
    }, [props.emailAttachments]);

    //consoleToLog('log******', allEmailsArr);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(!textFieldClicked && emailCcs.length < 1) {
    //             setEmailCcClicked(false);
                
    //         }

    //         if(!textFieldClicked && emailBccs.length < 1) {
    //             setEmailBccClicked(false);
                
    //         }
            
    //         if(!textFieldClicked && emailCcs.length < 1 && emailBccs.length < 1) {
    //             setSelectedEmailString(sendEmailString.TO);
    //         }
    //     }

    // }, [textFieldClicked])

    const setEmailDraftObj = (emailDraft) => {

        if(emailDraft !== '-1') {
            const emailBodyText = setBodyText(emailDraft);
            setEmailSubject(emailDraft.subject.replaceAll('*', ''));
            setEditorValue(emailBodyText.replaceAll('*', ''));
            let updatedAttachments = emailDraft.attachments && emailDraft.attachments.length > 0 ?
            emailDraft.attachments.map((attahmentObj) => {
                attahmentObj.bucket = process.env.REACT_APP_S3_EMAIL_DRAFT_BUCKET_NAME;
                attahmentObj.filename =  attahmentObj.path.replace(/^.*[\\\/]/, '');
                delete attahmentObj['attachment_id'];
                return attahmentObj
            }) : [];
            setAttachment(updatedAttachments);
        }
    }

    const handleEmailDraftChange = (e) => {
        setSelectedEmailDraft(e.target.value)
        const emailDraft = e.target.value;

        setEmailDraftObj(emailDraft);
    
    }

    const setBodyText = (emailDraft) => {
        if(props.forwardClick) {
            return `${emailDraft.body}
                    ${displayForwardMessage()}`;
        } 
        else if(props.replyAllClick || props.replyClick) {
            return `${emailDraft.body}
            ${displayEditorBodyData()}`;
        } else {
            return emailDraft.body;
        }


    }

    const getEmailClientList = (query) => {
        const organization_id = props.triageSelectedOrganization.organization?.id;

        if(!query) {
            setClientEmailList([]);
            return;
        }

        fullTextSearchApi(organization_id, query)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response fullTextSearchApi', res);
                setClientEmailList(res.contacts);
            })
            .catch((e) => {
                consoleToLog('Error fullTextSearchApi', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        //consoleToLog('attachment array: ', Array.from(files));
        const fileArr = Array.from(files);
        let totalSize = fileArr.reduce((sum, obj) => sum + obj.size, 0);
        if(totalSize > 20e6) {
            fileArr.map((fileObj) => setErrorAttachments(oldErrorAttachment => [...oldErrorAttachment, fileObj])); 
        } else {
            fileArr.map((fileObj) => setAttachment(oldAttachment => [...oldAttachment, fileObj]));
        }
    }

    const showAttachment = (file, index) => {
        return (
            <div className={classes.attachmentListStyle} key={index}>
                    <i className="flaticon-attachment" style={{marginRight:'3px'}}></i>
                    <Tooltip title={file.name} arrow>
                        <Typography  variant="body1" className={classes.attachmentFilename} style={{marginTop:'-2px', marginRight:'3px'}}>
                            {file.name}
                        </Typography>
                    </Tooltip>
                    <ClearIcon fontSize="small"
                            onClick={(e) => onRemoveAttachment(e, file)}
                            style={{cursor:'pointer', fontSize:'16px'}}/>
            </div>
            );
    }

    const onRemoveAttachment = (e, file) => {
        e.stopPropagation();
        let updateAttachment= attachment.filter((fileItem) => fileItem.name !== file.name);
        setAttachment(updateAttachment);
    }

    const onEmailCcClicked = () => {
        setEmailCcClicked(true);
        setSelectedEmailString(sendEmailString.CC);
    }

    const onEmailBccClicked = () => {
        setEmailBccClicked(true);
        setSelectedEmailString(sendEmailString.BCC);
    }

    const endAdornmentText = (params) => {
        return (
            <>
                <InputAdornment position="end" 
                    style={{display:'flex', alignItems:'center', marginLeft:'auto', opacity:textFieldClicked ? 1 : 0}}>
                    {!emailCcClicked &&
                    <Tooltip title='Add Cc recipients (Ctrl-Shift-C)'>
                        <Typography className={classes.lineText}
                            onClick={onEmailCcClicked}
                            style={{marginRight:'6px', cursor:"pointer"}}>
                            Cc
                        </Typography>
                    </Tooltip>
                    }
                    
                    {!emailBccClicked &&
                    <Tooltip title='Add Bcc recipients (Ctrl-Shift-B)'>
                        <Typography className={classes.lineText}
                            onClick={onEmailBccClicked}
                            style={{cursor:"pointer"}}>
                            Bcc
                        </Typography>
                    </Tooltip>}
                </InputAdornment>
                {params.InputProps.endAdornment}
            </>
        )
    }

    const onToEmailDivClicked = () => {
        setTextFieldClicked(true);
        if(emailCcs && emailCcs.length > 0) {
            setEmailCcClicked(true);
        }

        if(emailBccs && emailBccs.length > 0) {
            setEmailBccClicked(true);
        }
    }

    const onAwayClick = () => {
        setTextFieldClicked(false);

        // if(allEmailsArr.length > 0) {
        //     getPendingChecklist();
        // }
    }

    const handleInputChange = (e, newValue, setOpen) => {
        if(newValue.length > 0) {
            getEmailClientList(newValue);
        }

            
        setInputText(newValue);
        if (!newValue) {
            setOpen(false);
        }
    }

    const onAddCompany = (option) => {
        setAllEmailsArr(oldClients => [...oldClients, option]);
    }

    const showAvatarInitials = (option) => {
        if(option.first_name || option.email) {
            return option.first_name ? option.first_name.charAt(0).toUpperCase() + option.last_name.charAt(0).toUpperCase() : option.email.charAt(0).toUpperCase();
        } else {
                return option.charAt(0).toUpperCase();
        }
    }

    const showAvatarInitialsForOptions = (option) => {
        if(option.first_name || option.email) {
            return option.first_name ? option.first_name.charAt(0).toUpperCase() + option.last_name.charAt(0).toUpperCase() : option.email.charAt(0).toUpperCase();
        } 
    }

    const showSelectedValueText = (option) => {
        if(option.first_name || option.email) {
            return  option.first_name ? capitalizeFirstLetterOfEachWord(option.first_name) + ' ' + capitalizeFirstLetterOfEachWord(option.last_name) : option.email;
        } else {
            return option;
        }
    }

    const showClientAssociatedWithContact = (option) => {
        return option.clients && option.clients.length > 0 ? `(${option.clients.map((client) => client.name).join(', ')})` : '';
    }

    const boldString = (string) => {
        let text = string ? string : '';
        let boldStringText = new RegExp(inputText, 'igm');
        let result =  text ? text.replace(boldStringText, '<strong>' + inputText + '</strong>') : '';
        return result;
    }

    const showStyledOptionsNames = (option) => {
        const fullName = option.first_name ? capitalizeFirstLetterOfEachWord(option.first_name) + " " + capitalizeFirstLetterOfEachWord(option.last_name) : option.email;
        if(option.first_name || option.email) {
            return (
                <span dangerouslySetInnerHTML={{ __html: boldString(fullName) }}>
                </span>
            )
        }
    }

    const showStyledOptionsEmail = (option) => {
            return (<span dangerouslySetInnerHTML={{ __html: boldString(option.email) }}></span>
            )
    }

    const showOptionClientList = (option) => {
        const clientList = option.clients && option.clients.length > 0 ? `(${option.clients.map((client) => client.name).join(', ')})` : '';
            return (
                <span dangerouslySetInnerHTML={{ __html: boldString(clientList) }}>
                </span>
            )
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", alignItems: 'center', width:"100%"}} 
                className={classes.autoStyle}
                //onClick={() => onAddCompany(option)}     
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {showAvatarInitialsForOptions(option)}
                </Avatar>
                <div style={{marginLeft:'8px'}}>
                    <Typography variant='subtitle1' style={{marginBottom:'0px', display:'flex', alignItems:'center'}}>
                        {showStyledOptionsNames(option)}
                        <Tooltip title={showClientAssociatedWithContact(option)} arrow>
                            <span className={classes.clientListStyle}>
                                {showOptionClientList(option)}
                            </span>
                        </Tooltip>
                    </Typography>
                    <Typography variant='subtitle1' style={{color:'grey'}}>
                        {showStyledOptionsEmail(option)}
                    </Typography>
                </div>
            </div>
        );
    }

    const onDeleteClient = (option, emailArr, setEmailArr) => {
        setEmailArr(emailArr.filter(entry => entry !== option));
    }

    const showTooltip = (option) => {
        return (
                <Paper elevation={0}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Grid item>
                            <Avatar className={classes.avatar1}>
                                {showAvatarInitials(option)}
                            </Avatar>
                        </Grid>

                        <Grid item style={{marginLeft:'12px'}}>
                            <Typography className={classes.tooltipText}>
                                <b>{showSelectedValueText(option)}</b>
                                <span style={{marginLeft:'6px', fontSize:'12px', marginTop:'-4px'}}>
                                    {showClientAssociatedWithContact(option)}
                                </span>
                            </Typography>

                            {option.email &&
                                <Typography className={classes.tooltipText1}>
                                    {option.email}
                                </Typography>

                            }
                        </Grid>
                    </div>
                </Paper>

        )
    }

    const renderTags = (options, getTagProps, emailArr, setEmailArr) => {
        return (
            options.map((option, index) =>
                <Fragment key={option?.id ? option?.id : option}>
                    <LightTooltip title={showTooltip(option)}>
                            <Chip
                                classes={{
                                    root: classes.chip
                                }}
                                avatar={<Avatar style={{position:'relative', top:'1px'}} classes={{
                                    root: classes.avatar
                                }}>{showAvatarInitials(option)}</Avatar>}
                                onDelete={() => onDeleteClient(option, emailArr, setEmailArr)}
                                variant="outlined"
                                style={{margin: "2px 2px", overflow:'hidden'}}
                                deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                                label={showSelectedValueText(option)} {...getTagProps({ index })}
                            />
                    </LightTooltip>
                </Fragment>
            ))
    }

    const handleKeyDown = (e, emailArr, setEmailArr) => {
        if(e.ctrlKey && e.shiftKey && e.keyCode === 67) {
            setEmailCcClicked(true);
            e.preventDefault();
            return;
        }

        if(e.ctrlKey && e.shiftKey && e.keyCode === 66) {
            setEmailBccClicked(true);
            e.preventDefault();
            return;
        }

        if(e.keyCode === 9 && !e.target.value) {
            e.stopPropagation();
            return;
        }

        if (e.keyCode === 13 && e.target.value && clientEmailList.length === 0) {
            addEmailToArr(e, emailArr, setEmailArr);
        }

        if (e.keyCode === 9 && e.target.value && clientEmailList.length === 0) {
            addEmailToArr(e, emailArr, setEmailArr);
        }

        if (e.keyCode === 9 && clientEmailList.length > 0) {
            setEmailArr(emailArr.concat(clientEmailList[0]));
            e.preventDefault();
        }

    }

    const addEmailToArr = (e, emailArr, setEmailArr) => {
        const emailError = emailValidator(e.target.value);
        //const emailError1 = emailArr.filter((emailObj) => emailObj.email.includes(e.target.value));
        if(!emailError) return;
        setEmailArr(emailArr.concat(e.target.value));
        e.preventDefault();
    }

    const onChange = (e, newValue, emailArr, setEmailArr) =>{
        if(newValue.length === 0) {
            setEmailArr(newValue);
        } else {
            let arrLength = newValue.length - 1;
            const errorEmail = emailValidator(newValue[arrLength].email ? newValue[arrLength].email : newValue[arrLength]);
            //const emailError1 = emailArr.filter((emailObj) => emailObj.email.includes(newValue[arrLength].email ? newValue[arrLength].email : newValue[arrLength]));
            if(!errorEmail) {
                return;
            } else {
                setEmailArr(newValue);
            }
        }
    }

    const showAutoCompleteTextField = (emailArr, setEmailArr, toString, open, setOpen) => {
        return (
            <Autocomplete
                multiple
                style={{width: "100%"}}
                id="size-small-outlined-multi"
                size="small"
                options={clientEmailList}
                open={open}
                onOpen={() =>  {
                    if(inputText) {
                        setOpen(true)
                    }
                }}
                onClose={() => setOpen(false)}
                onChange={(e, newValue) => onChange(e, newValue, emailArr, setEmailArr)}
                disableClearable
                onInputChange={(e, newValue) => handleInputChange(e, newValue, setOpen)}
                getOptionSelected={(option, value) => option?.email ? option.email === value.email : option === value}
                getOptionLabel={(option) => option?.email ? option.email : option}
                value={emailArr}
                renderOption={(option) => renderOptions(option)}
                renderTags={(options, getTagProps) => renderTags(options, getTagProps, emailArr, setEmailArr)}
                filterSelectedOptions
                filterOptions={(options) => options}
                autoHighlight
                freeSolo
                onFocus={onToEmailDivClicked}
                onBlur={(e) => addEmailToArr(e, emailArr, setEmailArr)}
                renderInput={(params) => (
                <TextField
                    {...params}
                    margin='dense'
                    //autoFocus
                    onKeyDown={(e) => handleKeyDown(e, emailArr, setEmailArr)}
                    InputProps={{ ...params.InputProps,
                        startAdornment: (
                            <>
                            <InputAdornment position="start">
                                <div style={{fontSize:'14px', color:'#747775'}}>
                                    {toString}
                                </div>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                            </>
                        ),
                        endAdornment: textFieldClicked && toString === selectedEmailString &&  endAdornmentText(params),
                        disableUnderline: true,
                        }}
                />
                )}
            />
        )
    }

    const onConnectClick = (obj) => {
        data.selectedEmailId = selectedEmailId === '-1' ? obj : selectedEmailId;
        props.setCurrentEmailState(data);
        props.setIsEmailSendError(true);
        handleModalOpen();
}

    const onSendEmailClick = () => {
        
        const from_email = selectedEmailId?.email;
        if(selectedEmailId === '-1') {
            enqueueSnackbar('Please select the email to send from', {variant:'error'});
            return;
        }
        
        const toEmails = emails && emails.length > 0 ? emails.map((email) => email.email ? email.email : email) : [];
        const CCEmails = emailCcs && emailCcs.length > 0 ? emailCcs.map((email) => email.email ? email.email : email) : [];
        const BCCEmails = emailBccs && emailBccs.length > 0 ? emailBccs.map((email) => email.email ? email.email : email) : [];
        if(toEmails.length === 0 && CCEmails.length === 0 && BCCEmails.length === 0) {
            enqueueSnackbar('Please specify at least one recipient.', {variant:'error'});
            return;
        }


        const subject = emailSubject;
        if(subject === '') {
            enqueueSnackbar('Please enter email subject.', {variant:'error'});
            return;
        }

        const body_text = editorValue;
        if (!body_text) {
            enqueueSnackbar('Email body can not be empty!', {
                variant:'error'
            });
            return;
        }

        if(interval) {
            if(intervalDays === '' || parseInt(intervalDays) === 0) {
                enqueueSnackbar('Please enter interval days', {vairant:'error'})
                return;
            }

            if(maxCount === '' || parseInt(maxCount) === 0) {
                enqueueSnackbar('Please enter max count', {variant:'error'});
                return;
            }

        }
        

        //const checklist_ids = selectedTasks && selectedTasks.length > 0 ? selectedTasks.map((checklist) => checklist.checklist_id) : [];

        const email_uuid =  props.rawS3EmailPath !== '' ? props.rawS3EmailPath : props.selectedTriageItem?.data.email_uuid;
        const redirect_uri = selectedEmailId?.email_type === 'gmail' ? process.env.REACT_APP_GMAIL_REDIRECT_URL : process.env.REACT_APP_OUTLOOK_REDIRECT_URL;
        const from_name = props.triageSelectedOrganization.organization?.name;
        let reply = props.currentEmailState ? props.currentEmailState?.replyClick : undefined;
        let reply_all = props.currentEmailState ? props.currentEmailState?.replyAllClick : undefined;
        let forward = props.currentEmailState ? props.currentEmailState?.forwardClick : undefined;        
        const apiPathStr = sendApiPath(reply ? reply : props.replyClick, reply_all ? reply_all : props.replyAllClick, forward ? forward : props.forwardClick);
        const triageItemChecklists = props.selectedTriageItem?.data?.metadata?.checklist_ids;
        const checklist_ids = triageItemChecklists && triageItemChecklists.length > 0 ? triageItemChecklists : [];
        const metadata = checklist_ids && checklist_ids.length > 0 ? {checklist_ids: checklist_ids, followups:[], organization_id: props.triageSelectedOrganization.organization.id} : undefined;
        const triageItemClients =  props.selectedTriageItem?.data?.companies;
        const company_ids = triageItemClients && triageItemClients.length > 0 ? triageItemClients.map((clientObj) => clientObj.id) : [];
        const onlyEmailAttachments = attachment && attachment.length > 0 && attachment.filter((fileObj) => fileObj.lastModified);
        const is_repeat = interval;

        trackGAEvent(props.triageSelectedOrganization.organization.name, 'Send Email Clicked From Email Details', `${props.user.firstname} ${props.user.lastname} `);


        props.setCurrentEmailState(data);
        props.setOvelayMessage(OverlayMessage.SEND_EMAIL);
        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setIsEmailSend(true);
        closeSendEmailDialog();

        setLoading(true);
        if(onlyEmailAttachments && onlyEmailAttachments.length > 0) {
                callUploadUrlApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, redirect_uri, checklist_ids, from_name, apiPathStr, metadata, company_ids, onlyEmailAttachments, is_repeat);
        } else { 
            if(interval) {
                callCreateFollowupApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, attachment, redirect_uri, from_name, email_uuid, apiPathStr, company_ids, is_repeat);
            } else {
                callReplyForwardEmailApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachment, redirect_uri, from_name, apiPathStr, metadata, company_ids, is_repeat);
            }
        }

    }

    const callUploadUrlApi = async (email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, redirect_uri, checklist_ids, from_name, apiPathStr, metadata, company_ids, onlyEmailAttachments, is_repeat) => {
        let fileAttachments = [];
        let draftAttachments = attachment.filter((obj) => obj.path);
        for(let i = 0; i < onlyEmailAttachments.length; i++) {
            const file_name = onlyEmailAttachments[i].name;
            const file_type = onlyEmailAttachments[i]?.type;
            const file = onlyEmailAttachments[i];
            const bucket = process.env.REACT_APP_S3_EMAIL_SEND_BUCKET_NAME;

            const file_path = `mails/sent/${file_name}`;

            uploadAttachmentApi(file_path, file_type, bucket, file)
            .then((data) => {
                consoleToLog('upload success', data);

                let obj = {bucket: bucket, path: file_path, name: file_name, type: file_type};
                fileAttachments.push(obj);
                consoleToLog('fileAttachments', fileAttachments)
                if(fileAttachments.length === onlyEmailAttachments.length) {
                    const finalAttachmentArray = draftAttachments && draftAttachments.length > 0 ? fileAttachments.concat(draftAttachments) : fileAttachments;
                    if(interval) {
                        callCreateFollowupApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, finalAttachmentArray, redirect_uri, from_name, email_uuid, apiPathStr, company_ids, is_repeat);
                    } else {
                        callReplyForwardEmailApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, finalAttachmentArray, redirect_uri, from_name, apiPathStr, metadata, company_ids, is_repeat);
                    }
                }
            })
            .catch((e) => {
                consoleToLog('upload error', e);
                props.setOvelayMessage('');
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setIsEmailSend(false);
                props.handleClose();
            })
        }

    }

    const callCreateFollowupApi = (from_email,  toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, attachment, redirect_uri, from_name, email_uuid, apiPathStr, company_ids, is_repeat) => {

        const organization_id = props.triageSelectedOrganization?.organization.id;

        createEmailFollowupApi(organization_id, subject, body_text, attachment, selectedEmailId?.id, toEmails, CCEmails, BCCEmails, checklist_ids, parseInt(maxCount), parseInt(intervalDays))
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createEmailFollowupApi', res);

                const metadata = {checklist_ids: checklist_ids && checklist_ids.length > 0 ? checklist_ids : [], followups: [{id: res.email_followup?.id, subject: res.email_followup?.subject}], organization_id: props.triageSelectedOrganization.organization.id}
                callReplyForwardEmailApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachment, redirect_uri, from_name, apiPathStr, metadata, company_ids, is_repeat)
            })
            .catch((e) => {
                consoleToLog('Error createEmailFollowupApi', e);
                props.setOvelayMessage('');
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setIsEmailSend(false);
                props.handleSendEmailDialogOpen();
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    }

    const callReplyForwardEmailApi = (email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachments, redirect_uri, from_name, apiPathStr, metadata, company_ids, is_repeat) => {
        data.uploadedAttachment = attachments;
        props.setCurrentEmailState(data);

        replyForwardEmailApi(selectedEmailId?.id, email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachments, redirect_uri, metadata, from_name, company_ids, is_repeat, apiPathStr)
            .then((response) => {
                const res = response.data
                consoleToLog('Response sendEmailApi, ', res);
                props.setEmailItemId(res.emaild_id);
                props.rawS3EmailPath !== '' && props.setRawEmailS3Path('');
                setLoading(false);
                
            })
            .catch((e) => {
                consoleToLog("Error sendEmailApi, ", e.response);
                setLoading(false);
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                props.setOvelayMessage('');
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setIsEmailSend(false);
                props.handleClose();
                if(e.response.status === 401 && e.response.data && e.response.data.message) {
                    props.setIsEmailSendError(true);
                    return;
                }
            })
    }

    const daysLabel = (val) => {
        return {
            inputProps: {min: 1},
            endAdornment: 
                <InputAdornment  position="end" className={classes.daysLabel}>
                    <Typography style={{color:'#555555'}}>
                        {(val === 'days') ? 'days' : 'times'}
                    </Typography>
                </InputAdornment>
        }
    }

    const closeSendEmailDialog = () => {
        props.handleClose();
        setEmails([]);
        setEmailCcs([]);
        setEmailBccs([]);
        setAllEmailsArr([]);
        setEmailCcClicked(false);
        setEmailBccClicked(false);
        setTextFieldClicked(false);
        setClientEmailList([]);
        setSelectedEmailId('-1');
        setInterval(false);
        setIntervalDays(2);
        setMaxCount(3);
        setEditorValue('');
        setAttachment([]);
    }

    const onManualClose = () => {
        closeSendEmailDialog();
        props.currentEmailState && props.setCurrentEmailState(undefined);
        props.isEmailSendError && props.setIsEmailSendError(false);
    }
    
    const handleCloseErrorModal = () => {
        setErrorAttachments([]);
    }

    return (
        <>
        <Modal
            open={props.open}
            onClose={onManualClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            >

                <div dividers className={classes.paper}>
                    
                    <Grid item container direction='column'>
                        <Grid item sm={12}
                            style={{marginBottom:'6px', padding:'4px 16px', background:'#f2f6fc'}}>

                            <Grid item container justifyContent='space-between'>
                                <Grid item sm={11}>
                                <Select
                                    variant='standard'
                                    fullWidth
                                    select
                                    style={{fontSize:'14px', color: '#041E49', fontWeight: 500, width:"50%"}}
                                    onChange={handleEmailDraftChange}
                                    value={selectedEmailDraft}
                                    disableUnderline
                                    classes={{
                                        icon: selectedEmailDraft !== '-1' ? classes.selectIcon1 : classes.selectIcon
                                        }}
                                    renderValue={() => {
                                        const item = props.triageOrgEmailDraftList.find((email) => email.title === selectedEmailDraft.title); 
                                        return item ? item.title : 'New Email'
                                        }}
                                >
                                    <MenuItem value='-1'>New Email</MenuItem>
                                    {
                                        props.triageOrgEmailDraftList && props.triageOrgEmailDraftList.length &&
                                        props.triageOrgEmailDraftList.map((obj) => {
                                            return  <MenuItem key={obj.id} value={obj}>
                                                        {obj.title}
                                                    </MenuItem>
                                        })
                                    }
                                </Select>
                                </Grid>

                                <Grid item sm={1} style={{flexBasis:'3%', maxWidth:'3%'}}>
                                    <Button onClick={onManualClose}
                                        className={classes.emailCloseButton}> 
                                        <ClearIcon fontSize='small'/>
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                        {/* <Grid item sm={12} className={classes.topBarStyle}>

                            {<Grid item container justifyContent='space-between' alignItems='center' >
                                <Grid item sm={11}>
                                    <Typography variant='subtitle1' 
                                        style={{fontWeight:500}}
                                        className={classes.headingStyle}>
                                        {displayEmailSubject(emailSubject)}
                                    </Typography>
                                </Grid>

                                <Grid item sm={1} style={{flexBasis:'2.8%', maxWidth:'2.8%'}}>
                                    <Button className={classes.emailCloseButton} 
                                        onClick={onManualClose}> 
                                            <ClearIcon fontSize='small'/>
                                    </Button>
                                </Grid>
                            </Grid>}

                        </Grid> */}
                        {<Grid item sm={12} className={classes.customPadding}>
                            <Grid item container alignItems='center'>
                                <Grid item md={1}  className={classes.customColumn}>
                                    <Typography className={classes.textStyles}>
                                        From
                                    </Typography>
                                </Grid>

                                {<Grid item>
                                    <Select
                                        fullWidth
                                        className={classes.textStyles}
                                        style={{width:"100%", lineHeight:'16px'}}
                                        onChange={handleChange}
                                        value={selectedEmailId}
                                        disableUnderline
                                        classes={{
                                            icon: selectedEmailId !== '-1' ? classes.selectIcon3 : classes.selectIcon2
                                            }}
                                        renderValue={() => {
                                            const item = props.triageOrgEmailList.find((email) => email?.email === selectedEmailId.email); 
                                            return item ? item.email : 'Please select email'
                                            }}
                                        
                                    >
                                        <MenuItem value='-1'> Please select email</MenuItem>
                                        {
                                            props.triageOrgEmailList && props.triageOrgEmailList.length &&
                                            props.triageOrgEmailList.map((obj) => {
                                                
                                                return   obj.verified &&
                                                        <MenuItem key={obj.id} value={obj}>
                                                            {obj.email}
                                                        </MenuItem>
                                            })
                                        } 
                                    </Select>
                                </Grid>
                                }

                                {
                                    selectedEmailId !== '-1' && !selectedEmailId?.connected &&
                                    <Grid item>
                                        <Typography onClick={onConnectClick} 
                                            className={classes.connectLinkStyle}>
                                            Connect
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid> }
                        
                        <ClickAwayListener onClickAway={(e) => {
                                    setTextFieldClicked(false);
                        }}>
                            <Grid item sm={12}
                                className={`${classes.customBorder} ${classes.customPadding}`}>
                                
                                    {showAutoCompleteTextField(emails, setEmails, 'To', openDropdown, setOpenDropdown)}

                                    {emailCcClicked && showAutoCompleteTextField(emailCcs, setEmailCcs, 'Cc', openDropdown1, setOpenDropdown1)}

                                    {emailBccClicked && showAutoCompleteTextField(emailBccs, setEmailBccs, 'Bcc', openDropdown2, setOpenDropdown2)}
                                    
                            </Grid>
                        </ClickAwayListener>

                        <Grid item sm={12} className={`${classes.customBorder} ${classes.customPadding}`}>
                            <TextField variant='standard'
                                fullWidth
                                margin='dense'
                                className={classes.textStyles}
                                style={{padding:'4px 0px 4px 0px', background:'#fff', color:'#041E49', border:'none'}}
                                value={emailSubject}
                                onChange={(e) => setEmailSubject(e.target.value)}
                                placeholder='Subject'
                                inputProps={{style:{fontSize: '14px', color:'#747775'}}}
                                InputProps={{disableUnderline: true}}
                            />
                        </Grid>
                        
                        <Grid item sm={12} 
                            className={classes.customPadding}
                            style={{marginTop:'8px', position:'relative'}}>
                                <div>
                                    <JoditEditor
                                        ref={editorRef}
                                        value={editorValue}
                                        config={config}
                                        tabIndex={1}
                                        onBlur={newContent => setEditorValue(newContent)} // preferred to use only this option to update the editorValue for performance reasons
                                        //onChange={(newContent) => setEditorValue(newContent)}
                                    />
                                    {/* <Editor
                                        apiKey={process.env.REACT_APP_TINY_CLOUD_MCE_API_KEY}
                                        value={editorValue}
                                        init={{
                                            height:350,
                                            menubar: false,
                                            auto_focus: true ,
                                            selector: 'textarea',
                                            plugins: "link lists",
                                            //images_upload_url: 'postAcceptor.php',
                                            automatic_uploads: true,
                                            content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }",
                                            images_file_types: "jpg,svg,png",
                                            toolbar: 'undo redo | styleselect | fontsize forecolor | bold italic | align bullist numlist | outdent indent',
                                            fontsize_formats:
                                            "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                            //paste_data_images: true
                                            
                                        }}
                                        onClick={() => setTextFieldClicked(false)}
                                        onEditorChange={(data) => setEditorValue(data)}
                                    /> */}
                                </div>
                        </Grid>

                        <Grid item sm={12} className={classes.customPadding}>
                                <Grid item container alignItems='center' style={{marginTop:'16px'}}>
                                {
                                    attachment && attachment.length > 0 &&
                                    attachment.map((file, index) => {
                                        return showAttachment(file, index);
                                    })
                                }
                                </Grid>
                        </Grid>

                        <Grid item sm={12}
                            className={classes.customPadding}
                            style={{marginTop:'8px'}}>
                            <Grid item container
                                alignItems='center'>

                                <Grid item >
                                    <Button onClick={onSendEmailClick}
                                        className={classes.sendButtonStyle}
                                        variant='outlined'
                                        color='primary'>
                                        {loading && <CircularProgress size={18} style={{marginRight:'8px', color:"#fff"}}/>}
                                        Send Email
                                    </Button>
                                </Grid>

                                <Grid item>
                                        <Tooltip title='Attach files'>
                                            <Button  component="label" size='small'
                                                disableTouchRipple
                                                className={classes.attachLink}>
                                                    <AttachFileIcon fontSize='small'/>
                                            <input style={{display:'none'}}
                                                type="file"
                                                multiple
                                                id="emailSchedulerAttachment"
                                                onChange={onAttachmentSelected}
                                            />
                                            </Button>
                                        </Tooltip>
                                </Grid>

                                <Grid item md={3} style={{flexBasis:'10%', maxWidth:'10%'}}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.MuiFormControlLabelroot
                                        }}
                                        control={
                                        <Checkbox
                                            name="checkedB"
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            checked={interval}
                                            onChange={(event) => setInterval(event.currentTarget.checked)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        }
                                        label="Repeat"
                                    />
                                </Grid>
                                
                                <Grid item md={3} style={{marginRight:'16px', marginTop:'-4px', visibility:interval ? 'visible' : 'hidden'}}>
                                    <Grid item container alignItems="center">
                                        <Grid item md={3}>
                                            <span style={{color:'#555', fontSize:'14px', position:'relative', top:'1px'}}>
                                                Every
                                            </span>  
                                        </Grid> 
                                        <Grid item md={8} style={{marginLeft:'6px'}}>
                                            <TextField 
                                                fullWidth
                                                type="number"
                                                autoComplete='off'
                                                id="interval_days"
                                                margin="dense"
                                                variant="outlined"
                                                disabled={!interval}
                                                value={intervalDays}
                                                onChange={(e) => setIntervalDays(e.target.value)}
                                                InputProps={daysLabel('days')}
                                                classes={{
                                                    root: classes.MuiTextFieldroot
                                                }}
                                                onKeyPress={inputNumberValidate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={3} style={{marginTop:'-4px', visibility:interval ? 'visible' : 'hidden'}}>
                                    <Grid item container alignItems="center">
                                        <Grid item md={3}>
                                            <span style={{color:'#555', fontSize:'14px'}}>
                                                Maximum
                                            </span>  
                                        </Grid> 

                                        <Grid item md={8} style={{marginLeft:'16px'}}>
                                            <TextField 
                                                fullWidth
                                                type="number"
                                                autoComplete='off'
                                                id="max_count"
                                                margin="dense"
                                                variant="outlined"
                                                value={maxCount}
                                                disabled={!interval}
                                                onChange={(e) => setMaxCount(e.target.value)}
                                                InputProps={daysLabel('times')}
                                                classes={{
                                                    root: classes.MuiTextFieldroot
                                                }}
                                                onKeyPress={inputNumberValidate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </div>
        </Modal>
        {
            openModal &&
            <ConnectEmailModal selectedEmailId={selectedEmailId}
                open={openModal}
                handleModalClose={handleModalClose}
                selectedTriageItem={props.selectedTriageItem}
                fromReplyForwardEmailDialog={true}
                />
        }
        {
                errorAttachments && errorAttachments.length > 0 && 
                <EmailAttachmentErrorModal openErrorModal={errorAttachments && errorAttachments.length > 0} 
                    handleCloseErrorModal={handleCloseErrorModal}
                    errorAttachments={errorAttachments}
                    />
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
    triageOrgEmailList: state.triageSelectedOrganizationInfo.orgEmailList,
    isEmailSendError: state.triageSelectedOrganizationInfo.isEmailSendError,
    currentEmailState: state.triageSelectedOrganizationInfo.currentEmailState,
    user: state.triageSelectedOrganizationInfo.user,
    rawS3EmailPath: state.triageSelectedOrganizationInfo.rawS3EmailPath,
    triageOrgEmailDraftList: state.triageSelectedOrganizationInfo.orgEmailDraftList
});

const mapDispatchToProps = (dispatch) => ({
    setIsEmailSendError: (emailSendError) => dispatch(setIsEmailSendError(emailSendError)),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setEmailItemId: (emailItemId) => dispatch(setEmailItemId(emailItemId)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setIsEmailSend: (isEmailSend) => dispatch(setIsEmailSend(isEmailSend)),
    setRawEmailS3Path: (emailPath) => dispatch(setRawEmailS3Path(emailPath))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReplyForwardEmailModal);