import React, {useState, useEffect, useRef} from 'react';
import BaseComponent from './Base/BaseComponent';
import { makeStyles } from '@material-ui/core/styles';
import LeftMenuComponent from './LeftMenuComponent';
import { connect } from 'react-redux';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import {Grid, CircularProgress} from '@material-ui/core';
import TriageComponent from './TriageComponent';
import { useLocation } from 'react-router-dom';
import { getOrganizationList } from '../actions/triageSelectedOrganization';
import { setLoading } from '../actions/loading';
import { useIsMount } from './useIsMount';
import { useNavigate } from 'react-router-dom';
import { consoleToLog } from '../util/AppUtil';
import SendEmailDialog from "./modal/SendEmailDialog";
import SentEmailsComponent from './SentEmailsComponent';
import { Navigate } from 'react-router-dom';
import { setClearOrgState } from '../actions/triageSelectedOrganization';
import { logout } from '../actions/triageAuth';
import {clearOrgs} from '../actions/triageOrganizations';
import { fetchAccessToken } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    displayflex : {
        display: "flex", 
        height: "100%",
    },
    loading: {
        position:'absolute',
        left:'50%',
        right:'50%',
        top:'15%'
    }

}));

const DashboardPage = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const isMount = useIsMount();
    const navigate = useNavigate();

    const organization_id = location.state?.organization_id;
    
    const [showDrawer, setShowDrawer] = useState(true);
    const [triageList, setTriageList] = useState([]);
    const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
    const [messageObj, setMessageObj] = useState(undefined);
    const [accessToken, setAccessToken] = useState(undefined);
    const triageArr = useRef([]);

    const URL_WEB_SOCKET = `${process.env.REACT_APP_CONNECT_WEBSOCKET_URL}?authorization=${accessToken?.replace('Bearer ', '')}&type=genie`;
    
    const onNotificationClick = (event) => {
            //event.preventDefault();
            window.open(`${process.env.REACT_APP_GENIE_APP_DOMAIN}/dashboard`);
    }
    
    // window.addEventListener('beforeunload', function (e) {
    //     e.preventDefault();
    //     props.setClearOrgState();
    //     props.clearOrgs();
    //     props.logout();
    //     props.setSelectedDrawerState(SelectedDrawerState.SHOW_TRIAGE);
    // });

    const showNotification = (message) => {
        const title = 'New Message Received';
        const options = {
            body : `New Email Received from ${message.publicMessage?.data.from_name} \nSubject: ${message.publicMessage?.data.subject}`, 
            icon: '/images/eprocessify-icon.png'
        }

        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            consoleToLog("This browser does not support desktop notification");
        }
        
        else if(Notification.permission === 'granted') {
            const notification = new Notification(title, options);
            notification.onclick = (event) => {
                onNotificationClick(event)
            } 
            consoleToLog('Notification permission granted');
        } else if (Notification.permission !== "denied") {
            consoleToLog('Request permission');
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                consoleToLog('permission', permission);
                if (permission === "granted") {
                    const notification = new Notification(title, options);
                    notification.onclick = (event) => {
                        onNotificationClick(event)
                    }
              //  const notification = new Notification('Message Received', {body: `New Email Received from ${message.publicMessage?.data.from_name} \nSubject: ${message.publicMessage?.data.subject}`, icon: '/images/eprocessify-icon.png'});
                // …
                }
            });
        }
    }

    let webSocket;
    const connectToWebSocket = () => {
        webSocket = new WebSocket(URL_WEB_SOCKET);
    
        webSocket.onopen = (event) => {
            consoleToLog('event opened', event);
        };

        webSocket.onerror = (event) => {
            consoleToLog('event opened', event);
            webSocket.close();
        };

        webSocket.onclose = () => {
            consoleToLog('ws closed');
            connectToWebSocket();
        };

        webSocket.onmessage = (evt) => {
            const message = JSON.parse(evt.data);
            consoleToLog('received message', message);
            setMessageObj(message);
            addToTriageList(message);
            message.isNewEmail && showNotification(message);
        };

    }

    useEffect(() => {
        const fetchToken = async () => {
            try {
            const data = await fetchAccessToken('workplace');
            if(data) {
                consoleToLog('Response authenticateApi', data);
                const {access_token, redirect_url, invalid_service, scope} = data;
                if(access_token) {
                    setAccessToken(access_token)
                }  else {
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self');
                }

            } 
            } catch (error) {
                consoleToLog("error fetchAccessToken", error);
            }
        };
    
        fetchToken();

        return () => {
            webSocket.close();
        };
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(accessToken) {
                connectToWebSocket()
            }
        }
    }, [accessToken])

    useEffect(() => {
        if(props.isEmailSendError && props.rawS3EmailPath === '') {
            handleSendEmailDialogOpen();
        }
    }, [])

    const drawerAction = () => {
        setShowDrawer(!showDrawer);
    }

    const fetchTriageListFromChild = (triageListFromChild) => {
        triageArr.current = triageListFromChild;
        setTriageList(triageArr.current);
    }

    const addToTriageList = (message) => {
        let itemInTriageList = triageArr.current.filter((triageItem) => triageItem.id === message.publicMessage.id);
        if(itemInTriageList && itemInTriageList.length > 0) {
            if(message.updateToTop) {
                let updateItemAtTop = triageArr.current.filter((triageItem) => triageItem.id !== message.publicMessage.id);
                setTriageList([message.publicMessage, ...updateItemAtTop]);
            } else {
                let updateItemAtCurrentPosition = triageArr.current.map((triageItem) => {
                    if(triageItem.id === message.publicMessage.id) {
                        triageItem = message.publicMessage
                    }
                    return triageItem
                });
                setTriageList(updateItemAtCurrentPosition);
            }
        } else {
            setTriageList([message.publicMessage, ...triageArr.current]);
        }
    }

    useEffect(() => {
        document.title = 'eProcessify Inbox'
        props.setLoading(true);
        props.getOrganizationList(props.triageSelectedOrganization, organization_id);
        if(organization_id) navigate({state: {}})
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeydown, false);

        return () => {
            document.removeEventListener("keydown", handleKeydown);
        };
    }, [])
            
    const handleKeydown = (e) => {
        if(e.ctrlKey && e.key === 'e') {
            e.preventDefault();
            handleSendEmailDialogOpen();
        }
    }

    const displaySelectedComponent = () => {
        if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
            return  <TriageComponent showDrawer={showDrawer} triageList={triageList} 
                        fetchTriageListFromChild={fetchTriageListFromChild}
                        messageObj={messageObj}
                        setMessageObj={setMessageObj}/>
        }

        if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
            return  <TriageComponent showDrawer={showDrawer}/>
        }

        if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
            return  <TriageComponent showDrawer={showDrawer}/>
        }
        
    }

    const handleSendEmailDialogOpen = () => {
        setOpenSendEmailDialog(true);
    }

    const handleSendEmailDialogClose = () => {
        setOpenSendEmailDialog(false);
    }

    return (
        <BaseComponent drawerAction={drawerAction}
            handleSendEmailDialogOpen={handleSendEmailDialogOpen}>
            <div className={classes.displayflex}>
                {
                    props.isLoading 
                    ? <CircularProgress 
                        size={35}
                        className={classes.loading}
                    />
                    :
                    <>
                        <LeftMenuComponent showDrawer={showDrawer}/>
                        <Grid item container flexDirection="column">
                            {displaySelectedComponent()}
                        </Grid>
                    </>
                    
                }
            </div>
            {openSendEmailDialog && <SendEmailDialog openSendEmailDialog={openSendEmailDialog}
                handleSendEmailDialogClose={handleSendEmailDialogClose}
                handleSendEmailDialogOpen={handleSendEmailDialogOpen}
            />}
        </BaseComponent>
    );
}

const mapStateToProps = (state) => ({
    drawerState: state.drawerState.setDrawerState,
    isLoading: state.loading.isLoading,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
    isEmailSendError: state.triageSelectedOrganizationInfo.isEmailSendError,
    rawS3EmailPath: state.triageSelectedOrganizationInfo.rawS3EmailPath,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    getOrganizationList: (selectedOrganization, organizationId) => 
        dispatch(getOrganizationList(selectedOrganization, organizationId)),
        setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    setClearOrgState: () => dispatch(setClearOrgState()),
    clearOrgs: () => dispatch(clearOrgs()),
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);