import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect ,useState} from 'react';
import { connect } from 'react-redux';
import { OverlayView,  setOverlayView, setOvelayMessage, setEmailItemId, setIsEmailSend} from '../actions/triageOverlay';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import { useSnackbar } from 'notistack'; 
import {checkEmailBackgroundTaskCompleted} from '../services/genieService';
import {setCurrentEmailState, setEmailSentFrom} from '../actions/triageSelectedOrganization';
import { setIsEmailSendError } from '../actions/triageSelectedOrganization';

const OverlayComponent = (props) => {
    var interval;
    const isMount = useIsMount();
    const[errorMessage, setErrorMessage] = useState("");
    const[errorMessageArr, setErrorMessageArr] = useState([]);

    const {enqueueSnackbar} = useSnackbar();

    useEffect(()=>{
        if(!isMount && props.triageOverlay && props.triageOverlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && props.triageOverlay.emailItemId){
            consoleToLog("useEffect 2: ")
            //loadData();
            interval = setInterval(callSendEmailCheckApi, 5000);
        }

    }, [props.triageOverlay && (props.triageOverlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING) && props.triageOverlay.emailItemId])

    useEffect(() => {
        if(!isMount){
            if(props.triageOverlay.emailItemId === ""){
                if (errorMessageArr && errorMessageArr.length === 0){
                    props.setOverlayView(OverlayView.NO_VIEW);
                }else{
                    //props.setOverlayView(OverlayView.ERROR_VIEW);
                     //consoleToLog("error view1");
                }    
            }
        }
        return () => clearInterval(interval);
    }, [props.triageOverlay.emailItemId, errorMessage])
    

    const clearReduxState = () => {
        props.setOverlayView(OverlayView.NO_VIEW);
        clearInterval(interval);
        props.setIsEmailSend(false);
        props.setEmailItemId('');
        props.setCurrentEmailState(undefined);
        props.setIsEmailSendError && props.setIsEmailSendError(false);
    }
    
    const callSendEmailCheckApi = () => {
        const randomToken = props.triageOverlay.emailItemId;
        checkEmailBackgroundTaskCompleted(randomToken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response checkEmailBackgroundTaskCompleted: ", res); 

                if(res.status === 'SUCCESS') {
                    enqueueSnackbar('Email sent successfully!', {variant:'success'});
                    trackGAEvent(props.triageSelectedOrganization.organization.name, `Email Sent from ${props.emailSentFrom}` , `${props.user.firstname} ${props.user.lastname} `);
                    props.setEmailSentFrom('');
                    clearReduxState();
                }
            })
            .catch((e) => {
                consoleToLog("checkEmailBackgroundTaskCompleted error", e.response);
                props.setOvelayMessage("");
                props.setOverlayView(OverlayView.ERROR_VIEW);
                clearInterval(interval);
                props.setIsEmailSend(false);
                props.setEmailItemId('');

                if (e.response.data.status && e.response.data.status === 'FAIL') {
                    setErrorMessageArr([e.response.data.message])
                }

            }); 

    }

    const stopApiRequest = () => {
        props.setOverlayView(OverlayView.NO_VIEW);
        clearInterval(interval);
        props.setIsEmailSend(false);
        props.setEmailItemId('');
        props.setCurrentEmailState(undefined);
        props.setIsEmailSendError && props.setIsEmailSendError(false);
    }
    
    return(
        <div style={{
            position: 'absolute',
            zIndex: 2000,
        }}>
            {props.triageOverlay && (props.triageOverlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && props.triageOverlay.overlayMessage !== "")  
                && <div style={{borderRadius: "8px", color: 'white', background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <div>
                    <CircularProgress color="white" style={{width: "25px", height: "25px", marginBottom: "-10px"}}/>
                    <span style={{marginBottom: "50px", marginLeft: "8px"}}>{props.triageOverlay.overlayMessage}</span>
                </div>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={stopApiRequest}>
                        Close
                </Button>
            </div>}

            {props.triageOverlay && (props.triageOverlay.showOverlayView === OverlayView.ERROR_VIEW && errorMessageArr && errorMessageArr.length > 0)  
                && <div style={{borderRadius: "8px", color: 'white', background: "red",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <ul style={{margin:'0px', paddingLeft:'22px'}}>
                    {   
                        errorMessageArr && errorMessageArr.length > 0 &&
                        errorMessageArr.map((error) => {
                            return <li key={error} style={{marginBottom: "8px", padding:"0px"}}>{error}</li> 
                        })                    }
                    {/* <span style={{marginBottom: "50px", marginLeft: "8px"}}>{errorMessage}</span> */}
                </ul>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={()=>{
                        props.setOverlayView(OverlayView.NO_VIEW);
                        clearInterval(interval);
                    }}>
                        Close
                </Button>
            </div>}
            
        </div>
    )
}



const mapStateToProps = (state) => ({
    triageOverlay: state.triageOverlay,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
    currentEmailState: state.triageSelectedOrganizationInfo.currentEmailState,
    user: state.triageSelectedOrganizationInfo.user,
    emailSentFrom: state.triageSelectedOrganizationInfo.emailSentFrom,
    isEmailSendError: state.triageSelectedOrganizationInfo.isEmailSendError
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setEmailItemId: (emailItemId) => dispatch(setEmailItemId(emailItemId)),
    setIsEmailSend: (isEmailSend) => dispatch(setIsEmailSend(isEmailSend)),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str)),
    setIsEmailSendError: (emailSendError) => dispatch(setIsEmailSendError(emailSendError))

})

export default connect(mapStateToProps, mapDispatchToProps)(OverlayComponent)