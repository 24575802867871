import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Container, Typography, Button, Tooltip, MenuItem, Popover, CircularProgress, Divider} from '@material-ui/core';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        height:'calc(100vh - 51px)',
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        position:'relative'
    },
    container: {
        padding: "0px 12px 4px 12px",
        margin:'8px 0px 0px 0px'
    },
    }
));  

const SentEmailsComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);

    return (
        <div className={classes.mainContent}>
            <Grid item container alignItems="center" className='stickyTopBar'>
                <Container maxWidth="xl" className={classes.container}>   
                    <Grid item container alignItems="center">
                        <div style={{display:'flex', alignItems:'center', marginLeft:'16px'}}>
                            {
                                props.triageOrgEmailList && props.triageOrgEmailList.length > 0 &&
                                props.triageOrgEmailList.slice(0,2).map((orgEmail) => {
                                    return <Grid item container alignItems='center'>
                                                <Grid item
                                                    style={{
                                                        width:'14px',
                                                        height:'14px',
                                                        background: orgEmail.color,
                                                        marginRight:'6px'
                                                    }}>
                                                </Grid>
                                                <Grid item md={10}>
                                                    <Typography variant='body2' style={{fontWeight:500}}>
                                                        {orgEmail.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                })
                            }
                        </div>
                    </Grid>
                </Container>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    triageOrgEmailList: state.triageSelectedOrganizationInfo.orgEmailList,
})

export default connect(mapStateToProps)(SentEmailsComponent);