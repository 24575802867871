export default (state = [], action) => {
    switch (action.type) {
        case 'SET_ORGANIZATIONS':
            return action.organizations;
        
        case 'CLEAR_ORGS':
            return state;

        default:
            return state;
    }
};