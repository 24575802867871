import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import { Provider } from 'react-redux';
import { loadState, saveState } from './localStorage'
import configureStore from './store/configureStore';
import ReactGA from "react-ga4";
import {consoleToLog, isDebug} from './util/AppUtil';

const persistedState = loadState();
const store = configureStore(persistedState);

//Things you want to be persisted all time
store.subscribe(() => {
  saveState({
      triageAuth: store.getState().triageAuth,
      drawerState: store.getState().drawerState,
      triageOrganizations: store.getState().triageOrganizations,
      triageSelectedOrganizationInfo: store.getState().triageSelectedOrganizationInfo,
      triageOverlay: store.getState().triageOverlay
  });
});

const initGA4 = () => {
  consoleToLog("***init GA4")
  ReactGA.initialize(process.env.REACT_APP_GA4_API_KEY);
}

if (!isDebug()) {
  initGA4();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
