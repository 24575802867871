import {organizationListApi, organizationDetailsApi} from '../services/mainAppService';
import { consoleToLog } from '../util/AppUtil';
import {setOrganizations} from './triageOrganizations';
import {setLoading} from '../actions/loading';
import {getOrgSupportEmailsApi, getEmailDraftListApi} from '../services/genieService';

export const setSelectedOrganizationId = (organization_id) => ({
    type: 'SET_SELECTED_ORG_ID',
    organization_id
});

export const setSelectedOrg = (organization) => ({
    type: 'SET_SELECTED_ORG',
    organization
});

export const getOrganizationList = (selectedOrganization, organizationId) => {
    return (dispatch) => {
        organizationListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response OrganizationListApi ", res);
                dispatch(setOrganizations(res));

                // if (Object.keys(selectedOrganization).length === 0 && organizationId) {
                //     dispatch(setSelectedOrg(res[0]));
                // }    

                if(organizationId) {
                    const selectedOrg = res.find((org) => org.organization.id === Number(organizationId));
                        if (selectedOrg) {
                            dispatch(setSelectedOrg(selectedOrg));
                            dispatch(fetchOrgDetails(organizationId));
                    }
                } else {

                let organization_id;
                if (Object.keys(selectedOrganization).length === 0) {
                    organization_id = res[0].organization.id
                } else {//if selected org found, get its id
                        organization_id = selectedOrganization.organization.id;
                            //if found update its data
                        const updateSelectedOrg = res.find((org) => org.organization.id === organization_id);
                            if (updateSelectedOrg) {
                                dispatch(setSelectedOrg(updateSelectedOrg));
                        }
                        dispatch(fetchOrgDetails(organization_id));
                    }
                }
                //fetch members & labels and store in redux-> orgInfo-> members
                
            })
            .catch((e) => {
                consoleToLog('Error OrganizationListApi1', e);
                dispatch(setLoading(false));
                const { status } = e.response;
                consoleToLog('Error code', status);
                //if unauthorized error comes we will clear all data from redux and redirect to login
                if (status === 401) {
                    // dispatch(logout());
                    // dispatch(logoutOrgs());
                    // dispatch(logoutSelectedOrg());
                    // dispatch(logoutFilters());
                    // history.push('/login');
                    // firebase.auth().signOut();
                }

                if (e.response.data && e.response.data.message) {
                    //  toast.error(e.response.data.message);
                    return false;
                }
            });
    };
}

//set members list of selected org
export const setOrgMembers = (members) => ({
    type: 'SET_ORG_MEMBERS',
    members
});

export const fetchOrgDetails = (organization_id) => {
    return(dispatch) => {
        organizationDetailsApi(organization_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response OrganizationDetailsApi: ', res);

                    //set members & labels of selected organization in redux
                    dispatch(setOrgMembers(res.members));

                    dispatch(fetchOrgEmails(organization_id));
                })
                .catch((e) => {
                    consoleToLog("OrganizationDetailsApi error", e.response);
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
    }
}


//set org emails
export const setOrgEmails = (emails) => ({
    type: "SET_ORG_EMAILS",
    emails
})

//update org email
export const updateOrgEmail = (email) => ({
    type: "UPDATE_ORG_EMAIL",
    email
})

export const fetchOrgEmails = (organization_id) => {
    return(dispatch) => {
        getOrgSupportEmailsApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getOrgSupportEmailsApi: ', res);

                const colors = [
                    '#61be4e',
                    '#fc9f1b',
                    '#eb5a46',
                    '#f2d600',
                    '#c377e0',
                    '#1379bf',
                    '#2ec2e0',
                    '#51e898',
                    '#fa78CC',
                    '#344563'
                ];

                const organizationEmails = res.organization_emails;
                const updatedOrganizationEmailArr = organizationEmails.map((emailObj, index) => {
                    emailObj.color = colors[index];
                    return emailObj
                })

                dispatch(setOrgEmails(updatedOrganizationEmailArr));
                dispatch(fetchEmailDraftsList(organization_id));

            })
            .catch((e) => {
                consoleToLog('Error getOrgSupportEmailsApi: ', e.response);
                dispatch(setLoading(false));
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }
}

export const fetchEmailDraftsList = (organization_id) => {
    return (dispatch) => {
        getEmailDraftListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEmailDraftListApi: ', res);

                dispatch(setOrgEmailDrafts(res.email_drafts));
                dispatch(setLoading(false))
            })
            .catch((e) => {
                consoleToLog("getEmailDraftListApi error", e.response);
                dispatch(setLoading(false));
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }
}

export const setSelectedOrgEmailObj = (emailObj) => ({
    type: "SET_SELECTED_ORG_EMAIL_OBJ",
    emailObj
});

export const setIsEmailSendError = (emailSendError) => ({
    type: 'SET_IS_SEND_EMAIL_ERROR',
    emailSendError
});

export const setCurrentEmailState = (emailState) => ({
    type: 'SET_CURRENT_EMAIL_STATE',
    emailState
});

export const setRawEmailS3Path = (emailPath) => ({
    type: 'SET_RAW_EMAIL_S3_PATH',
    emailPath
});

export const setOrgEmailDrafts = (draftsList) => ({
    type: "SET_ORG_EMAIL_DRAFTS",
    draftsList
});

export const setUserObj = (user_obj) => ({
    type: 'SET_USER_OBJ',
    user_obj
});

export const setEmailSentFrom = (str) => ({
    type: 'SET_EMAIL_SENT_FROM',
    str
});

export const setClearOrgState = () => ({
    type: 'SET_CLEAR_ORG_STATE',
})