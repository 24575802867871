import React, {useEffect, useState} from 'react';
import { Drawer, AppBar, Toolbar, IconButton, Typography, Grid, Paper, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useIsMount } from './useIsMount';
import SetClientForTriageItem from './SetClientForTriageItem';
import SetChecklistForTriageItem from './SetChecklistForTriageItem';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '32%', 
        height: (fromDetailsOpen) => {
            return  fromDetailsOpen ? '100vh' : 'calc(100vh - 51px)'
        },
        flexGrow:1,
        top: (fromDetailsOpen) => {
            return  fromDetailsOpen ? '0px' : '51px'
        },
        padding:'16px'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        background:'#f7f7f7'
    },
    detailBadge: {
        backgroundColor: "#cccccc",
        fontSize: "12px",
        borderRadius: "3.5px",
        padding: "0px 6px",
        fontWeight: 500,
        color: "black",
        marginBottom: "3px",
        marginLeft: "5px",
    },
}));

const AssignCompanyChecklistDrawer = (props) => {
    const {fromDetailsOpen} = props;
    const classes = useStyles(fromDetailsOpen);
    const isMount = useIsMount();

    const [value, setValue] = useState(0);
    const [checklistTextBoxClicked, setChecklistTextboxClicked] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if(!isMount) {
            if(props.openClientTab) {
                setValue(0);
            }

            if(props.openChecklistTab) {
                setValue(1);
            }
        }

    }, [props.openClientTab, props.openChecklistTab])

    const companiesCount = props.selectedTriageObj?.data.companies ? props.selectedTriageObj.data?.companies.length : '';
    const checklistsCount = props.selectedTriageObj?.data.checklists ? props.selectedTriageObj.data?.checklists.length : '';

    const TabsWithBadage = (props) => {
        return (
            <span>
                {props.labelname}
                <Badge color="primary" className={classes.detailBadge}>
                    {props.count}
                </Badge>
            </span>
        );
    };

    return (
        <Drawer variant="temporary"
            anchor="right"
            open={props.openDrawer}
            onClose={props.handleDrawerClose}
            classes={{
                paper: classes.drawerPaper
                }}
            >
            <Grid item md={12}>
                <Grid item md={12} className='companyChecklistStyle'>
                    <Typography variant='body1' style={{fontWeight:600}}>
                        Companies
                    </Typography>

                    <SetClientForTriageItem addClientToTriageItem={props.addClientToTriageItem}
                        selectedTriageObj={props.selectedTriageObj}
                        checklistTextBoxClicked={checklistTextBoxClicked}
                        setChecklistTextboxClicked={setChecklistTextboxClicked}/>
                </Grid>

                <Grid item md={12} className='companyChecklistStyle' style={{marginTop:'24px'}}>
                    <Typography variant='body1' style={{fontWeight:600}}>
                        Checklist
                    </Typography>

                    <SetChecklistForTriageItem addChecklistToTriageItem={props.addChecklistToTriageItem}
                        selectedTriageObj={props.selectedTriageObj}
                        setChecklistTextboxClicked={setChecklistTextboxClicked}
                        />

                </Grid>
                
            </Grid>
        </Drawer>
    );
}

export default AssignCompanyChecklistDrawer;