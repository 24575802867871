import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { login } from '../actions/triageAuth';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../actions/loading';
import {Grid, CircularProgress} from '@material-ui/core';
import { setUserObj } from '../actions/triageSelectedOrganization';
import {fetchAccessToken} from '../util/AppUtil';
import { consoleToLog } from '../util/AppUtil';

const LoginPage = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

            let url_string = window.location.href;
            let url = new URL(url_string);
            let organization_id = url.searchParams.get("organization_id");
            let firstname = url.searchParams.get("firstname");
            let lastname = url.searchParams.get("lastname");
            let obj = {firstname, lastname};

            const fetchToken = async () => {
                try {
                const data = await fetchAccessToken('workplace');
                if(data) {
                    consoleToLog('Response authenticateApi', data);
                    const {access_token, redirect_url, invalid_service, scope} = data;
                    if(access_token) {
                        props.setLoading(true);
                        props.setUserObj(obj);
                        navigate('/dashboard', {state:{organization_id: organization_id}})
                    }  else {
                        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self');
                    }
    
                } 
                } catch (error) {
                    consoleToLog("error fetchAccessToken", error);
                }
            };
        
            fetchToken();

            // if(access_token) {
            //     props.setLoading(true);
            //     props.setUserObj(obj);

            //     navigate('/dashboard', {state:{organization_id: organization_id}})
            // } else if(props.accessToken) {
            //     navigate('/dashboard')
            // } else {
            //     window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self')
            // }

    }, []);

    return (
        <Grid item container justifyContent='center'>
            <CircularProgress size={28} style={{marginTop:"32px"}}/>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.triageAuth,
})

const mapDispatchToProps = (dispatch) => ({
    login: (access_token) => dispatch(login(access_token)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);