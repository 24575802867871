import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Checkbox, Container, Typography, Select, Button, Tooltip, MenuItem, InputAdornment, TextField, Divider} from '@material-ui/core';
import TriageList from './TriageList';
import { useSnackbar } from 'notistack';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {connect} from 'react-redux';
import {showIcons, TriageTypes} from '../util/AppUtil';
import { SelectedDrawerState } from '../actions/drawer';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        height:'calc(100vh - 51px)',
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        position:'relative'
    },
    container: {
        padding: "0px 12px 4px 12px",
        margin:'8px 0px 0px 0px'
    },
    MuiFormControlroot: {
        '& .MuiInputBase-input' :{
            padding: '10.5px 4px 10.5px 0px',
            color:'grey',
            background:'#fff'
        },
        '& .MuiOutlinedInput-root' :{
            position: 'relative',
            borderRadius: '6px',
        },   
        '& .MuiOutlinedInput-adornedStart' : {
            background:'#fff',
            paddingLeft: '7px',
        }
    },
    }
));  

const TriageComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const {enqueueSnackbar} = useSnackbar();

    const [checkAll, setCheckAll] = useState(false);
    const [selectedTypeValue, setSelectedTypeValue] = useState('all');
    const [selectedTriageCount, setSelectedTriageCount] = useState(0);
    const [triageCount, setTriageCount] = useState(0);
    const [triageList, setTriageList] = useState([]);
    const [clearTriageItems, setClearTriageItems] = useState(false);
    const [markAsRead, setMarkAsRead] = useState(false);
    const [markAsUnRead, setMarkAsUnRead] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedTriageList, setSelectedTriageList] = useState([]);

    const showSentEmails = props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS;
    const noteTriagePresent = selectedTriageList.filter((triageItem) => triageItem.type === TriageTypes.CHECKLIST_NOTE);
    const fullName = props.user.firstname + ' ' + props.user.lastname;

    useEffect(() => {
        if(props.drawerState === SelectedDrawerState.SHOW_TRIAGE) {
            document.title = `Inbox - ${fullName}`;
        }

        if(props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE) {
            document.title = `Cleared Items - ${fullName}`;
        }

        if(props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS) {
            document.title = `Sent Emails - ${fullName}`;
        }

    }, [props.drawerState]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(selectedTriageList && selectedTriageList.length > 0) {
                
    //         }
    //     }   
    // }, [selectedTriageList])

    const handleSelectedValueChange = (event) => {
        setSelectedTypeValue(event.target.value);
    }

    const getSelectedTriageCount = (count) =>{
        setSelectedTriageCount(count);
        if(count > 50){
            setCheckAll(false);
            enqueueSnackbar('Cannot select more than 50 items !', {
                variant: 'error'
            });
        }
    }

    const getTriageCount = (triageCount) =>{
        //consoleToLog("checklistCount",checklistCount);
        setTriageCount(triageCount);
    }

    const handleChange = (event) => {
        if(selectedTriageCount > 1){
            setCheckAll(event.target.checked);
        }else{
            if(triageCount < 50){
                setCheckAll(event.target.checked);
            }else{
                enqueueSnackbar('Cannot select more than 50 items !', {
                    variant: 'error'
                });
            }
        }
    };

    const returnTriageCount = (triage) => {
        setTriageList(triage);
    }

    const onClearTriageClick = () => {
        setClearTriageItems(true)
    }

    const onMarkAsReadClick = () => {
        setMarkAsRead(true);
    }

    const onMarkAsUnReadClick = () => {
        setMarkAsUnRead(true);
    }

    const onSearchChecklistChange = (e) => {
        const search_text = e.target.value;
        setSearchText(e.target.value);
    }

    return (
        <div className={classes.mainContent}>
            <Grid item container alignItems="center" className='stickyTopBar'>
                <Container maxWidth="xl" className={classes.container}>   
                    <Grid item container alignItems="center" style={{marginTop: showSentEmails ? '4px' : '0px'}}>
                    {
                        <>
                            {!showSentEmails &&
                            <Grid item style={{padding: "0px", marginLeft:'4px'}}> 
                                <Checkbox color="secondary" 
                                    onChange={handleChange} 
                                    checked={checkAll}/>
                            </Grid>}

                            {!checkAll ?
                            <>
                            {!showSentEmails &&
                            <Grid item sm={3} style={{padding: "0px"}}> 
                                <Select 
                                    value={selectedTypeValue}
                                    onChange={handleSelectedValueChange}
                                    variant="outlined"
                                    fullWidth
                                    margin='dense'
                                    classes={{
                                        root: classes.MuiFormControlroot
                                    }} 
                                >
                                    <MenuItem value="all">All Inbox</MenuItem>
                                    <MenuItem value="email">Emails</MenuItem>
                                    <MenuItem value="checklist_note">Notes</MenuItem>
                                    <MenuItem value="approval">Approvals</MenuItem>
                                </Select>
                            </Grid>}
                            <Grid item sm={5}>
                                <TextField fullWidth 
                                        value={searchText}
                                        id="quicksearch checklist" 
                                        label="" 
                                        variant="outlined"
                                        type="text"
                                        placeholder="Type Contact Name, Email, Subject or Client Name"
                                        onChange={onSearchChecklistChange}
                                        classes={{
                                            root: classes.MuiFormControlroot
                                        }} 
                                        InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                        style={{marginLeft:'16px', marginTop:'-0.1px'}}
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <SearchIcon className={classes.searchicon}/> */}
                                                <i className="flaticon-search"></i>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                            </Grid>
                            </>
                            :
                            <div  style={{padding:'3px 0', display:'flex', alignItems:'center', position:'relative'}}>
                                <Grid item>
                                    <Tooltip title="Clear Triage" arrow >
                                        <Button aria-label="delete"
                                            onClick={onClearTriageClick}
                                            className='header_icons'>
                                                <img src={showIcons('triage')} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                {noteTriagePresent && noteTriagePresent.length === 0 &&
                                <Grid>
                                    <Tooltip title="Mark as Read" arrow >
                                        <Button aria-label="delete"
                                            onClick={onMarkAsReadClick}
                                            className='header_icons'>
                                                <img src={showIcons('read')} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                }
                                {noteTriagePresent && noteTriagePresent.length === 0 &&
                                <Grid>
                                    <Tooltip title="Mark as Unread" arrow >
                                        <Button aria-label="delete"
                                            onClick={onMarkAsUnReadClick}
                                            className='header_icons'>
                                                <img src={showIcons('unread')} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                }
                                <Grid item style={{color:"#0076ff",marginLeft:"3px", paddingTop:'0px'}}>
                                    {selectedTriageCount} selected
                                </Grid>
                            </div>
                        }
                        </>
                    }

                        {/* <div style={{display:'flex', alignItems:'center', marginLeft:'16px'}}>
                            {
                                props.triageOrgEmailList && props.triageOrgEmailList.length > 0 &&
                                props.triageOrgEmailList.slice(0,2).map((orgEmail) => {
                                    return <Grid item container alignItems='center' style={{marginLeft:'16px'}}>
                                                <Grid item
                                                    style={{
                                                        width:'14px',
                                                        height:'14px',
                                                        background: orgEmail.color,
                                                        marginRight:'6px'
                                                    }}>
                                                </Grid>
                                                <Grid item md={10}>
                                                    <Typography variant='body2' style={{fontWeight:500}}>
                                                        {orgEmail.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                })
                            }
                        </div> */}
                    </Grid>
                </Container>
            </Grid>
            <Container  maxWidth="xl" style={{padding: "0 12px"}}>
                <TriageList checkAll={checkAll} 
                    setCheckAll={setCheckAll}
                    getSelectedTriageCount={getSelectedTriageCount}
                    getTriageCount = {getTriageCount}
                    returnTriageCount={returnTriageCount}
                    clearTriageItems={clearTriageItems}
                    setClearTriageItems={setClearTriageItems}
                    triageListFromParent={props.triageList}
                    fetchTriageListFromChild={props.fetchTriageListFromChild}
                    markAsRead={markAsRead}
                    setMarkAsRead={setMarkAsRead}
                    markAsUnRead={markAsUnRead}
                    setMarkAsUnRead={setMarkAsUnRead}
                    searchText={searchText}
                    setSelectedTriageList={setSelectedTriageList}
                    messageObj={props.messageObj}
                    setMessageObj={props.setMessageObj}
                    selectedTypeValue={selectedTypeValue}
                /> 
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    triageOrgEmailList: state.triageSelectedOrganizationInfo.orgEmailList,
    drawerState: state.drawerState.setDrawerState,
    user: state.triageSelectedOrganizationInfo.user
})

export default connect(mapStateToProps)(TriageComponent);