import React, {useState} from 'react';
import {getAttachmentUploadURLApi} from './genieService';
import axios from 'axios';

export const uploadAttachmentApi = async (file_name, file_type, bucket, file, organization_id) => {
    const uploadURl = await getAttachmentUploadURLApi(file_name, file_type, bucket, file)
    const options = {
            headers: {
            "Content-Type": file_type,
            }
        };
        axios.defaults.headers.common = {};
    const data = await axios.put(uploadURl.data.data.returnData.signedRequest, file, options)
    return data;
        
}
