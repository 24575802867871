import React, {useState, useEffect} from 'react';
import { Typography, ListItem, Button, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'; 
import {connect} from 'react-redux';  
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import {showIcons, trackGAEvent} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    leftmenu : {
        width: (showDrawer) => { 
            return showDrawer ? "19%" : "0%";
        },     
        height: 'calc(100vh - 48px)',
        flexGrow: 1,
        backgroundColor: "white", 
        transition: ".5s", 
        overflow: "auto", 
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   ,
        zIndex:1000
    },
    text : {
        color:'#202020',
        fontWeight: 600,
    },
    icons : {
        marginRight:'8px',
        marginLeft: '2px', 
        fontSize: '16px',
        lineHeight: 0
    },
    listStyle: {
       marginLeft:'auto !important',
       color: '#ccc',
       padding:'0 4px 0px 8px',
    },
    labels : {
        padding:'10px 18px',
        marginTop: '5px',
        marginBottom: '10px'
    },
    actionIcons: {
        width:'18px',
        marginRight:'6px',
    },
}));

const LeftMenuComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);


    const onShowTriageClick = () => {
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_TRIAGE);
        trackGAEvent(props.triageSelectedOrganization.organization.name, 'Inbox Tab Clicked', `${props.user.firstname} ${props.user.lastname} `);
    }

    const onClearedTriageClick = () => {
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLEARED_TRIAGE);
        trackGAEvent(props.triageSelectedOrganization.organization.name, 'Cleared Item Tab Clicked', `${props.user.firstname} ${props.user.lastname} `);
    }

    const onSentEmailsClick = () => {
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_SENT_EMAILS);
        trackGAEvent(props.triageSelectedOrganization.organization.name, 'Sent Email Tab Clicked', `${props.user.firstname} ${props.user.lastname} `);
    }

    return (
        <div className={classes.leftmenu}>
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_TRIAGE}   
                    onClick={onShowTriageClick}
                >
                    {<img src='/images/inbox-icon.png' className='inboxIcon filterColor'/> }
                    <Typography variant="body1" className={classes.text}>Inbox</Typography>
                </ListItem>
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_CLEARED_TRIAGE}   
                    onClick={onClearedTriageClick}
                >
                    {  <img src={showIcons('triage')} className={`${classes.actionIcons} filterColor1`}/>}
                    <Typography variant="body1" className={classes.text}>Cleared Items</Typography>
                </ListItem>
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_SENT_EMAILS}   
                    onClick={onSentEmailsClick}
                >
                    {  <img src={showIcons('sent-email')} className={`${classes.actionIcons} filterColor2`}/>}
                    <Typography variant="body1" className={classes.text}>Sent Emails</Typography>  
                </ListItem>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.triageAuth,
    drawerState: state.drawerState.setDrawerState,
    user: state.triageSelectedOrganizationInfo.user,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuComponent);