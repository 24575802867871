import {getAxios} from './axiosUtil';

//get Organization List API
export const organizationListApi = () => {
    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organizations`);
}

//organization details (member + labels)
export const organizationDetailsApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/details`);
}

export const getClientEmailListApi = (organization_id, query) => {
    let param  = {
        params : {
            query
        }};

return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/client_emails`, param);
}

export const orgCompaniesListApi = (page, text, organization_id) => {
    const param = {
        params: {
            page,
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/company/${organization_id}`, param);
}

export const getPendingCheckListApi = (organization_id, clients, search_query_text) => {
    let param  = {
        params : {
            clients,
            search_query_text
        }};

return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/clients_checklists`, param);
}

export const decryptEmailPathApi = (organization_id, raw_email_path) => {

    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/email/raw/decrypt?raw_email_path=${raw_email_path}`);
}


export const organizationTemplateListApi = (organization_id) => {
    
    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/published_templates`);
}

export const getChecklistFoldersApi = (organization_id, template_id,) => {
    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/checklist/grouping/${organization_id}/${template_id}`);
}

//Create Checklist API
export const createChecklistApi = (assignees, company_ids, approvers, schedule, organization_id, template_id, 
    start_date, schedule_type, schedule_creation_type, subtitle, billable, cost, periodicity, due_date) => {
    let data = {
        assignees,
        company_ids,
        approvers,
        schedule,
        start_date,
        schedule_type,
        schedule_creation_type,
        subtitle,
        billable, 
        cost, 
        periodicity,
        due_date
    }
    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/checklist/${organization_id}/${template_id}`, data);
}

export const disableEmailFollowupApi = (organization_id, followup_id) => {
    return getAxios().put(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/email_followup/${followup_id}/inactive`);
}

export const createEmailFollowupApi = (organization_id, subject, body, attachments, from_email, to_emails, cc_emails, bcc_emails, checklist_ids, max_count, interval, email_draft_id  ) => {

    let data = {
        subject,
        body,
        attachments,
        from_email,
        to_emails,
        cc_emails,
        bcc_emails,
        checklist_ids,
        max_count,
        interval,
        email_draft_id
    }

    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/email_followup`, data);
}

export const downloadAttachmentApi = (organization_id, path, bucket) => {
    let data = {path, bucket};

    return getAxios().put(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/attachment/download_success`, data);
}

export const addChecklistNoteApi = (checklist_id, mention_users, text, organization_id) => {
    let data = {
        mention_users,
        text
    };

    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/comments/${organization_id}/checklist/${checklist_id}`, data);
}

export const noteReadApi = (organization_id, checklist_id) => {

    return getAxios().put(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/checklist/${checklist_id}/update_note_read_users`);
}

export const approveRejectCancelApi = (organization_id, apiStr) => {
    let data = {
        request_origin : "genie"
    }

    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/approve_reject_cancel/${apiStr}`, data);
}

export const undoApproveRejectApi = (organization_id, approval_id) => {

    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/undo_approve_reject/${approval_id}`);
}

export const downloadApprovalAttachmentApi = (organization_id, path, bucket) => {
    let data = {path, bucket};

    return getAxios().put(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/organization/${organization_id}/attachment/download_success`, data);
}

export const authenticateApi = (service) => {
    const param = {
        params: {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/authenticate`, param)
}
