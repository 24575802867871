import { useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, Tooltip, Popover, Badge, AppBar, Toolbar, MenuItem, Fab  } from '@material-ui/core';
import {logout} from '../actions/triageAuth';
import {setSelectedDrawerState, SelectedDrawerState} from '../actions/drawer';
import {history} from '../routers/AppRouter';
import {setSelectedOrg, fetchOrgDetails} from '../actions/triageSelectedOrganization';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { setLoading } from '../actions/loading';
import { useIsMount } from './useIsMount';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { setEmailSentFrom, setCurrentEmailState } from '../actions/triageSelectedOrganization';

const useStyles = makeStyles((theme) => ({
    MuiPopoverroot : {
        '& .MuiPopover-paper' : {
        width:'550px !important',
        borderRadius: '0px !important',
        height:'calc(100vh - 51px)',
        marginTop:"16px",
        flexGrow: 1,
        scrollbarWidth: 'none',
        marginLeft:"16px",
        overflow:'auto',
        "&::-webkit-scrollbar": {
            display: "none"
        } 
        }
    },
    logo: {
        maxWidth: 120,
        marginLeft: "15px",
        cursor:'pointer'
    },
    login: {
        marginLeft: 'auto',
    },
    selectEmpty: {
        color: "white",
        padding: "0 14px",
        backgroundColor: "transparent"
    },
    icons : {
        backgroundColor: "transparent",
        color: "white",
        fontSize: "18px",
        marginTop: "8px",
        minWidth: "40px"
    },
    colorSecondary : {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.contrastText
    },
    actionIconStyle: {
        width:'20px',
        marginRight: '6px'
    },
    avatar : {
        minHeight: "28px",
        height: "28px",
        width: "28px",
        fontSize: '13px !important',
        color: '#fff !important',
        border: '1px solid #fff !important',
        background:'#3d3d3d !important',
        display:'flex',
        alignItems:'center'
    }
}));

const Header = (props) => {
    const classes = useStyles(props);
    const isMount = useIsMount();

    const [anchorEl, setAnchorEl] = useState(null);
    const { name } = props.triageSelectedOrganization.organization ? props.triageSelectedOrganization.organization : { name: '' };
    const [selectedText, setSelectedText] = useState(name);
    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        const value = event.currentTarget.dataset.myValue;
        consoleToLog(event.currentTarget.dataset.myValue);
        setSelectedText(value ? event.currentTarget.dataset.myValue : selectedText);
        setAnchorEl(null);
    };

    useEffect(() => {
        if(!isMount) {
                const organization_id = props.triageSelectedOrganization.organization?.id;
                props.fetchOrgDetails(organization_id);
        }
    }, [props.triageSelectedOrganization.organization?.id]);

    const onChangeOrgClick = (org) => {
        if (org.organization.id !== props.triageSelectedOrganization.organization?.id) {
            consoleToLog('Change Organization Clicked');
            //Set selected org
            props.setSelectedOrg(org);
            trackGAEvent(props.triageSelectedOrganization.organization.name, 'Organization Changed', `${props.user.firstname} ${props.user.lastname} `);
            props.setLoading(true);
            setAnchorEl(null);
            props.currentEmailState && props.setCurrentEmailState(undefined);
        }
    }

    const handleActionsMenuOpen = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleActionsMenuClose = () => {
        setAnchorEl2(null)
    }

    const onSendEmailClick = () => {
        trackGAEvent(props.triageSelectedOrganization.organization.name, 'New Email clicked from header', `${props.user.firstname} ${props.user.lastname} `);
        props.setEmailSentFrom('Header');
        props.handleSendEmailDialogOpen();
        handleActionsMenuClose();
    }


    return (
        <AppBar position="static" elevation={0}>
            <Toolbar variant="dense">
                    <Tooltip title="Drawer Open/Close" arrow>
                        <Button
                            onClick={props.drawerAction}
                            className={classes.icons}
                            >
                            <i className="flaticon-menu"></i>
                        </Button>
                    </Tooltip>
                <img src="/images/eprocessify-logo-header.svg"
                    
                    alt="logo" 
                    className={classes.logo}/>
                <div className={classes.login}>
                        <Button aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            style={{
                                color: "white",
                                border: '1px solid #ffffff',
                                fontWeight: "400",
                                fontSize: "12px",
                                marginRight:"10px"
                            }}
                            variant="outlined"
                            >
                            {name}
                        </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                            {props.accessToken && props.triageOrganizations &&
                                    props.triageOrganizations.length !== 0 ? (
                                        props.triageOrganizations.map((org) => {
                                            return <MenuItem key={org.id} data-my-value={org.organization.name} 
                                                            onClick={() => onChangeOrgClick(org)}
                                                    >
                                                    {org.organization.name}
                                                    </MenuItem>
                                        })
                                    ) : <MenuItem>No Organizations Found</MenuItem>
                            } 
                    </Menu>
                    <>
                    <Tooltip title="Actions" arrow>
                        <Button
                            onClick={handleActionsMenuOpen}
                            className={classes.icons}>
                            <i className="flaticon-add-2"></i>
                        </Button>
                    </Tooltip>
                    <Menu
                        id="action-menu"
                        anchorEl={anchorEl2}
                        keepMounted
                        open={Boolean(anchorEl2)}
                        onClose={handleActionsMenuClose}
                        style={{marginTop:'24px'}}
                    >
                        <MenuItem onClick={onSendEmailClick}>
                            <img src="/images/new-mail.png" className={classes.actionIconStyle}/>
                            {/* <EmailOutlinedIcon fontSize='small'  className={classes.actionIconStyle}/> */}
                            New Email (Ctrl + E)
                        </MenuItem>
                    </Menu>
                    </>
                    <Tooltip title={props.user.firstname + ' ' + props.user.lastname} arrow>
                        <Button style={{position:'relative', top:'-2.5px'}}
                            className={classes.icons}>
                            {/* <i className={`flaticon-user ${classes.profileIcon}`}></i> */}
                            <Fab className={classes.avatar}>
                                {props.user.firstname.charAt(0) + props.user.lastname.charAt(0)}
                            </Fab>
                        </Button>
                    </Tooltip>
                </div> 
                {/* <Button color="inherit" className={classes.login}>Login</Button> */}
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => ({
    drawerState: state.drawerState.setDrawerState,
    triageOrganizations: state.triageOrganizations,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
    user: state.triageSelectedOrganizationInfo.user,
    currentEmailState: state.triageSelectedOrganizationInfo.currentEmailState
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setSelectedOrg: (organization) => dispatch(setSelectedOrg(organization)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    fetchOrgDetails: (organization_id) => dispatch(fetchOrgDetails(organization_id)),
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str)),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);