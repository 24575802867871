const defaultState = {
    selectedOrganization: {},
    members: [],
    orgEmailList: [],
    selectedOrgEmailObj: undefined,
    isEmailSendError: false,
    currentEmailState: undefined,
    rawS3EmailPath: '',
    orgEmailDraftList: [],
    user: {},
    emailSentFrom: ''
}

//set selected organization
export default (state = defaultState, action) => {
    switch (action.type) {

        /*** selected organization*/
        case 'SET_SELECTED_ORG':
            return {
                ...state,
                selectedOrganization: action.organization
            }

        /*** Org members */
        case 'SET_ORG_MEMBERS':
            return {
                ...state,
                members: action.members
            } 
        
        case 'SET_ORG_EMAILS':
            return {
                ...state,
                orgEmailList: action.emails
            }

        case 'UPDATE_ORG_EMAIL': 
            return {
                ...state,
                orgEmailList: state.orgEmailList.map((email) => {
                    if(email.id === action.email.id) {
                        return action.email;
                    } else {
                        return email;
                    }
                })
            }  

        case 'SET_SELECTED_ORG_EMAIL_OBJ':
            return {
                ...state,
                selectedOrgEmailObj: action.emailObj
            }

        case 'SET_IS_SEND_EMAIL_ERROR': 
            return {
                ...state,
                isEmailSendError: action.emailSendError
            }

        case 'SET_CURRENT_EMAIL_STATE': 
            return {
                ...state,
                currentEmailState: action.emailState
            }

        case 'SET_RAW_EMAIL_S3_PATH': 
            return {
                ...state,
                rawS3EmailPath: action.emailPath
            }

        case 'SET_ORG_EMAIL_DRAFTS':
            return {
                ...state,
                orgEmailDraftList: action.draftsList
            }

        case 'SET_USER_OBJ' :
            return {
                ...state,
                user : action.user_obj
            }

        case 'SET_EMAIL_SENT_FROM':
            return {
                ...state,
                emailSentFrom: action.str
            }

        case 'SET_CLEAR_ORG_STATE':
            return defaultState;

            default:
            return state;
    }
};