import React, {useState, useEffect} from 'react';
import {Grid, Tooltip, TextField, Typography, Button, Divider, ClickAwayListener, InputAdornment} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useIsMount } from "./useIsMount";
import {getPendingCheckListApi} from '../services/mainAppService';
import { connect } from 'react-redux';
import { consoleToLog, showIcons } from '../util/AppUtil';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    addButtonStyle: {
        position: "relative",
        width: "23px",
        height: "23px",
        minWidth: "0",
        marginTop: "-1px",
        fontSize: "13px",
        color: "#0071ff",
        borderRadius: "50%",
        border: "1px solid #0071ff",
        paddingTop: "3px",
    },
    companyNameStyle: {
        fontSize: '14px',
        fontWeight: 500,
        padding: '4px 0px'
    },
    list: {
        display: 'flex',
        flexDirection:'column',
        cursor: 'pointer',
        color: '#333',
        '&:hover' : {
            background:'#F9F9F9'
        }
    },
    listContainer : {
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    subtitleStyle: {
        fontSize:'13px',
        marginLeft:'4px', 
        color:'grey', 
    },
    templateName: {
        color:'#0071FF'
    },
    checklistItemStyle: {
        cursor: "pointer", 
        padding: '6px', 
        marginBottom:'8px',
        background:'#f7f7f7',
    },
    checklistItemStyle1: {
        cursor: "pointer", 
        padding: '6px', 
        marginBottom:'8px',
        '& .iconHover': {
            visibility: 'hidden'
        },
        '&:hover' : {
            background:'#f7f7f7',
            '& .iconHover': {
                visibility: 'visible',
                marginLeft:'auto'
            }
        }
    },
    MuiFormControlroot: {
        '& .MuiInputBase-input' :{
            padding: '8px',
            color:'grey',
            background:'#f7f7f7'
        },
        '& .MuiOutlinedInput-root' :{
            position: 'relative',
            borderRadius: '6px',
        },   
        '& .MuiOutlinedInput-adornedStart' : {
            background:'#fff',
            paddingLeft: '7px',
        }
    },
    checklistTitle: {
        maxWidth: '28ch', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis',
        '&:second-child' : {
            fontSize:'13px',
            color:'grey', 
        },
        [theme.breakpoints.down(1400)] : {
            maxWidth: '23ch', 
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '20ch', 
        },
    },
    checklistTitle1: {
        maxWidth: '31ch', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis',
        '&:second-child' : {
            fontSize:'13px',
            color:'grey', 
        },
        [theme.breakpoints.down(1400)] : {
            maxWidth: '26ch', 
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '23ch', 
        },
    }

}))

const SetChecklistForTriageItem = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [pendingCheckList, setPendingCheckList] = useState([]);
    const [selectedCheckList, setSelectedCheckList] = useState(props.selectedTriageObj?.data.checklists);
    const [searchText, setSearchText] = useState('');


    useEffect(() => {
        if (!isMount) {
            setSelectedCheckList(props.selectedTriageObj?.data.checklists);
        }
    }, [props.selectedTriageObj?.data.checklists]);

    const fetchPendingCheckList = (text) => {
        if(text === '') return;

        const organization_id = props.triageSelectedOrganization.organization.id;
        const clients = props.selectedTriageObj?.data.companies.map((client) => client.id).join();

        getPendingCheckListApi(organization_id, clients, text)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getPendingCheckListApi', res);

                let filterSelectedCompany = res.filter(item => !props.selectedTriageObj?.data.checklists.some(selectedItem => item.checklist_id === selectedItem.checklists_id));
                setPendingCheckList(filterSelectedCompany);
            })
            .catch((e) => {
                consoleToLog('Error getPendingCheckListApi', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const isSelectedChecklist = (checkedChecklist) => {
        const tempArr = selectedCheckList.filter(
            (checklistItem) => checklistItem.checklists_id === checkedChecklist.checklists_id
        );
        return tempArr.length > 0 ? true : false;
    };

    
    const onChecklistItemClick = (checkedChecklist) => {
        if (isSelectedChecklist(checkedChecklist)) {
            let checklist_id = checkedChecklist.checklists_id ? checkedChecklist.checklists_id : checkedChecklist.checklist_id;
            let triageObjFollowupArr = props.selectedTriageObj?.data.metadata.followups;
            const followupArr = triageObjFollowupArr && triageObjFollowupArr.length > 0 && 
                                triageObjFollowupArr.filter((followup) => followup.checklist_ids && followup.checklist_ids.length > 0 && !followup.checklist_ids.includes(checklist_id));
            //remove
            const array = selectedCheckList.filter((checklistItem) => checklistItem.checklists_id !== checkedChecklist.checklists_id);
            //setSelectedCheckList(array);
            const removedChecklistArray = selectedCheckList.filter((checklistItem) => checklistItem.checklists_id === checkedChecklist.checklists_id);
            onApplyChanges(array, followupArr, removedChecklistArray);
        } else {
            //add
            let checklistFollowupsArr = checkedChecklist.followups && checkedChecklist.followups.length > 0 ? checkedChecklist.followups : [];
            let triageObjFollowupArr = props.selectedTriageObj?.data.metadata?.followups && props.selectedTriageObj?.data.metadata?.followups.length > 0 
                                        ? props.selectedTriageObj?.data.metadata.followups : [];
            const followupArr = [...checklistFollowupsArr, ...triageObjFollowupArr];
            const array = selectedCheckList.concat(checkedChecklist);
            //setSelectedCheckList(array);
            onApplyChanges(array, followupArr, []);
        }

    }

    const onApplyChanges = (list, followupArr, removedChecklistArray) => {
        props.addChecklistToTriageItem(list, followupArr, props.fromTriageDetails, removedChecklistArray);
        setSearchText('');
    };

    // const showChecklistForTriageList = () => {
    //     return  (
    //         <>
    //             <Grid item style={{ position: "relative", marginTop:'16px'}}>
    //                 <Typography variant='subtitle1' style={{marginBottom:'-8px'}}>
    //                     Search Checklist
    //                 </Typography>
    //                 <TextField 
    //                     variant='outlined'
    //                     margin='dense'
    //                     id="label_input"
    //                     fullWidth
    //                     placeholder="Search Checklist"
    //                     style={{ marginBottom: "8px" }}
    //                     value={searchText}
    //                     onClick={(e) => e.stopPropagation()}
    //                     onChange={(e) => {
    //                         setSearchText(e.target.value);
    //                         fetchPendingCheckList(e.target.value)
    //                     }}
    //                 />
    //                     <ClickAwayListener onClickAway={() => {
    //                             setSearchText('');
    //                             setPendingCheckList([])
    //                             }}>
    //                         <Grid item 
    //                             style={{minHeight:'10px !important'}}
    //                             className={`orgcomp__menu ${(searchText !== '')  ? "active" : "inactive"}`}
    //                         >
    //                             { pendingCheckList.length > 0 ?
    //                                 pendingCheckList.map((checklistItem) => {
    //                                     let company_name = checklistItem.title
    //                                     return <ul key={checklistItem.id} className={classes.listContainer}>
    //                                                     <li className={classes.list} 
    //                                                         onClick={(e) => onChecklistItemClick(checklistItem)}
    //                                                     >
    //                                                     <span>
    //                                                         <Typography style={{marginBottom:'0px', display:'flex', alignItems:'center'}}>
    //                                                         {company_name} 
    //                                                             <Typography className={classes.subtitleStyle}>
    //                                                                 {checklistItem.subtitle}
    //                                                             </Typography>
    //                                                         </Typography>
                                                            
    //                                                         <Typography variant='subtitle1' className={classes.templateName}>
    //                                                             {checklistItem.template_name}
    //                                                         </Typography>
    //                                                     </span>

    //                                                 </li>
    //                                                 <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
    //                                             </ul>
    //                                 }) : <ul className={classes.listContainer} style={{textAlign:'center'}}>
    //                                         <li className={classes.list}>
    //                                             <span style={{fontSize:'14px', color:'#555'}}>
    //                                                 No Checklist Found
    //                                             </span>
    //                                         </li>
    //                                     </ul> 
    //                             }
    //                         </Grid>
    //                     </ClickAwayListener>
    //             </Grid>
                
    //             <Grid item sm={12} style={{marginTop:'16px'}}>
    //                 {selectedCheckList && selectedCheckList.length > 0 &&
    //                     <Typography variant="button" style={{fontWeight:600}} gutterBottom>
    //                         Checklist
    //                     </Typography>
    //                 }

    //                 {
    //                     /* display initial 2 members if any*/ 
    //                     selectedCheckList && selectedCheckList.length > 0 &&
    //                     selectedCheckList && selectedCheckList.length > 0 && 
    //                     selectedCheckList.map((checklistItem) => {
    //                         return <Grid item container key={checklistItem.id} justifyContent='space-between' alignItems='center' 
    //                                     className={classes.checklistItemStyle}>
    //                                         <Typography variant='body1' 
    //                                             className={`company_checklist_style`}>
    //                                                 <Typography className='text_ellipsis' 
    //                                                     style={{marginBottom:'0px', display:'flex', alignItems:'center'}}>
    //                                                     {checklistItem.title} 
    //                                                     <Typography className={classes.subtitleStyle}>
    //                                                         {checklistItem.subtitle}
    //                                                     </Typography>
    //                                                 </Typography>    

    //                                             <Typography variant='body2' className={classes.templateName}>
    //                                                 {checklistItem.template?.name}
    //                                             </Typography>
    //                                         </Typography>
    //                                         <ClearIcon fontSize='small' onClick={(e) => onChecklistItemClick(checklistItem)}/>
                                            
    //                                 </Grid>
    //                     })
    //                 } 
    //             </Grid>
    //         </>
    //     )

    // }

    const onClickAway = () => {
        setSearchText('');
        setPendingCheckList([]);
    }

    const showAssignChecklistUI = () => {
        return (
            <>
                <Grid item style={{ position: "relative", marginTop:'0px'}}>
                    <TextField 
                        variant='outlined'
                        margin='dense'
                        id="label_input"
                        fullWidth
                        placeholder="Search Checklist"
                        style={{ marginBottom: "8px" }}
                        value={searchText}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.setChecklistTextboxClicked(true);
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            fetchPendingCheckList(e.target.value);
                        }}
                        classes={{
                            root: classes.MuiFormControlroot
                        }} 
                        InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <img src={showIcons('search')}/>
                            </InputAdornment>
                            ),
                            style: {paddingTop:'0.5px', paddingBottom:'0.5px', background:'#f7f7f7'}
                        }}
                    />
                        <ClickAwayListener onClickAway={onClickAway}>
                            <Grid item 
                                style={{minHeight:'10px !important'}}
                                className={`orgcomp__menu ${(searchText !== '')  ? "active" : "inactive"}`}
                            >
                                { pendingCheckList.length > 0 ?
                                    pendingCheckList.map((checklistItem) => {
                                        let company_name = checklistItem.title
                                        return <ul key={checklistItem.id} className={classes.listContainer}>
                                                        <li className={classes.list} 
                                                            onClick={(e) => onChecklistItemClick(checklistItem)}
                                                        >
                                                        <span>
                                                            <Tooltip title={`${company_name} (${checklistItem.subtitle})`}>
                                                                <Typography className={classes.checklistTitle1}>
                                                                    {company_name} 
                                                                    <a className={classes.subtitleStyle}>
                                                                        {checklistItem.subtitle}
                                                                    </a>
                                                                </Typography>
                                                            </Tooltip>                                                            
                                                            <Typography variant='subtitle1' className={classes.templateName}>
                                                                {checklistItem.template_name}
                                                            </Typography>
                                                        </span>

                                                    </li>
                                                    <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
                                                </ul>
                                    }) : <ul className={classes.listContainer} style={{textAlign:'center'}}>
                                            <li className={classes.list}>
                                                <span style={{fontSize:'14px', color:'#555'}}>
                                                    No Checklist Found
                                                </span>
                                            </li>
                                        </ul> 
                                }
                            </Grid>
                        </ClickAwayListener>
                </Grid>
                
                <Grid item sm={12} style={{marginTop:'16px'}}>

                    {
                        /* display initial 2 members if any*/ 
                        selectedCheckList && selectedCheckList.length > 0 &&
                        selectedCheckList && selectedCheckList.length > 0 && 
                        selectedCheckList.map((checklistItem) => {
                            return <Grid item container key={checklistItem.id} justifyContent='space-between' alignItems='center' 
                                        className={classes.checklistItemStyle1}>
                                            <Typography variant='body1' 
                                                className={`company_checklist_style`}>
                                                        <Tooltip title={`${checklistItem.title} (${checklistItem.subtitle})`}>
                                                            <Typography variant='body1' className={classes.checklistTitle}>
                                                                {checklistItem.title} <a className={classes.subtitleStyle}>{checklistItem.subtitle}</a> 
                                                            </Typography>
                                                        </Tooltip>

                                                <Typography variant='body2' className={classes.templateName}>
                                                    {checklistItem.template?.name}
                                                </Typography>
                                            </Typography>
                                            <ClearIcon fontSize='small' 
                                                className='iconHover'
                                                onClick={(e) => onChecklistItemClick(checklistItem)}/>
                                            
                                    </Grid>
                        })
                    } 
                </Grid>
            </>
        )
    }

    return (
        <Grid item sm={12}>
            {
                showAssignChecklistUI() 
            }
        </Grid>
    );
}
const mapStateToProps = (state) => ({
    accessToken: state.triageAuth,
    triageSelectedOrganization: state.triageSelectedOrganizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(SetChecklistForTriageItem);