import {getAxios} from './axiosUtil';

export const getInvoiceAccountsListApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/invoice_accounts/connected`);
}

export const autoCompleteClientApi = (organization_id, text) => {
    const param = {
        params : {
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/autocomplete_search/organization/${organization_id}/clients/`, param);
}

export const fullTextSearchApi = (organization_id, text) => {
    const param = {
        params: {
            text
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/contact/fulltext`, param);
}